import {Routes} from '@angular/router';
import {AuthGuard} from '@core/guards/auth.guard';
import {RoleGuard} from '@core/guards/role.guard';
import {ROLES} from '@core/enums/Roles.enum';

export default [
  {
    path: 'corporate-chat',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
    },
    loadComponent: () => import('./corporate-chat.component').then(c => c.CorporateChatComponent),
  }
] satisfies Routes;
