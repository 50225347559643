import {DOCUMENT, registerLocaleData} from '@angular/common';
import {Component, ElementRef, Inject, inject, OnInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {CoreMenuService} from 'core/components/core-menu/core-menu.service';
import {CoreTranslationService} from 'core/services/translation.service';
import {locale as menuUzbek} from '../assets/i18n/uz';
import {locale as menuCrl} from '../assets/i18n/crl';
import {locale as menuRussian} from '../assets/i18n/ru';
import {locale as menuKarakalpak} from '../assets/i18n/kk';
import localeUz from '@angular/common/locales/uz';
import localeCrl from '@angular/common/locales/uz-Cyrl';
import localeRu from '@angular/common/locales/ru';
import localeKk from '@angular/common/locales/kk';
import {callCenterMenu, chatServiceMenu, crmMenu, helpDeskMenu} from '@app/menu';
import {Router} from '@angular/router';
import {AppService} from '@app/app.service';
import {coreConfig} from './app.config';
import {CoreLoadingScreenService} from '@core/services/loading-screen.service';
import {OnlineStatusService} from 'ngx-online-status';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from './modules/pages/login/auth.service';
import {environment} from '../environments/environment';
import {MyLiveCallService} from '@call-center/services/my-live-call.service';
import {CurrentCallDetailsService} from '@call-center/services/current-call-details.service';
import {HttpClient} from '@angular/common/http';
import {ToastService} from '@core/services/toast.service';
import {LiveCallsDataService} from './common/customer-evaluation/live-calls-data.service';
import {StreamingService} from './modules/pages/profile/video-streaming/streaming.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
  public errorToastId: number;
  public callDetails = null;
  private http = inject(HttpClient);
  private toast = inject(ToastService);
  private translocoService = inject(TranslateService);
  private alertCount: number = 0;


  //////// don't remove code below
  // detectMouseInteraction(): void {
  //   this.mouseTimer = setTimeout(() => {
  //     this._blockUIService.startBlocking()
  //     this._usersBlockHistory.insert({status:Block.Blocked}).subscribe()
  //     this.handleHistoryState();
  //     this.platformLocation.onPopState(this.handleHistoryState);
  //   }, 600000);
  // }

  // @HostListener('document:mousemove', ['$event'])
  // onMouseMove(event: MouseEvent): void {
  //   if(this._localStorageService.getData(ELocalStorage.ACCESS_TOKEN)){
  //     clearTimeout(this.mouseTimer);
  //     this.mouseTimer = setTimeout(() => {
  //       this._blockUIService.startBlocking()
  //       this._usersBlockHistory.checkStatus().subscribe({
  //         next:(res:{data:any})=>{
  //            if(res.data[0].status==Block.Active){
  //              this._usersBlockHistory.insert({status:Block.Blocked}).subscribe()
  //            }
  //         }
  //       })
  //       this.handleHistoryState();
  //       this.platformLocation.onPopState(this.handleHistoryState);
  //     },600000);
  //   }
  // }

  // private handleHistoryState(): void {
  //   history.pushState(null, '', location.href);
  // }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public title: Title,
    public coreMenuService: CoreMenuService,
    public coreTranslationService: CoreTranslationService,
    public translateService: TranslateService,
    public coreLoadingService: CoreLoadingScreenService,
    public router: Router,
    public appService: AppService,
    private onlineStatusService: OnlineStatusService,
    private toastr: ToastrService,
    private authService: AuthService,
    private liveCallService: MyLiveCallService,
    private liveCallsSendData: LiveCallsDataService,
    private currentCallDetailsService: CurrentCallDetailsService,
    private streamingService: StreamingService
  ) {
    this.coreMenuService.register('main', crmMenu);
    this.coreMenuService.register('helpDesk', helpDeskMenu);
    this.coreMenuService.register('callCenter', callCenterMenu);
    this.coreMenuService.register('chatService', chatServiceMenu);
    this.translateService.addLangs(['uz', 'crl', 'ru', 'kk']);
    this.coreTranslationService.translate(menuCrl, menuRussian, menuUzbek, menuKarakalpak);
    this.title.setTitle(coreConfig.app.appTitle);
    registerLocaleData(localeUz, 'uz');
    registerLocaleData(localeCrl, 'crl');
    registerLocaleData(localeRu, 'ru');
    registerLocaleData(localeKk, 'kk');

    if (!sessionStorage.getItem('loggedIn')) {
      sessionStorage.removeItem('currentPage');
      sessionStorage.removeItem('call-details');
      sessionStorage.setItem('loggedIn', 'true');
    }
  }

  ngOnInit(): void {
    this.appService.init(this._renderer, this.document, this._elementRef);

    this.onlineStatusService.status.subscribe((status: OnlineStatusService) => {
      if (!status) {
        this.errorToastId = this.toastr.error(
          this.translateService.instant('Errors.ErrorOfflineText'),
          this.translateService.instant('Errors.ErrorOfflineTitle'),
          {
            positionClass: 'toast-top-right',
            titleClass: 'text-uppercase, text-danger',
            messageClass: 'text-secondary',
            toastClass: 'toast ngx-toastr',
          }
        ).toastId;
      } else {
        this.toastr.remove(this.errorToastId);
        this.toastr.success(
          'Online',
          '',
          {
            positionClass: 'toast-top-right',
            toastClass: 'toast ngx-toastr',
          }
        );
      }
    });

    // this.swUpdate.versionUpdates.subscribe(event => {
    //     if (confirm('A new version is available. Update now?')) {
    //         window.location.reload();
    //     }
    // });

    //////// don't remove code below


    //  if(this._localStorageService.getData(ELocalStorage.ACCESS_TOKEN)) {this.detectMouseInteraction();}
    //  this._usersBlockHistory.checkStatus().subscribe({
    //   next:(res:{data:any})=>{
    //     if(res.data[0].status==Block.Blocked){
    //       this._blockUIService.startBlocking()
    //     }
    //   }
    //  })


    this.currentCallDetailsService.getData().subscribe((data: any) => {
      this.callDetails = data;
    });

    this.isLiveCall();

    this.authService?.currentUser?.subscribe(currentUser => {
      if (currentUser !== null) {
        if (currentUser?.is_streaming) {
          console.info('Streaming started');
          this.streamingService.startLiveStreaming();
        } else {
          console.info('Streaming disabled');
        }
      }
    });
  }

  reHungUp() {
    let hangUpUrl = environment.apiUrl + '/call-center/api/v1/hungup-call';
    let body = {
      call_id: this.callDetails?.data?.call_id
    };

    this.http.post(hangUpUrl, body).subscribe({
      next: (res) => {
        if (res && res['success']) {
          this.currentCallDetailsService.removeData();
          setTimeout(() => {
            this.toast.info(this.translocoService.instant('CallEnd'));
          }, 500);
        }
      },
      error: (err) => {
        this.currentCallDetailsService.removeData();
        setTimeout(() => {
          this.toast.info(this.translocoService.instant('CallEnd'));
        }, 500);
        console.log(err);
      }
    });
  }

  isLiveCall() {
    setInterval(() => {
      if (this.authService.isLoggedIn()) {
        let hasCallDetail = sessionStorage.getItem('call-details') ? sessionStorage.getItem('call-details') : false;

        this.liveCallService.getAll().subscribe(
          (next) => {
            this.liveCallsSendData.sendData(next);

            if (next?.data && next?.data?.call_events?.length === 0) {
              this.reHungUp();
            } else if (next?.data?.call_events?.length >= 0 && next?.data?.call_events[0] && !hasCallDetail) {
              console.log('Review window');

              let pushLocalStorageObj = {
                unique_key: 'abc',
                data: {
                  status: 'Answered',
                  call_id: next?.data?.call_events[0].call_id,
                  type: 'incoming',
                  caller_phone: next?.data?.contact?.caller_phone,
                  answering_phone: next?.data?.contact?.answering_phone,
                  user_ids: [
                    {
                      user_id: 'qwerty'
                    }
                  ]
                },
                startTime: next?.data?.call_events[0]?.created_at
              };

              this.currentCallDetailsService.updateData({
                ...pushLocalStorageObj,
                startTime: next?.data?.call_events[0]?.created_at
              });
            } else if (next?.status === 404) {
              this.currentCallDetailsService.removeData();
            }
          },
          (error) => {
            if (this.alertCount === 0) {
              this.toast.warning(this.translateService.instant('CommunicationHasDeteriorated') + ' (LiveCallUser)');
              this.alertCount++;
            }
          }
        );
      }
    }, 5000);
  }

  reStartVideoStreaming() {
    if (this.authService.isLoggedIn()) {
      this.authService.currentUser.subscribe(currentUser => {
        if (currentUser.is_streaming) {
          console.info('Streaming started');
          this.streamingService.startLiveStreaming();
        } else {
          console.info('Streaming disabled');
        }
      });
    }
  }
}
