import {Injectable} from '@angular/core';
import {ELanguages, LanguageEnum} from '@core/enums/Languages.enum';

@Injectable({
  providedIn: 'root'
})
export class ELanguageService {

  constructor() {
  }

  getELanguage(): ELanguages {
    const language: string = JSON.parse(localStorage.getItem('config'))?.app?.appLanguage || ELanguages.UZ;
    let lang = null;

    switch (language) {
      case LanguageEnum.UZ:
        lang = ELanguages.UZ;
        break;

      case LanguageEnum.CRL:
        lang = ELanguages.CRL;
        break;

      case LanguageEnum.RU:
        lang = ELanguages.RU;
        break;

      case LanguageEnum.KK:
        lang = ELanguages.UZ;
        break;

      default:
        lang = ELanguages.UZ;
    }

    return lang;
  }
}
