@defer (when menuType === 'vertical-menu') {
  @if (menuType === 'vertical-menu') {
    <vertical-menu></vertical-menu>
  }
}

@defer (when menuType === 'horizontal-menu') {
  @if (menuType === 'horizontal-menu') {
    <horizontal-menu></horizontal-menu>
  }
}
