import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(public http: HttpClient) {
  }

  getUnreadNotifications(userId = null) {
    const params = {
      to_user: userId,
      order_column: 'created_at',
      order_type: 'desc',
      read: false,
    };

    return this.http.request('GET', environment.apiUrl + '/call-center/api/v1/notifications', { params });
  }

  getAllNotifications(userId = null) {
    const params = {
      to_user: userId,
      order_column: 'created_at',
      order_type: 'desc',
    };

    return this.http.request('GET', environment.apiUrl + '/call-center/api/v1/notifications', { params });
  }

  unreadNotificationCount(userId = null): Observable<number> {
    const params = {
      to_user: userId,
      read: false,
    };

    return this.http.get(environment.apiUrl + '/call-center/api/v1/notifications', { params }).pipe(
      map((notifications: any) => notifications?.data?.data?.length)
    );
  }

  readNotification(notificationId: number) {
    return this.http.request('PATCH', environment.apiUrl + '/call-center/api/v1/notifications/' + notificationId, {
      params: { read: true }
    });
  }
}
