import {inject, Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {ELanguages} from '@core/enums/Languages.enum';
import {ELocalStorage} from '@core/enums/LocalStorage.enum';
import {EReqHeaders} from '@core/enums/Auth.enum';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {ToastService} from '@core/services/toast.service';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {ELanguageService} from '@core/services/e-language.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _authService = inject(AuthService);
  private _toastService = inject(ToastService);
  private _eLanguageService = inject(ELanguageService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const language: string = this._eLanguageService.getELanguage() || ELanguages.UZ;

    let modifiedHeaders = request.headers.append(EReqHeaders.LANGUAGE, language);
    let modifiedParams = request.params;

    if (!request.params.get('TokenYuvarma')) {
      const access_token = sessionStorage.getItem(ELocalStorage.ACCESS_TOKEN) || '';
      modifiedHeaders = modifiedHeaders.append(EReqHeaders.AUTHORIZATION, `Bearer ${access_token}`);
    } else {
      modifiedParams = modifiedParams.delete('TokenYuvarma');
    }

    const modifiedRequest = request.clone({
      headers: modifiedHeaders,
      params: modifiedParams
    });

    return next.handle(modifiedRequest).pipe(
      catchError((error: any): Observable<any> => {
        if (error instanceof HttpErrorResponse && error.status === 0) {
          console.log('CORS');
        }
        if (error.status === 400) {
          this._toastService.error(
            error.error.message ?? 'ERROR_LIST.BAD_REQUEST'
          );
        }
        if (error.status === 500) {
          this._toastService.error('ERROR_LIST.SERVER_ERROR');
        }
        if (error.status === 401) {
          this._authService.logout();
        }
        if (error.status === 422) {
          this._toastService.error('ERROR_LIST.ERROR_RESPONSE');
        }
        if (error.status === 403) {
          this._toastService.error('ERROR_LIST.ACCESS_DENIED');
        }
        return throwError(() => error);
      })
    );
  }
}
