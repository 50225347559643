import {Injectable} from '@angular/core';
import {DefaultService} from '@core/services/default.service';
import {FormType} from '@core/enums/Form.enum';
import {jsonToFormData} from '@shared/utils/object.utils';
import {tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForwardCallService extends DefaultService {
  formName: '#call-forward';

  getUrl(): string {
    return 'call-center/api/v1/call-forward';
  }

  override insert(form: any) {
    return this._http
      .post(
        this.getTableUrl(),
        this.formType === FormType.FORM_DATA ? jsonToFormData(form) : form,
        {
          params: this.tableRequest.getParams()
        }
      )
      .pipe(tap(() => this.loadDataTable()));
  }
}
