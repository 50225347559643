export const coato_parent = [
  {
    id: 126,
    code: '03000',
    name: '"ANDIJON  HETK" AJ',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 22,
    number_legal_consumers: 28545,
    number_household_consumers: 0,
    number_substations: 102,
    number_tp: 8707,
    lon: '72.350664',
    lat: '40.78339',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:15:03.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  },
  // {
  //   id: 125,
  //   code: "00002",
  //   name: "test urganch",
  //   status: null,
  //   parent_code: "00000",
  //   cadastre_code: null,
  //   level_id: 1,
  //   number_branches: 0,
  //   number_legal_consumers: 0,
  //   number_household_consumers: 0,
  //   number_substations: 0,
  //   number_tp: 0,
  //   lon: null,
  //   lat: null,
  //   created_by: 1,
  //   updated_by: null,
  //   created_at: "2024-03-01T05:14:59.000000Z",
  //   updated_at: "2024-04-24T16:54:35.000000Z"
  // },
  {
    id: 113,
    code: '26000',
    name: 'AJ  "TOSHKENT SHAHAR ELEKTR TARMOQLARI KORHONASI"',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 11,
    number_legal_consumers: 20583,
    number_household_consumers: 20000,
    number_substations: 88,
    number_tp: 7625,
    lon: '69.28288826',
    lat: '41.30645599',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:21.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  },
  {
    id: 95,
    code: '35000',
    name: 'QORAQALPOQ  RESPUBLIKASI HUDUDIY FILIALI',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 17,
    number_legal_consumers: 20006,
    number_household_consumers: 0,
    number_substations: 2,
    number_tp: 9205,
    lon: '59.617987',
    lat: '42.460334',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:19.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  },
  {
    id: 80,
    code: '30000',
    name: '"FARG‘ONA  HETK" AJ',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 0,
    number_legal_consumers: 0,
    number_household_consumers: 0,
    number_substations: 0,
    number_tp: 0,
    lon: '71.783135',
    lat: '40.389448',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:16.000000Z',
    updated_at: '2024-04-24T16:54:35.000000Z'
  },
  {
    id: 81,
    code: '33000',
    name: 'XORAZM HUDUDIY FILIALI',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 13,
    number_legal_consumers: 25200,
    number_household_consumers: 100,
    number_substations: 6,
    number_tp: 8563,
    lon: '60.631476',
    lat: '41.550458',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:16.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  },
  {
    id: 57,
    code: '27000',
    name: 'TOSHKENT HUDUDIY FILIALI',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 22,
    number_legal_consumers: 34141,
    number_household_consumers: 0,
    number_substations: 89,
    number_tp: 8472,
    lon: '69.234515',
    lat: '41.429405',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:13.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  },
  {
    id: 56,
    code: '24000',
    name: '"SIRDARYO  HETK" AJ',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 0,
    number_legal_consumers: 0,
    number_household_consumers: 0,
    number_substations: 0,
    number_tp: 0,
    lon: '68.769089',
    lat: '40.509489',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:12.000000Z',
    updated_at: '2024-03-01T05:13:12.000000Z'
  },
  {
    id: 55,
    code: '22000',
    name: 'SURXONDARYO HUDUDIY FILIALI',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 0,
    number_legal_consumers: 0,
    number_household_consumers: 0,
    number_substations: 0,
    number_tp: 0,
    lon: '67.275451',
    lat: '37.228581',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:11.000000Z',
    updated_at: '2024-03-01T05:13:11.000000Z'
  },
  {
    id: 32,
    code: '18000',
    name: 'SAMARQAND  HUDUDIY FILIALI',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 22,
    number_legal_consumers: 40032,
    number_household_consumers: 0,
    number_substations: 52,
    number_tp: 14175,
    lon: '66.975827',
    lat: '39.654406',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:08.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  },
  {
    id: 31,
    code: '14000',
    name: 'NAMANGAN  HUDUDIY FILIALI',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 0,
    number_legal_consumers: 0,
    number_household_consumers: 0,
    number_substations: 0,
    number_tp: 0,
    lon: '71.67257',
    lat: '41.000078',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:07.000000Z',
    updated_at: '2024-04-24T16:54:35.000000Z'
  },
  {
    id: 30,
    code: '12000',
    name: '"NAVOIY  HETK" AJ',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 0,
    number_legal_consumers: 0,
    number_household_consumers: 0,
    number_substations: 0,
    number_tp: 0,
    lon: '65.373996',
    lat: '40.103107',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:06.000000Z',
    updated_at: '2024-04-24T16:54:35.000000Z'
  },
  {
    id: 29,
    code: '10000',
    name: '"QASHQADARYO  HETK" AJ',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 0,
    number_legal_consumers: 0,
    number_household_consumers: 0,
    number_substations: 0,
    number_tp: 0,
    lon: '65.789979',
    lat: '38.841605',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:05.000000Z',
    updated_at: '2024-04-24T16:54:35.000000Z'
  },
  {
    id: 15,
    code: '08000',
    name: 'JIZZAX HUDUDIY FILIALI',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 13,
    number_legal_consumers: 19605,
    number_household_consumers: 0,
    number_substations: 62,
    number_tp: 5359,
    lon: '67.828544',
    lat: '40.120302',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:03.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  },
  {
    id: 2,
    code: '06000',
    name: '"BUXORO  HETK" AJ',
    status: null,
    parent_code: '00000',
    cadastre_code: null,
    level_id: 1,
    number_branches: 12,
    number_legal_consumers: 21544,
    number_household_consumers: 1,
    number_substations: 4,
    number_tp: 9836,
    lon: '64.421725',
    lat: '39.767968',
    created_by: 1,
    updated_by: null,
    created_at: '2024-03-01T05:13:01.000000Z',
    updated_at: '2024-04-24T17:12:00.000000Z'
  }
];
