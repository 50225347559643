<router-outlet/>

<div class="call-section">
  @if (waitingCount > 0) {
    <span class="badge-filter">{{ waitingCount }}</span>
  }

  <button (click)="toggleWaitingsModal()" [disabled]="callHas()" class="open-close-modal">
    @if (isWaitingsModalOpen) {
      <img src="../../../../../assets/icons/arrow-down.icon.svg" alt="arrow-down.icon">
    } @else {
      <img src="../../../../../assets/icons/phone.icon.svg" alt="phone.icon">
    }
  </button>

  @if (callHideValue()) {
    <button class="open-close-modal-2" pTooltip="{{'OpenWindow' | translate}}" tooltipPosition="left"
            tooltipZIndex="43333" (click)="closeCallModal(true)">
      <keen-icon class="font-medium-3" name="arrow-up" type="outline" color="white" size="large-1"></keen-icon>
    </button>
  }

  <div [class]="getWaitingListClass()" class="waiting-list">
    <div class="header">
     <div class="flex items-center gap-2">
      <img alt="users" src="../../../../../assets/icons/users.icons.svg">
      {{ 'Components.CallCenter.WaitingConsumersCount' | translate }}: {{ waitingConsumers().length }}
     </div>
      <button [disabled]="refreshLoading()" [ngClass]="{'refreshbtnDisabled':refreshLoading() ,'refreshbtn': !refreshLoading()}" class="  rounded-sm" (click)="refreshWaitingList()"><img src="../../../../../assets/icons/refresh-icon.svg"/></button>
    </div>

    <div class="list">
      <div class="list__header">
        <div class="list__header__id">
          №
        </div>
        <div class="list__header__number">
          {{ 'CallCenter.Phone' | translate }}
        </div>
        <div class="list__header__time">
          {{ 'Components.CallCenter.TableHeader.Language' | translate }}
        </div>
      </div>

      <!--          @for (item of waitingConsumers(); track item.id) {-->
      <div class="list__content">
        <div *ngFor="let item of waitingConsumers(); let i = index; trackBy: trackById">
          <div class="list__content__item"
               style="background: rgba(74,252,5,0.1); border-bottom: 1px solid rgba(141,141,141,0.2);">
            <div class="list__content__item__id">
              <p-badge [value]="i + 1"/>
            </div>
            <div class="list__content__item__number">
              {{ item?.contact?.caller_phone | phoneNumber }}
            </div>
            <div class="list__content__item__time font-bold font-medium-2">
                  <span class="badge badge-light-dark" style="color: #333333 !important;">
                    {{ item?.lang }}
                  </span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="!callHas()" class="d-flex align-items-center justify-content-between">
      <div>
        @if (_outGoing) {
          <ups-button
            [disabled]="outgoingModalBtn"
            label="Components.CallCenter.OutgoingCall"
            [styleType]="'primary'"
            (btnClick)="openSelectorModal()"
          ></ups-button>
        }
      </div>

      <div class="flex items-center gap-4">
        <div class="pr-1">
          <!-- <button (click)="onMuteAudio()">click</button> -->


          @if (isPlay()) {
            <button
              pTooltip="{{'OffNotification' | translate}}"
              tooltipPosition="left"
              (click)="onMuteAudio(false)"
              type="button"
              tooltipZIndex="33333"
              class="onmuteaudio"
            >
              <img src="../../../../../assets/icons/notification.icons.svg" alt="notification">
            </button>
          } @else {
            <button
              pTooltip="{{'OnNotification' | translate}}"
              tooltipPosition="left"
              (click)="onMuteAudio(true)"
              type="button"
              tooltipZIndex="33333"
              class="onmuteaudio"
            >
              <img src="../../../../../assets/icons/notification-off.icon.svg" alt="notification-off">
            </button>
          }

        </div>
        <!-- <button
          (click)="operatorIsReady()"
          pTooltip="{{'Components.CallCenter.OperatorIsReady' | translate}}"
          tooltipPosition="left"
          tooltipZIndex="33333"
          type="button"
        >
          <img alt="operator-is-ready" src="assets/icons/operator-is-ready.icon.svg">
        </button> -->
      </div>
    </div>


    <div [class]="isAnsweringModalOpen ? 'answering-section-open' : 'answering-section-close' "
         class="answering-section">
      <div class="answering-section__info">
        <div class="answering-section__info__id">
          № {{ callDetails?.data?.call_id }}

          <img alt="user" src="../../../../../assets/icons/user.icon.svg">

        </div>

        <div class="answering-section__info__number">
          {{ callDetails?.data?.caller_phone | phoneNumber }}
        </div>

        <div>
          <button (click)="closeCallModal(false)" class="close-modal mr-50" pTooltip="{{ 'CloseWindow' | translate}}"
                  tooltipPosition="left" tooltipZIndex="43333">
            <keen-icon class="close-icon" color="white" name="minus" size="large-1" type="outline"/>
          </button>
          <button (click)="isEndCall()" [disabled]="isDisabledBtn()" class="close-btn"
                  pTooltip="{{ 'EndCall' | translate}}" tooltipPosition="left" tooltipZIndex="43333">
            <keen-icon class="close-icon" color="white" name="cross-circle" size="large-1" type="outline"/>
          </button>
        </div>
      </div>

      <div class="answering-section__actions">
        <div class="answering-section__actions__rec">

          <img alt="" src="../../../../../assets/icons/REC.icon.svg">
          {{ isCallTime() }}
        </div>

        @if (webSocket.isLostSignal()) {
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        }

        <div class="answering-section__actions__mute">

          <!-- @if(isPlay()){
              <button
              pTooltip="{{'Components.CallCenter.Call.CounterStatus.OffBtn' | translate}}"
              tooltipPosition="left"
              (click)="onMuteAudio(false)"
              type="button"
              tooltipZIndex="33333"
              class="onmuteaudio-answer"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="24" fill="#808b96" class="bi bi-bell-fill" viewBox="0 0 16 16">
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
            </svg>
              </button>
          }@else {
              <button
              pTooltip="{{'Components.CallCenter.Call.CounterStatus.OnBtn' | translate}}"
              tooltipPosition="left"
              (click)="onMuteAudio(true)"
              type="button"
              tooltipZIndex="33333"
              class="onmuteaudio-answer"
            >
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="24" fill="#808b96" class="bi bi-bell-slash-fill" viewBox="0 0 16 16">
              <path d="M5.164 14H15c-1.5-1-2-5.902-2-7q0-.396-.06-.776zm6.288-10.617A5 5 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5 5 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113zM10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z"/>
            </svg>
              </button>
          } -->

          @if (!isCallMute) {
            <button (click)="muteCurrentCall()" pTooltip="{{ 'NoSound' | translate}}" tooltipPosition="left"
                    tooltipZIndex="43333" class="answering-section__actions__mute__mute-btn">
              <img src="../../../../../assets/icons/mute.icon.svg" alt="mute">
            </button>
          } @else {
            <button (click)="unMuteCurrentCall()" pTooltip="{{ 'OnSound' | translate}}" tooltipPosition="left"
                    tooltipZIndex="43333" class="answering-section__actions__mute__mute-btn">
              <img src="../../../../../assets/icons/unmute.icon.svg" alt="unmute">
            </button>
          }

          @if (!(outgoing_type | async)) {
            <button (click)="openForwardCallModal()" [disabled]="isDisabledBtn()"
                    pTooltip="{{ 'CallForwarding' | translate}}" tooltipPosition="left" tooltipZIndex="43333"
                    class="answering-section__actions__mute__forward-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-telephone-forward-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708"/>
              </svg>
            </button>
          }

          @if (!isWaitingsModalOpen) {
            <button (click)="toggleWaitingsModal()" pTooltip="{{ 'WaitingClientsWindow' | translate}}"
                    tooltipPosition="left" tooltipZIndex="43333">
              <img src="../../../../../assets/icons/toggle-wating-modal-up.svg" alt="">
            </button>
          } @else {
            <button (click)="toggleWaitingsModal()" pTooltip="{{ 'CloseWindow' | translate}}" tooltipPosition="left"
                    tooltipZIndex="43333">
              <img src="../../../../../assets/icons/toggle-waiting-modal-down.svg" alt="">
            </button>
          }
        </div>
      </div>
    </div>

    <div [class]="isHangUpModalOpen ? 'hangup-section-open' : 'hangup-section-close'" class="hangup-section">
      <div class="hangup-section__time">
        {{ lastCallDetails?.time }}
      </div>

      <img alt="hang-up" src="../../../../../assets/icons/hang-up.icon.svg">
      <div class="hangup-section__info">
        {{ 'Components.CallCenter.UserEndedCall' | translate }}
      </div>
    </div>
  </div>
</div>

<div [class]="isRecordAudioModalOpen ? 'audio-record-section-open' : 'audio-record-section-close'"
     class="audio-record-section">
  <div class="audio-record-section__play">
    @if (!isRecordAudioPlaying) {
      <button class="audio-record-section__play__btn" (click)="toggleRecordedAudio(true)">
        <img src="../../../../../assets/icons/audio-play.icon.svg" alt="audio-play">
      </button>
    } @else {
      <button class="audio-record-section__play__btn" (click)="toggleRecordedAudio(false)">
        <img src="../../../../../assets/icons/audio-pause.icon.svg" alt="audio-pause">
      </button>
    }

    <div class="waveform"></div>


    <div class="audio-record-section__play__minute">
      {{ recordedAudioCurrentTime || '00:00' }} / {{ recordedAudioDuration || '00:00' }}
    </div>

    <button (click)="downloadRecordedAudio()" class="audio-record-section__play__menu">
      <img alt="audio-download" src="../../../../../assets/icons/audio-download.icon.svg">
    </button>

    <button (click)="toggleRecordedAudioModal(false)" class="audio-record-section__play__close">
      <img alt="cross" src="../../../../../assets/icons/cross.icon.svg">
    </button>
  </div>

</div>
