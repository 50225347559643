<div class="modal-body">
  <form [formGroup]="form">
    <div class="multiselect-content">
      <label class="multiselect-label">{{ "Selects.Regions" | translate }}</label>
      <p-multiSelect
        (onChange)="onMultiSelectChange($event)"
        [(ngModel)]="selectedRegions"
        [appendTo]="'body'"
        [filter]="false"
        [options]="regionOptions"
        [panelStyleClass]="'add-ntf-multiselect'"
        [required]="true"
        [selectedItemsLabel]="getSelectedRegionsLabel()"
        optionLabel="name"
      >
        <ng-template pTemplate="header">
          <div class="my-text-content">
            <label (click)="selectAll()" style="margin-left: 8px; cursor: pointer">
              {{ "Notification.SelectAll" | translate }}
            </label>
          </div>
        </ng-template>
      </p-multiSelect>
      <div class="error-content"></div>
    </div>

    <div class="second-multiselect-content">
      <label class="multiselect-label">{{ "Dashboard.Users" | translate }}</label>
      <p-multiSelect
        (onChange)="onUserMultiSelectChange($event)"
        [(ngModel)]="selectedUsers"
        [appendTo]="'body'"
        [disabled]="userDisabled"
        [filter]="false"
        [options]="userOptions"
        [panelStyleClass]="'add-ntf-multiselect'"
        [required]="true"
        [selectedItemsLabel]="getSelectedUsersLabel()"
        optionLabel="name"
      >
        <ng-template pTemplate="header">
          <div class="my-text-content">
            <label (click)="selectUserAll()" style="margin-left: 8px; cursor: pointer">
              {{ "Components.CallCenter.ToAllOperators" | translate }}
            </label>
          </div>
        </ng-template>
      </p-multiSelect>
      <div class="error-content"></div>
    </div>

    <div class="w-full">
      <!-- <app-cab-autocomplete
        [_defaultService]="_usersService"
        [firstSelect]="false"
        [additionalList]="[{ user_id: null, name: 'Components.CallCenter.ToAllOperators' | translate }]"
        [required]="false"
        class="w-full"
        bindLabel="name"
        bindValue="user_id"
        formControlName="to_user"
        label="Components.CallCenter.Operator"
        [searchKey]="'name'"
        [appendTo]="'body'"
      /> -->

      <app-het-textarea
        [label]="'Notification.MessageDescription'"
        [required]="true"
        [rows]="4"
        class="w-100"
        formControlName="content"
      ></app-het-textarea>
    </div>
  </form>
</div>
<div class="modal-footer">
  <ups-button
    (btnClick)="onSubmit()"
    [isPending]="isPending()"
    label="Notification.MessageSend"
    styleType="primary"
    type="submit"
  />
</div>
