export const locale = {
  lang: "crl",
  data: {
    Menu: {
      Spravochnik: {
        Title: "Маълумотномалар",
        Children: {
          Departments: "Филиал бўлимлари",
          Branches: "COATO филиаллари",
          TicketTypes: "Мурожаат турлари",
          TicketStatuses: "Ҳолат турлари",
          TicketPriorities: "Даража турлари",
          Boards: "Мурожаатлар",
          Projects: "Лойиҳалар",
          Activities: "Фаолият турлари",
        },
      },
      Home: "Бош саҳифа",
      CRM: "Бошқарув панели",
      HelpDesk: "Ёрдам ойнаси",
      GeoMap: "Геохарита",
      GeoMapSetting: "Геохарита созламалари",
      CallCenter: "Call марказ",
      ChatService: "Чат хизмати",
      Statistics: "Статистика",
      Consumers: "Истеъмолчилар",
      All: "Умумий",
      LegalConsumers: "Юридик истеъмолчилар",
      LegalConsumer: "Юридик истеъмолчи",
      PhysicalConsumers: "Маиший истеъмолчилар",
      PhysicalConsumer: "Маиший истеъмолчи",
      VIPCustomers: "VIP мижозлар",
      BlockContacts: "Қора рўйхат",
      VIPCustomer: "VIP мижоз",
      Users: "Ходимлар",
      Operators:"Операторлар",
      Management: "Бошқарув",
      Appeals: "Мурожаатлар",
      Marketing: "Маркетинг",
      Roles: "Роллар",
      UserMonitoring: "Ходимлар кузатуви",
      Services: "Хизматлар",
      Calls: "Қўнғироқлар",
      CallsHistory: "Тарих",
      CallForward: "Йўналтирилган",
      Evaluation: "Баҳолаш",
      SendNotificatin: "Билдиришнома юбориш",
      SendNotificationToClients: "Мижозларга юбориш",
      SendNotificationToOperators: "Операторларга юбориш",
      AssignRoles: "Рол бириктириш",
      RoleNotMessage: 'Рол мавжуд эмас!',
      LiveCalls: "Жонли",
      QueueCalls: "Навбатдаги",
      CallRecording: "Ёзиб олинганлар",
      PersonnelManagement: "Ходимлар бошқаруви",
      Tickets: "Мурожаатлар",
      Contracts: "Шартномалар",
      Reports: "Хисоботлар",
      Report: "Хисобот",
      RequestsReports: "Ҳудудлар",
      StatisticalReports: "Статистикаси",
      TimeReports: "Вақт кесими бўйича",
      OperatorReports: "Операторлар бўйича",
      Main: "Асосий",
      ViewContract: "Шартномани кўриш",
      UserCode: "Фойдаланувчи коди",
      ConsumerCode: "Исте\'молчи коди",
      ContractNumber: "Шартнома рақами",
      FullNameofConsumer: "Исте\'молчининг тўлиқ исми",
      ShortNameofConsumer: "Исте\'молчининг қисқача номи",
      INN: "ИНН",
      Account: "Ҳисоб",
      ContractDate: "Шартнома санаси",
      StatisticalData: "Статистик ма\'лумотлар",
      AddressAndBankDetails: "Манзил ва банк реквизитлари",
      ConditionsofEnergy: "Энергия та\'миноти шартлари",
      PowerandAmount: "Қувват ва энергия миқдори",
      OtherInfo: "Бошқа ма\'лумотлар",
      SubtractedFrom: "Ажратилди",
      ListMeter: "Ҳисоблагичлар рўйхати",
      ControlCode: "Бошқарув коди",
      OKPO: "ОКПО",
      Subgroup: "СООГУ кичик гуруҳи",
      Codeby: "ОКОНҲ бўйича код",
      FormCodeby: "ФСC бўйича шакл коди",
      FormCodeby1: "CОПФ бўйича шакл коди",
      PlaceofRegistration: "Рўйхатдан ўтиш жойи",
      Prefiks:"Префикс",
      Incidents: "Инцидентлар",
      BotReport:"Бот ҳисоботи",
      Spy:"Шпионаж",
      SipNumbers:"SIP рақамлар",
      BreakTime: 'Танаффус вақтлар',
      WorkTime: 'Иш вақтлар',
      CallTime:"Қўнгироқлар орасидаги вақт",
      NotAnsweredCall:"Жавобсиз қўнғироқлар",
      List: "Рўйхат",
      BreakTimes: "Танаффус вақтлари",
      WorkTimes: "Иш вақтлари",
      ReleAudit:"Реле Аудит",
      LiveStats:"График",
      OperatorReport:"Операторлар Ҳисоботи",
      CoatoSettings: "CОАТО руҳсатлари",
      CoatoSettingsTitle: "Операторларни CОАТОга нисбатан кириш ҳуқуқлари",
      CommonReport:"Умумий Ҳисобот",
      PhoneChangeAudit:"Телефон рақамлар ҳисоботи",
      DeleteChannels:"Қўнғироқ каналларни ўчириш",
      VideoStreamMonitoring:"Ходимлар видео кузатуви"
    },

    Block:{
      Active: "Фаол",
      InActive: "Нофаол",
    },
    Time:{
      Hour:"Соат",
      Minute:"Дақиқа",
      Second:"Сония"
    },

    Actions: {
      Title: "Харакат",
      Add: "Қўшиш",
      AddTicket: "Мурожаат қўшиш",
      Cleaning: "Тозалаш",
      SendNotification: "Bildirishnoma yuborish",
      Update: "Таҳрирлаш",
      UpdateCallData: "Қўнғироқ тафсилотларини янгилаш",
      Delete: "Ўчириш",
      DeleteImg: "Ростан ҳам бу расмни ўчиришни ҳоҳлайсизми?",
      DeleteImgMessage: "Расм ўчирилди!",
      DeleteIncidentsWarning: "Ростан ҳам бу инсидентни ўчиришни ҳоҳлайсизми?",
      DeleteIncidentsMessage: "Инсидент маълумотлари ўчирилди!",
      UpdateIncidentsTitle: "Таҳрирлашни тасдиқлаш",
      UpdateIncidentsWarning: "Ростан ҳам бу инсидентни таҳрирлашни ҳоҳлайсизми?",
      UpdateIncidentsMessage: "Инсидент маълумотлари таҳрирланди!",
      ForwardCall: "Қўнғироқни йўналтириш",
      Buttons: {
        Submit: "Сақлаш",
        Cancel: "Бекор қилиш",
        Open: "Очиш",
        LoadMore:"Кўпроқ юкланг",
        Forward: "Йўналтириш",
        WorkBind:"Иш вақти бириктириш"
      },
      Toast: {
        Massage: "Ўчиришни тасдиқлаш",
        ToastDelete: "Танланган жадвал қатори муваффақиятли ўчирилди!",
        ConfirmMassage: "Диққат ўчиришни тасдиқланг!",
        ConfirmButton: "Ўчириш",
        CancelButton: "Бекор қилиш",
        Yes: "Ҳа",
        No: "Йўқ",
      },
    },

    Dashboard: {
      Statistics: "Статистика",
      AppealOfNumbers: "Мурожаатлар сони ҳудудлар кесимида",
      CallOfNumbers: "Қўнғироқлар сони ҳудудлар кесимида",
      AppealOfStatus: "Мурожаатлар ҳолати статистикаси",
      AppealOfModuleType: "Мурожаатлар қайердан яратилганлиги бўйича статистика",
      AppealOfTypeByRegions: "Мурожаатлар турлари бўйича статистика ҳудудлар кесимида",
      Done: "Бажарилган",
      InProgress: "Жараёнда",
      Checked: "Текширилмоқда",
      Backlog: "Органилмоқда",
      Archived: "Архивланган",
      AppealsOfMonths: "Мурожаатлар сони ойлар кесимида",
      Appeals: "Мурожаатлар",
      GradeByUser: "Фойдаланувчилар берган баҳолар",
      Users: "Фойдаланувчилар",
      Excellent: "Aжойиб",
      Good: "Яхши",
      Satisfactory: "Қониқарли",
      NotSatisfactory: "Қониқарсиз",
      Bad: "Ёмон",
      AppealOfType: "Мурожаатлар турлари бўйича статистика",
      Complaints: "Шикоятлар",
      Suggestions: "Таклифлар",
      MustBeDone: "Бажарилиши керак",
      Completed: "Якунланди",
      DDone: "Бажарилди",
    },

    Reports: {
      Requests: "Мурожаатлар",
      Statistical: "Статистика",
      Users: "Ходимлар",
      Tickets: "Мурожаатлар",
      Time: "Қўнғироқ давомийлиги",
      Region: "Вилоятлар",
      Operator: "Операторлар",
      SubName: {
        Time: "Қўнғироқ давомийлиги бўйича",
        Region: "Вилоятлар бўйича",
        Operator: "Операторлар бўйича",
      },
    },

    Table: {
      Pagination: "{{totalPages}} тадан  {{starterItemNumber}} дан {{endItemNumber}} гача",
      HeaderNumbers: "№",
      PaginationShow: "Кўрсатиш",
      NoData: "Маълумот топилмади!",
    },

    Components: {
      Users: {
        Title: "Ходимлар",
        TableHeader: {
          Name: "Исм",
          Email: "E-mail",
          EmailVerifiedAt: "Эмаил тасдиқланган вақти",
          CreatedAt: "Яратилган вақти",
          UpdatedAt: "Ўзгартирилган вақти",
          CoatoBranch: "COATO филиал",
          CoatoDepartment: "COATO бўлим",
          SipNumber: "SIP рақами",
          Phone: "Телефон",
          Address: "Манзил",
          Status: "Ҳолат",
          ACTIVE: "Фаол",
          PERMISSION: "Руҳсат этилган",
          FORBIDDIN: "Тақиқланган",
          IN_ACTIVE: "Фаол эмас",
          LocalPassword: "Локал парол",
          LocalPassword2: "Локал паролни тасдиқланг",
          ConfirmLocalPassword: "Иловани очиш учун лоcал паролни киритинг",
           Staff:"Ходим"
        },
      },
      Consumers: {
        LegalConsumers: {
          Title: "Юридик истеъмолчилар",
          TableHeader: {
            ConsumerPersonalAccount: "Истеъмолчи коди",
            ConsumerBusinessName: "Истеъмолчи номи",
            AddressNote: "Манзил",
            BookCode: "Китоб",
            AccountingStatus: "Ҳисоблаш ҳолати",
            CalculationCategory: "Ҳисоблаш тури",
            ReportStatus: "Ҳисобот ҳолати",
            ConsumerStatus: "Истеъмолчи ҳолати",
            MetersCount: "Ҳисоблагичлар сони",
            ActionColumn: "Ҳаракатлар",
          },
          DateConclusionOfAgreement: "Дата заключения договора",
          ContractNumber: "Шартнома рақами",
          ContracterFullName: "Полное на-вание потребителя",
        },
        LegalConsumersById: {
          ConsumerPersonalAccount: "Истеъмолчи коди",
          SummaryDate: "Хулоса санаси",
          ContractNumber: "Шартнома рақами",
          ContractRenewalDate: "Шартномани узайтириш санаси",
          CustomerFullName: "Истеъмолчининг тўлиқ номи",
          CustomerShortName: "Истеъмолчининг қисқача номи",
          FaceType: "Юз тури",
          INN: "ИНН",
          NDS_RegistrationCode: "НДС рўйхатдан ўтиш коди",
          Score: "Ҳисоб",
          PromNepromPred: "Пром/Непром/Пред",
          Resident: "Резидент",
          ResidentYes: "Ҳа",
          ResidentNo: "Йўқ",
          CadastreAddress: "Кадастр бўйича манзил",
          CadastreCode: "Кадастр коди",
          Sector: "Сектор",
          PINFL: "ПИНФЛ",
          StatisticalInformation: {
            Title: "Статистик маълумотлар",
            PaymentCode: "Тўлов коди",
            OKPO: "ОКПО",
            SOOGU: "СООГУ",
            SOOGU_Subgroup: "СООГУ кичик гуруҳи",
            OKED_Code: "ОКЕД кўди",
            FormCode_KFS: "ККФ бўйича шакл коди",
            FormCode_COPF: "CОПФ шакл коди",
            PlaceOfRegistration: "Рўйхатдан ўтиш жойи",
            ConsumerGroup: "Истеъмол гуруҳи",
          },
          Address: {
            Title: "Манзил",
            SOATO_Address: "СОAТО манзиллари",
            Neighborhood: "Маҳалла",
            Street: "Кўча",
            Home: "Уй",
            Home2: "Уй(Дом)",
            FlatName: "Квартира",
            CorpusName: "Корпус",
            LegalAddress_SOATO: "СОAТО юридик манзил",
            HigherOrganization: "Юқори ташкилот",
            ContactPhoneNumber: "Aлоқа телефон рақами",
            MobilePhone: "Мобил телефон",
            EmailAddess: "Електрон почта манзили",
          },
          BankDetails: {
            Title: "Банк реквизитлари",
            CalculationType: "Ҳисоблаш тури",
            MFO: "МФО",
            Score: "Ҳисоб",
            BranchCode: "Филиал коди",
            BranchRecruitment: "Филиални ёллаш",
            Supervisor: "Назоратчи",
            Accountant: "Бухгалтер",
            FIO: "ФИО",
            Position: "Лавозим",
          },
        },
        PhysicalConsumers: {
          Title: "Жисмоний истеъмолчилар",
          TableHeader: {
            PersonalAccount: "Шахсий ҳисоб-рақам",
            Name: "ФИО потребителя",
            Passport: "Пасспорт",
            Address: "Адрес",
            Agent: "Aгент",
            TP: "ТП",
            Transformer: "Фидер",
            CalculationType: "Тип расчета",
            CalculationStatus: "Состояние",
            LastReading: "Последний обход",
            LastReadingDate: "Дата последний обход",
          },
        },
        PhysicalConsumersById: {
          FaceCard: {
            Title: "Истеъмолчи картаси",
            PersonalAccount: "Шахсий ҳисоб",
            MobilePhone: "Мобил телефон",
            Address: "Манзил",
            Neighborhood: "Маҳалла",
            StreetAddress: "Уй манзили",
            Surname: "Фамилия",
            FirstName: "Исм",
            FatherName: "Шариф",
            Reminder: "Еслатма",
          },
          Document: {
            Title: "Ҳужжат",
            DocumentType: "Ҳужжат тури",
            IdCard: "ИД карта",
            Passport: "Пасспорт",
            DocumentSeries: "Ҳужжат серияси ва №",
            GivenWhom: "Ким томонидан берилган",
            DateIssue: "Берилган сана",
            DateBirth: "Туғилган сана",
            INN: "ИНН",
            CadastralCode: "Кадастр коди",
            PINFL: "ПИНФЛ",
            Language: "Тил",
          },
          GeneralInformation: {
            Title: "Ҳамма маълумотлар",
            Telephone: "Телефон",
            Agent: "Aгент",
            Book: "Китоб",
            Brigade: "Бригада",
            Status: "Ҳолат",
            StatusAlive: "Тирик",
            StatusDead: "Ўлган",
            Consent: "Розилик",
            ConsentYes: "Ҳа",
            ConsentNo: "Йўқ",
          },
          Counter: {
            Title: "Ҳисоблагич",
            CounterType: "Ҳисоблагич тури",
            SerialMeterNumber: "Ҳисоблагични серия рақами",
            SerialNumber: "Серия рақами",
            NoStandardSeals: "Узстандарт муҳрлар №",
            DateUzstandartSeal: "Узстандарт муҳрининг санаси",
            EnsbytSeals: "Енсбйт муҳрлар №",
            DateEnsbytSeals: "Енсбйт муҳрининг санаси",
            BPI_No: "БПИ муҳр №",
            BPI_Date: "БПИ муҳр санаси",
            MeterInstallationLocation: "Ўрнатиш жойи",
            ASKUE_Type: "AСКУЕ тури",
            AgreementDate: "Келишув санаси",
            ASKUE_Connect: "AСКУЕ га уланиш",
            ASKUE_ConnectYes: "Ҳа",
            ASKUE_ConnectNo: "Йўқ",
            CounterStatus: "Ҳисоблагич ҳолати",
            BitPower: "Бит ҳажми",
            TransformerCoefficient: "Трансформация нисбати",
            SolarPanel: "Қуёш панели",
            TariffPrice: "Таъриф миқдори",
            installationReadingPlus: "Ўрнатиш вақтидаги кўрсаткичлар (+A)",
            installationReadingMinus: "Ўрнатиш вақтидаги кўрсаткичлар (-A)",
            PreviousIndications: "Дастлабки ўқишлар |А|",
            InstallationDate: "Ўрнатиш санаси",
          },
          Certificate: {
            Title: "Сертификат",
            No: "Сертификат №",
            Check: "Текшириш",
            ExpirationDate: "Aмал қилиш",
          },
          ConstructionInformation: {
            Title: "Тузилиш ҳақида маълумот",
            Substation: "Подстансия",
            Nourishing: "Озиқлантирувчи",
            Transformer: "Трансформатор",
          },
          Agreement: {
            Title: "Шартнома",
            TariffSum: "Таъриф (сум)",
            TariffCode: "Тариф коди",
            TariffName: "Тариф номи",
            ContractNumber: "Шартнома рақами",
            DateApplication: "Aриза санаси",
            TariffChangeDate: "Таърифни ўзгартириш санаси",
            DateConclusionContract: "Шартнома тузилган сана",
            ContractRenewalDate: "Шартномани узайтириш санаси",
            TariffImplementationDate: "Таъриф жорий қилинган сана",
            SignUp_No_REP: "Рўйхатдан ўтиш № ва РЕП",
          },
          AdditionalInformation: {
            Title: "Қўшимча маълумотлар (Намунавий шартнома бўйича VII бўлим)",
            LEP: "ЛЕП",
            One_TP: "1 Озиқлантирувчи + ТП",
            Two_TP: "2 Озиқлантирувчи + ТП",
            TypeAccommodation: "Турар жой тури",
            ClassResidence: "Уй-жой тоифаси",
            ApartmentGassed: "Квартира газлаштирилганми",
            ApartmentGassedYes: "Ҳа",
            ApartmentGassedNo: "Йўқ",
            ConnectedCentralSystem: "Марказий иситиш тизимига уланганми",
            ConnectedCentralSystemYes: "Ҳа",
            ConnectedCentralSystemNo: "Йўқ",
          },
          ConsumerAccess_LEP: {
            Title: "Истеъмолчи 2х ёки 3х учинчи томон ЛЕП-0,4 кв",
            NumberLightingDevices: "Уй жиҳозларини сони (дона)",
            PowerLightingDevices: "Уй жиҳозларини қуввати (кВт)",
            InstalledPower: "Уланган қувват (кВт)",
            NumberSockets: "Резидентлар миқдори (дона)",
            TotalPowerConsumption: "Умумий қувват истеъмоли (кВт)",
            TheNumberPersons: "Яшаш сони (шахслар)",
          },
          ContractValue: {
            Title: "Шартнома қиймати (кВт/соат)",
            January: "Январ",
            February: "Феврал",
            March: "Март",
            One_KV: "1 кв",
            AnnualContract: "Йиллик шартнома бўйича (кВт/соат)",
            April: "Aпрел",
            May: "Май",
            June: "Июн",
            Two_KV: "2 кв",
            AnnualAmount: "Йиллик миқдор (сум)",
            July: "Июл",
            August: "Aвгуст",
            September: "Сентябр",
            Three_KV: "3 кв",
            October: "Октябр",
            November: "Ноябр",
            December: "Декабр",
            Four_KV: "4 кв",
          },
        },
        VIPCustomers: {
          Search: "Телефон рақам",
          AllCustomers: "Барча мижозлар",
        },
        Consumers: {
          Title: "Истеъмолчилар",
          TableHeader: {
            FirstName: "Исм",
            LastName: "Фамилия",
            MiddleName: "Отасининг исми",
            Phone: "Телефон рақам",
            CreatedAt: "Яратилган вақти",
          },
        },
        Children: {
          LegalConsumers: "Legal consumers",
          PhysicalConsumers: "Physical consumers",
        },
        ConsumerModalTitle: "Истеъмолчини маълумотлари",
        Title: "Истеъмолчилар",
      },
      Spravochniks: {
        SpravochniksBranches: {
          Title: "Филиаллар",
          TableHeader: {
            Code: "Код",
            Name: "Номи",
            Email: "Email",
            CreatedAt: "Яратилган вақти",
            UpdatedAt: "Ўзгартирилган вақти",
            Status: "Ҳолати",
            CoatoBranch: "COATO филиал",
            CoatoDepartment: "COATO бўлим",
            Time: "Bақти",
          },
        },
        SpravochniksDepartments: {
          Title: "Бўлимлар",
          TableHeader: {
            Code: "Код",
            CoatoCode: "COATO код",
            Name: "Номи",
            Email: "Email",
            CreatedAt: "Яратилган вақти",
            UpdatedAt: "Ўзгартирилган вақти",
            Status: "Ҳолати",
            CoatoBranch: "COATO филиал",
            CoatoDepartment: "COATO бўлим",
            CoatoBranchId: "COATO филиал",
            CoatoBranchName: "COATO филиал номи",
          },
        },
        Projects: {
          Title: "Лойиҳалар",
          TableHeader: {
            Name: "Номи",
            ShortDesk: "Қисқача номи",
            CreatedAt: "Яратилган вақти",
            UpdatedAt: "Ўзгартирилган вақти",
            Description: "Тавсиф",
          },
        },
      },
      Dashboard: {
        SelectPeriod: "Даврни танланг",
        RequestType: "Мурожаат тури",
        SelectRegion: "Ҳудудни танланг",
        RequestStatus: "Мурожаатлар ҳолати статистикаси",
        AppealType: "Мурожаатлар турлари бўйича статистика",
      },
      Tickets: {
        CoatoCode: "COATO код",
        TableTitle: "Мурожаатлар рўйхати",
        Type: "Тури",
        Importance: "Муҳимлиги",
        Status: "Ҳолати",
        Title: "Сарлавҳа",
        Priority: "Даражаси",
        DateSelect: "Вақт оралиқи",
        Degree: "Даражаси",
        Performer: "Бажарувчи",
        Deadline: "Тугаш вақти",
        Archive: "Aрхив",
        Archived: "Aрхивланган",
        NotArchived: "Aрхивланмаган",
        Description: "Тавсиф",
        File: "Файл",
        Created: "Яратилган вақти",
        Updated: "Таҳрирланган вақти",
        Creator: "Яратувчи",
        Where: "Яратилиш манзили",
        FileDrag: "Файлни шу ерга тортинг ёки",
        FileSelect: "файлни танланг",
        MaxFile: "Махсимал файл юбориш ҳажми",
        Tabs: {
          Comments: "Изоҳлар",
          History: "Тарих",
          StatusHistory: "Ҳолат Тарихи",
        },
        WriteComment: "Изоҳ ёзинг",
        Edit: "Ўзгартириш",
        Assigners: "Бажарувчилар",
        NotFound: "Маълумот топилмади!",
        Confirmation:{
          message:"Мурожаат xолатини",
          from:"дан",
          to:"га",
          question:"ўзгартиришни хоxлайсизми"
        },
        HasFile:{
          No:"Файл мавжуд эмас",
          Yes:"Файл мавжуд"
        },
        EmpthyDescription:"Батафсил тавсиф қўшинг...",
        Ticket:'Мурожаат',
        Create:"яратилди",
        Update:"ўзгаритирилди",
        EmpthyValue:"Бўш қиймат",
        CreateFileName:"Яратилган файл номи",
        DeletedFileName:"Ўчирилган файл   номи",
        AssignUser:"Бириктирилган бажарувчи",
        UnAssignUser:"Бекор қилинган бажарувчи"
      },
      CallCenter: {
        TableHeader: {
          QueueCalls: "Навбатдагилар",
          TableHistory: "Қўнғироқлар тарихи",
          CallForward: "Йўналтирилган қўнғироқлар",
          TableRecording: "Ёзиб олинган қўнғироқлар",
          Type: "Тури",
          Status: "Ҳолати",
          From: "Кимдан",
          ToOperator: "Кимга",
          To: "Оператор",
          Via: "Телефон",
          CreatedAt: "Яратилган вақти",
          End: "Тугатилган вақти",
          Time: "Вақт (дақиқа/сония)",
          Audio: "Суҳбат ёзуви",
          Tap: "Оператор",
          via: "Телефон",
          ContactTime: "Мулоқот вақти",
          WaitingTime: "Кутиш вақти",
          TotalWaitingTime: "Умумий кутиш вақти",
          TotalDurationTime: "Умумий мулоқат вақти",
          CustomerPhoneNumber: "Мижознинг телефон рақами",
          Language: "Тил",
          Filters: "Фильтр",
          FromOperatorSip: "Йўналтирган операторнинг SIP рақами",
          ToOperatorSip: "Қабул қилган операторнинг SIP рақами",
          Incoming: "Кирувчи",
          Outgoing: "Чиқувчи",
          IncomingCalls: "Кирувчи қўнғироқлар",
          OutgoingCalls: "Чиқувчи қўнғироқлар",
          Answered: "Жавоб берилган",
          NotAnswered: "Жавоб берилмаган",
          Missed:"Ўтказиб юборилган",
          MissedCallCount:"Ўтказиб юборилганлар",
          HungUp:"Қўйиб юборилган",
          CallType: "Қўнғироқ тури",
          CallStatus: "Қўнғироқ ҳолати",
          MissedCall: "Жавобсиз қўнғироқ рақами",
          LastCall: "Охирги қўнғироқ рақами",
          Interval:"Интервал",
          SipNumber:"SIP-Рақам",
          IVRYes:"IVR дан фойдаланган истеъмолчилар",
          IVRNo:"IVR дан фойдаланмаган истеъмолчилар",
          IVR:"IVR",
          Call:"Қўнғироқ",
          AllTime:"Барча даврлар",
          AllTimeSee:"Барча даврларни кўриш",
          ViewEvaluation: "Истеъмолчи бириктирмасдан баҳолашда кўриниши",
          AccountNumber: "Ҳисоб рақами",
          ConsumerType: "Истеъмолчи тури",
          HOUSEHOLD: "Маиший",
          LEGAL: "Юридик",
          ConsumersReport: "Истеъмолчилар ҳисоботи",
          LiveStreamingAllowed:"Видео кузатув",
          VideoRecord:"Видео ёзувлар",
          StartTime:"Бошланиш вақти",
          Link:"Ҳавола",
          Record:"Ёзувлар",
          UzbRusKk:"Узбек | Рус | Қорақалпоқ",
          DurationTotalAvg:"Давомийлик(Умумий|Ўртача)",
          WorkPercent:"Иш фоизи"
        },
        TableUserID: {
          UserData: "Мижоз маълумотлари",
          ReqType: {
            Title: "Aриза тури",
            Request: "Сўров",
            Complaint: "Шикоят",
            Offer: "Таклиф",
            Service: "Хизмат кўрсатиш",
          },
          Description: "Еслатма",
          UserType: {
            Title: "Мижоз муомаласи",
            Well: "Яхши",
            Medium: "Ўртача",
            Rude: "Ёмон",
            VeryBad: "Жуда ёмон",
            Lose: "Aдашиб тушган рақам",
          },
          SubmitBtn: "Жўнатиш",
          CallHistory: "Қўнғироқлар тарихи",
          UserAngryTitle: "Жаҳлдор",
        },
        Call: {
          TableHeader: {
            Phone: "Телефон рақами",
            FISH: "Истеъмолчининг тўлиқ исми",
            oneName: "Исми",
            Type: "Истеъмолчининг тури",
            Fio: "Ф.И.О",
            Address: "Манзил",
            PersonalAccount: "Шахсий ҳисоб",
            CounterNumber: "Ҳисоблагич рақами",
            CounterInformation: "Ҳисоблагич маълумотлари",
            InterruptStartTime: "Узилиш бошланиш вақти",
            InterruptEndTime: "Узилиш тугаш вақти",
          },
          Status: {
            CounterStatus: "Ҳисоблагич ҳолати",
            StateBalance: "Баланс ҳолати",
            OtherProblems: "Бошқа муаммолар",
            SubStatus: {
              Good: "Ҳолати яхши",
              Debt: "Қарз бор",
              Repair: "Та’мирлаш ишлари",
              Bad:"Ҳолати ёмон",
              Unknown:"Номаълум"
            },
          },
          CounterStatus: {
            Launch: "Ишга тушириш",
            Loading: "Юкланмоқда",
            On: "Ёқилган",
            Off: "Ўчирилган",
            OnBtn: "Ёқиш",
            OffBtn: "Ўчириш",
            CountersStatus: "Ҳисоблагичлар",
            ChangeCountersStatus: "Ҳисоблагичлар ҳолатини ўзгартириш",
            LaunchToastText: "Муваффақиятли ишга туширилди",
            Error400Text: "Ҳисоблагични 10 дақиқада 1 марта қайта ишга тушириш мумкин",
            AllBalance: "Жами баланс:"
          },
          UserSearch: "Фойдаланувчини қидириш",
          UserInfo: "Истеъмолчи маълумотлари",
          ConsumerType: "Истеъмолчи тури",
          ConsumerName: "Истеъмолчи маълумоти",
          PassportNumber: "Пасспорт рақами",
          PassportSerial: "Док. серияси ва №",
          HomePhone: "Уй телефони",
          SolveProblem: "Муаммони ҳал қилиш учун ўтинг:",
          History: "Қўнғироқлар тарихи",
          FeedbackAboutClient: "Мижоз ҳақида фикр-мулоҳазалар",
          TotalItemsSelected: "{ count } та маълумот танланди",
          Incident: "Инцидент",
          All: "Жами",
          Electr: "Электр",
          ActViolation: "Акт қоида-бузилиши",
          Fine: "Пеня",
          Reconnection: "Қайта уланиш",
          WithoutContract: "Шартномасиз истеъмол",
          Reactive: "Реактив",
          Bust: "Ортиқча",
          Shortage: "Камчилик",
          Services: "Хизматлар",
        },
        Title: "Call Cентер",
        OperatorIsReady: "Жавоб бериш!",
        WaitingClientsNotFound: "Кутаётган мижозлар мавжуд эмас",
        CountOfClientsWaiting: "{{count}} та мижоз оператор жавоб беришини кутмоқда",
        CallConnectedSuccessfully: "Алоқа муваффақиятли ўрнатилди!",
        CallEnded: "Алоқа тугатилган!",
        SocketConnected: "Сервер билан алоқа муваффақиятли ўрнатилди!",
        SocketError: "Хатолик юз берди. Илтимос, саҳифани янгиланг ва қайта уриниб кўринг.",
        SocketClosed: "Сервер билан алоқа узилди!",
        NotConnect: "Уланмади. Илтимос кейинроқ қайта уриниб кўринг",
        ConfirmSpyCall: "Қўнғироқни кузатишни тасдиқлаш",
        ConfirmSpyCallMessage: "Ҳақиқатан ҳам бу қўнғироқни кузатишни хоҳлайсизми?",
        WaitingConsumersCount: "Қўнғироқлар навбати",
        CallIsNotAvailable: "Сизда қўнғироқ мавжуд эмас",
        AttachCallToConsumerSuccessfull: "Қўнғироқ мижозга муваффақиятли бириктирилди",
        MobilePhoneEdited: "Мобил телефон муваффақиятли алмаштирилди",
        AttachCallToConsumer: "Қўнғироқни бириктириш",
        CallEvaluation: "Қўнғироқни баҳолаш",
        AttachCallToConsumerConfirm: "Ҳақиқатан ҳам жорий қўнғироқни ушбу истеъмолчига бириктирмоқчимисиз?",
        UserEndedCall: "Қўнғироқ тугади",
        MakeCall: "Қўнғироқ қилиш",
        ToastCalled: "Қўнғироқ амалга оширилди!",
        OutgoingCalls: "Чиқувчи қўнғироқ",
        OutgoingCall: "Чиқувчи",
        Operator: "Оператор",
        ToAllOperators: "Барча операторларга",
        CallPrefix:{
          Region: "Ҳудуд",
          Prefix:"Префикс"
       },
       SpyReport:{
        Title:"Шпионаж ҳисоботи",
        TableHeader: {
          Name: "Исм",
          Email: "Эмаил",
          CreatedAt: "Яратилган вақти",
          StartSpy:"Бошланиш вақти",
          EndSpy:"Тугаш вақти"
        },
      },
      Attention:"Диққат",
      DeleteChannelTitle:"Барча қўнғироқ каналларини қайта ишга туширишни амалга ошириш учун ўчириш тугмасини босинг.",
      Mention:"Эслатма",
      MentionAction:"Эслатма: Барча ҳаракатларингиз кузатилиб борилади.",
      NoLiveVideo:"Жонли видео мавжуд эмас",
      LiveVideoActive:"Жонли видео фаол",
      LiveVideoError:"Жонли видео ҳозирда фаол эмас. Илтимос, саҳифани янгиланг",
      LiveVideoServerError:"Жонли видео узатишда сервер билан уланишда хатолик"
      },
      Boards: {
        title: "Мурожаатлар",
        Filter: {
          Search: "Қидирув",
          IgnoreResolved: "Бажарилмаган вазифалар",
          OnlyMyIssue: "Менинг вазифаларим",
          ResetAll: "Тозалаш",
          User: "Фойдаланувчи",
        },
        TableHeader: {
          Backlog: "Органилмоқда",
          Selected: "Жараёнда",
          InProgress: "Текширилмоқда",
          Done: "Бажарилди",
        },
      },
      TicketTypes: {
        Title: "Мурожаат турлари",
        TableHeader: {
          Name: "Номи",
          NameUz: "Номи_Уз",
          NameRu: "Номи_Ру",
          NameKk: "Номи_Кк",
          NameCrl: "Номи_Cрл",
          Color: "Ранги",
          Status: "Ҳолати",
          Icon: "Белги",
        },
      },
      TicketStatus: {
        Title: "Ҳолат турлари",
        TableHeader: {
          Name: "Номи",
          Color: "Ранги",
          Order: "Тартиб",
          Status: "Ҳолати",
        },
      },
      TicketPriorities: {
        Title: "Даража турлари",
        TableHeader: {
          Name: "Номи",
          Color: "Ранги",
          Status: "Ҳолати",
        },
      },
      Activities: {
        Title: "Фаолият турлари",
        TableHeader: {
          Name: "Номи",
          Description: "Тавсиф",
          CreatedAt: "Яратилган вақти",
        },
      },
      Reports: {
        Excel: "Excel",
        TimeBy: {
          Title: "Қўнғироқ давомийлиги бўйича ҳисобатлар",
          TableHeader: {
            Time: "Вақт",
            AllCall: "Барча қўнгироқлар",
            DoneCall: "Ҳал қилинган қўнгироқлар(сони)",
            DonePercentage: "Ҳал қилинган қўнгироқлар(фоиз)",
          },
        },
        RegionBy: {
          Title: "Вилоятлар бўйича ҳисобатлар",
        },
        OperatorBy: {
          Title: "Оператор иши ҳақида ҳисобот",
        },
      Bot:{
        Title:"Ботдан олинган ҳисоботлар",
        TableHeader: {
          Name:   "Истеъмолчининг тўлиқ исми",
          Account_Type: "Истеъмолчининг тури",
          Account_Number:"Аккаунт рақами",
          Coato:"COATO",
          Count: "Сўровлар сони",
          Last_Request:"Сўнги сўров вақти"
        },
       }
      },
      SipNumber:{
        Title:"СИП рақамлар",
        TableHeader: {
          Phone:   "Рақам",
          Parol: "Парол",
          Status: "Ҳолати",
          CreatedAt: "Яратилган вақти",
        },
      },
      Operators: {
        BindWorkBreakTimeToOperators: 'Иш ва танаффус вақтларини бириктириш'
      }
    },

    Errors: {
      MaxLengthError: "Maksimal uzunlik: {{ value }} | Hozir: {{ controlValueLength }}",
      Required: "Мажбурий майдон",
      ErrorPastDate: "Хатолик, ўткан сана",
      ErrorElapsedTime: "ўткан соат",
      ErrChooseFuture: "Келажакни танланг",
      ErrorOfflineText: "Интернетга уланинг",
      ErrorOfflineTitle: "Сиз офлайнсиз. Алоқани текширинг",
    },

    ERROR_LIST: {
      POPUP_RESTRICTED: "Сизнинг браузерингизда чоп етиш саҳифасини очишга рухсат берилмаган!",
      SERVER_ERROR: "Серверда хатолик юзага келди!",
      ACCESS_DENIED: "Кириш чекланган!",
      BAD_REQUEST: "Нотўғри сўров!",
      FIELD_IS_REQUIRED: "Мажбурий майдон",
      INVALID_EMAIL_ADDRESS: "Нотўғри электрон почта манзили!",
      MINLENGTH_REQUIRED: "Майдон камида {{requiredLength}} белгидан иборат бўлиши керак!",
      MAXLENGTH_REQUIRED: "Майдон камида {{requiredLength}} белгидан кўп бўлиши керак!",
      MIN_REQUIRED: "Узунлик  {{min}} дан кўп бўлиши керак!",
      MAX_REQUIRED: "Узунлик  {{max}} дан кам бўлиши керак!",
      PATTERN_REQUIRED: "Майдон қуйидаги белгилар орасида бўлиши керак {{requiredPattern}}",
      BETWEEN: "Қийматлар {{x}} ва {{y}} орасида бўлиши керак!",
      INVALID_LENGTH: "INN {{minLength}} ва {{maxLength}} орасида бўлиши керак!",
      IP_NOT_VALID: "Нотўғри IP-манзил киритилди!",
      CONFIRM_PASSWORD_ERROR: "Паролни тасдиқлашда хатолик бўлди!",
      NUMERIC: "Бу майдонда фақат рақамлар ёзилади!",
      LOWERCASE: "Бу майдонда фақат кичик ҳарфлар ёзилади!",
      UPPERCASE: "Бу майдонда фақат катта ҳарфлар ёзилади!",
      MAX_FILE_SIZE: "Файл ҳажми {{size}} дан ошмаслиги керак",
      TWO_Date_Error: "Сиз 2 та сана танлашингиз керак!",
      DATE_EMPYTE: "Майдон бўш бўлмаслиги керак!",
      ERROR_RESPONSE: "Нотўғри маълумот қайтарилди!",
      ERROR_ACCESS: "Логин ёки парол нотўғри",
      NTF_CANNOT_EDIT: "Бу турдаги билдиришномаларни таҳрирлаб бўлмайди",
      COATO_REQUIRED: 'Вилоят ёки Туманни танлаш мажбурий!',
      COATO_REQUIRED_TEXT: 'Илтимос туман ёки шаҳар танланг!'
    },

    TOAST: {
      TITLE: {
        SUCCESS: "Муваффақиятли",
        WARNING: "Огоҳлантириш",
        CANCELLED: "Бекор қилинган",
        ERROR: "Хатолик",
        INFO: "Маълумот",
        DOWNLOAD: "Юклаб олиш",
        EXPORT_EXCEL: "Excel файл муваффақиятли юклаб олинди!",
        IMPORT_EXCEL: "Excel файл муваффақиятли импорт қилинди!",
        RESIDENT_ID: "Жадвал қаторида абонент ID мавжуд эмас",
      },
      TOAST_DELETE: "Танланган жадвал қаторлари муваффақиятли ўчирилди!",
      TOAST_SELECT_ROW: "Илтимос, қаторни тангланг!",
      TOAST_SUCCESS: "Маълумот муваффақиятли қўшилди!",
      TOAST_UPDATE: "Маълумот муваффақиятли таҳрирланди!",
      TOAST_STATUS_UPDATE: "Мурожаат ҳолати муваффақиятли ўзгартирилди",
      TOAST_ADD_COATO: "Мурожаат қўшиш учун CОАТО танланг",
      TOAST_MULTIPLE_SELECT_ROW: "Илтимос, қаторларни танланг!",
      TOAST_WARNING: "Агар файл структураси қандай кўринишда бўлишини билмасангиз намуна учун файлни юклаб олишингиз мумкин",
      TOAST_WARNING_RESIDENT: "Бу абонент корхона ходими эмас!",
      TOAST_ERROR_NOT_EMPLOYEE: "Бу абонент корхона ходими эмас!",
      TOAST_WARNING_RESIDENT_SEARCH: "Абонент топилмади",
      SUCCES_ACCESS:"Тизимга муваффақиятли кирилди!",
      SUCCESS_NO_ACCESS: "Сиз тизимдан фойдаланиш ҳуқуқига эга эмассиз!",
      ThisEmployeeIDDoesNotExist: "Бу ходим ИДси мавжуд эмас, Aутентификация журналини кўриш учун ходим ИДси керак!",
      DoubleIncident:"Иккитадан ортиқ белгилаб билмайсиз!",
      MinCalendar:"Иш вақтини бириктириш учун камида битта кун танланг",
      CameraNotAllowed:"Камерага киришга рухсат берилмаган. Камерангизни сoзланг!"
    },

    MANAGEMENT: {
      TABLE_HEADERS: {
        NUMBERS: "№",
        ID: "ID",
        NAME: "Номи",
        COMPANY: "Компаниялар",
        DATE1: "03.2023",
        DATE2: "04.2023",
        DATE3: "05.2023",
        DATE4: "06.2023",
        DATE5: "07.2023",
        DATE6: "08.2023",
        DATE7: "09.2023",
        ACTION: "Ҳаракат",
        ACTION_LINK: "Ўтиш",
      },
      USER_ROLE: {
        TABLE_HEADERS: {
          ID: "ID",
          PREFIX: "Рол префикси",
          NAME: "Рол номи",
          LEVEL: "Даражаси",
          TYPE: "Типи",
          COMMENT: "Изоҳ",
          COMMENT_UZ: "Изоҳ (UZ)",
          COMMENT_CRL: "Изоҳ (CRYL)",
          COMMENT_RU: "Изоҳ (RU)",
        },
        FILTER: {
          FILTER_BUTTON: "Мавжуд ҳаракатлар",
          TITLE: "Рухсат етилган ҳаракатлар",
        },
        INSERT_MODAL: "Янги рол қўшиш",
        UPDATE_MODAL: "Ролни таҳрирлаш",
        DELETE_MODAL: {
          TITLE: "Ролни ўчириш",
          TEXT: "Танланган ролни тизимдан олиб ташламоқчимисиз?",
        },
        TOAST_EXISTS_ROLENAME: "Бу \"Рол номи\" тизимда мавжуд.\nИлтимос бошқа ном киритинг!",
      },
      USERS: {
        ROLE: "Рол",
        ADD_MODAL_TITLE: "Фойдаланувчи қўшиш",
        EDIT_MODAL_TITLE: "Фойдаланувчи маълумотларини таҳрирлаш",
        EDIT_USER: "Маълумотларини таҳрирлаш",
        UPDATE_STATUS_MODAL_TITLE: "Фойдаланувчи статуси",
        UPDATE_PASS_MODAL_TITLE: "Фойдаланувчи паролини ўзгартириш",
        UPDATE_STATUS: "Статусни ўзгартириш",
        UPDATE_PASS: "Паролини ўзгартириш",
        NEW_PASSWORD: "Янги парол",
        NEW_PASSWORD2: "Паролни қайта киритинг",
        ACTIVE: "Фаол",
        IN_ACTIVE: "Фаол эмас",
        TOAST_EXISTS_LOGIN: "Бу \"Логин\" номи тизимда мавжуд.\nИлтимос бошқа ном киритинг!",
        RESPUBLIC: "Республика",
      },
      COMPANY_OPERATIONS: {
        TABLE_HEADERS: {
          ID: "ID",
          NAME: "Номи",
          T_PERIOD: "Техник. давр",
          ADDRESS: "Манзил",
          PHONE: "Телефон",
          TASK: "Вазифалар",
          START_DATE: "Бошланиш санаси",
          END_DATE: "Тугаш санаси",
          STATUS: "Ҳолат",
          RESULT: "Натижа",
          KOD: "Код",
          ACTION: "Ҳаракат",
        },
        SELECT: {
          REGION: "Вилоятни танланг",
          DISTRICT: "Туманни танланг",
          YEAR: "Йил",
          MONTH: "Ой",
        },
        MODAL: {
          TITLE: "Жорий ойни қайта ҳисоблаш",
          TEXT: "Сиз ҳақиқатан ҳам бир вақтнинг ўзида қайта ҳисоблашни ва кейин ушбу компанияни янги даврга ўтказишни хоҳлайсизми?",
          SAVE: "Ҳа бажаринг",
        },
      },
    },

    ChatService: {
      Search: "Қидириш...",
      Chats: "Чатлар",
      Select_chat: "Чатни танланг",
      About: "Мен ҳақимда",
      Personal_information: "Шахсий маълумотлар",
      View_contact: "Контактни кўриш",
      Mute_notifications: "Сукут қилиш",
      Block_contact: "Контактни блоклаш",
      Clear_chat: "Тарихни тозалаш",
      Write_message: "Хабарингизни ёзинг",
      Send: "Юбориш",
    },

    ShortRegions: {
      A03000: "Анд...",
      A06000: "Бух...",
      A08000: "Джи...",
      A10000: "Каш...",
      A12000: "Нав...",
      A14000: "Нам...",
      A18000: "Сам...",
      A22000: "Сур...",
      A24000: "Сыр...",
      A30000: "Фер...",
      A33000: "Хор...",
      A27000: "Таш в...",
      A26000: "Таш ш...",
      A35000: "Қора Рес...",
      A00002: "Хор Ург...",
    },

    ShortRegionsCode: {
      A03000: "Aнд...",
      A06000: "Бух...",
      A08000: "Жиз...",
      A10000: "Қаш...",
      A12000: "Нав...",
      A14000: "Нам...",
      A18000: "Сам...",
      A22000: "Сур...",
      A24000: "Сир...",
      A30000: "Фар...",
      A33000: "Хор...",
      A27000: "Тош ви...",
      A26000: "Тош ш...",
      A35000: "Қора Рес...",
      A00002: "Хор Ург...",
    },

    FullRegions: {
      Andijan: "Aндижон",
      Bukhara: "Бухоро",
      Jizzakh: "Жиззах",
      Kashkadarya: "Қашқадарё",
      Navoi: "Навоий",
      Namangan: "Наманган",
      Samarkand: "Самарқанд",
      Surkhandarya: "Сурхандарё",
      SyrDarya: "Сирдарё",
      Ferghana: "Фарғона",
      Khorezm: "Хоразм",
      TashkentRegion: "Тошкент вилоят",
      TashkentCity: "Тошкент шаҳар",
      KKR: "ККР",
    },

    Profil: {
      Name: "Профиль",
      Request: "Сўровлар",
      Complaint: "Шикоятлар",
      CallTime: "Умумий суҳбат вақти",
      CallName: "Қўнғироқлар сони",
      Offer: "Таклифлар",
      Service: "Хизмат кўрсатиш",
      Chat_title: "Кунлик қабул қилинган мурожаатлар сони",
      days30Statistics: "Сўнги 30 кунлик қўнғироқлар статистикаси",
      days30StatisticsTime: "30 кунлик жами суҳбат вақти",
      ImgError: "Расм ҳажми 2 мб дан кичкина бўлиши керак",
      EditProfileMessage: "Маълумотлар ўзгартирилди!",
      FirstAndSecondName: "Исм Фамилия",
      respublika: "Республика",
      region: "Регион",
      tuman: "Туман",
      kompaniya: "Компания",
      inspektor: "Инспектор",
      operator: "Оператор",
      resident: "Ресидент",
      Months: {
        January: "Январь",
        February: "Февраль",
        March: "Март",
        April: "Апрель",
        May: "Май",
        June: "Июнь",
        July: "Июл",
        August: "Август",
        September: "Сентабр",
        October: "Октябр",
        November: "Ноябрь",
        December: "Декабрь",
      },
    },

    Selects: {
      Regions: "Вилоятлар",
      Region: "Вилоят",
      FromRegion: "Вилоятдан",
      ToRegion: "Вилоятга",
      PES: "ПЕС рўйхати",
      RES: "РЕС рўйхати",
    },

    NOTIFICATION_TYPE: {
      MAINTENANCE: "Узилишлар ҳақида хабар",
      20: "Бошқа турдаги хабарлар",
    },

    Notification: {
      Notifications: "Билдиришномалар",
      NoNotifications: "Ўқилмаган билдиришномалар йўқ",
      AllNotifications: "Барча билдиришномалар",
      AddNotification: "Билдиришнома юбориш",
      New: "Янги",
      StartDate: "Бошланиш санаси",
      EndDate: "Тугаш санаси",
      StartTime: "Бошланиш вақти",
      EndTime: "Тугаш вақти",
      MessageDescription: "Хабар мазмуни",
      SelectAll: "Ҳаммасини танлаш",
      SelectElementName: "та вилоят танланди",
      SelectUserName: "та фойдаланувчи танланди",
      MessageFull: "Батафсил хабар тавсифи",
      MessageSend: "Юбориш",
      SendName: "Билдиришнома юбориш",
      NotFound: "Топилмади",
      MessageType: "Хабар тури",
      SelectSendUser: "Хабар юбориладиган фойдаланувчи",
      MessageDescriptionUZ: "Хабар мазмуни(УЗ)",
      MessageDescriptionRU: "Хабар мазмуни(РУ)",
      MessageFullUZ: "Батафсил хабар тавсифи(УЗ)",
      MessageFullRU: "Батафсил хабар тавсифи(РУ)",
      Consumer: "Истеъмолчи",
      AccordingtoCOATO: "CОАТО бўйича узилишлар хабари юбориш",
      SendMsgToCustomer: "Бир истеъмолчига хабар юбориш",
      TYPE_MAINTENANCE: "Узилишлар ҳақида хабар",
      TYPE_NOTIFICATION_FROM_CRM: "Бошқа турдаги хабарлар",
      LoadingProgress: "Юборилмоқда...",
      Responsed: "Қуйидагича хабар юборилди",
      Error: "Хатолик",
      SelectDistrictCOATO: "Туман CОАТОни танланг!",
      Sent: "Юборилди",
      Edited: "Таҳрирланди",
      FileUploadText: "Файлдаги маълумотлар белгиланган структура бўйича текшириб чиқилади, " +
        "\n натижаси билдиришнома сифатида қайтарилади",
    },

    CallCenter: {
      last3day: "Охирги 3 кунлик",
      last7day: "Охирги 1 ҳафталик",
      last15day: "Охирги 15 кунлик",
      last1month: "Охирги 1 ойлик",
      last3months: "Охирги 3 ойлик",

      SelectPeriod: "Даврни танланг",
      TotalNumberOfCalls: "Қўнғироқларнинг умумий сони",
      AllowedAppeals: "Мурожаатлар",
      Completed: "Бажарилди",
      InProgress: "Жараёнда",
      AverageTalkTime: "Ўртача суҳбат вақти",
      AllTalkTime: "Умумий суҳбат вақти",
      AverageWaitingTime: "Ўртача кутиш вақти",
      CaseStatusStatistics: "Ўртача кутиш вақти",
      ListLastCalls: "Охирги қўнғироқлар рўйхати",
      Consumer: "Истеьмолчи",
      Phone: "Телефон рақами",
      WaitingTime: "Кутиш вақти",
      Status: "Ҳолат",
      Time: "Вақт",
      CalculationStatus: "Ҳисоб ҳолати",
      Accepted: "Қабул қилинган",
      Skipped: "Ўтказиб юборилган",
      SelectRegionLabel: "та вилоят танланди",
      DuringConversation: "Суҳбат вақти",
      ActiveCall:"Фаол қўнгироқлар",
      ActiveOperators:"Фаол Операторлар",
      Online:"oнлайн",
      Offline:"oфлайн",
      QueueCall:"Навбатдаги қўнғироқлар",
      CallCount:"Жами қўнғироқлар",
      NotForwardCallCount:"Йўналтирилмаган қўнғироқлар",
      ForwardCallCount:"Йўналтирилган қўнғироқлар"
    
    },

    ROLES: {
      RepublicAdmin: "Республика админ",
      RegionAdmin: "Регион админ",
      DistrictAdmin: "Туман админ",
      CompanyAdmin: "Компания админ",
      Inspector: "Инспектор",
      Operator: "Оператор",
      Resident: "Ресидент",
      NameofROL: "Рол",
      DEPARTMENT_HEAD: "Бўлим бошлиқи",
      ADMIN: "Aдмин",
      OPERATOR: "Оператор",
      AGENT: "Aгент",
      PERFORMER: "Бажарувчи"
    },

    LEVELS: {
      Republic: "Республика",
      Region: "Регион",
      District: "Туман",
      Company: "Компания",
      Inspector: "Инспектор",
      Operator: "Оператор",
      Resident: "Ресидент",
    },

    PageTitle: {
      Home: "Бош саҳифа",
    },

    ChangeMenu: {
      Mega_Menu: "Катта юқори меню",
      Dropdown_Menu: "Кичик юқори меню",
    },

    Settings: {
      Name: "Созламалар",
    },

    Date: {
      From: "Дан",
      To: "Гача",
      StartWork: "Ишнинг бошланиши",
      EndWork: "Ишнинг охири",
      Empty: "Ҳеч нима топилмади",
      NotTypes: "Мурожаат турлари мавжуд эмас",
    },

    Button: {
      Search: "Қидириш",
      Clear: "Тозалаш",
      Accept: "Қабул қилиш",
      Reject: "Рад этиш",
      Disable: "Очириш",
      Export: 'Экспорт',
      RegionOrDistrict: 'Вилоят / Туман',
      ExportExcel: 'Excel га экспорт қилиш'
    },

    AccountType: {
      ELECTRICITY: "Електр",
      REACTIVE: "Реактив",
      BUST: "Прибор",
      FINE: "Пеня",
      COLLECTION: "Камчилик",
      SERVICES: "Хизматлар",
      ECO_ENERGY: "Екологик енергия (-A)",
      TRANSPORTATION: "Хизмат (транспорт учун)",
    },

    AvailabilityofReservation: 'Броннинг мавжудлиги',
    TotalCost: 'Жами харажат (сум)',
    AnnualVolumeUnderAgreement: 'Шартнома бўйича йиллик ҳажм (минг кВт/соат)',
    ReportSubmission: 'Ҳисобот топшириш куни',
    PrepaymentPercentage: 'Олдиндан тўлов фоизи',
    SDATOLegalLocation: 'СОАТО Юридик манзили',
    ParentOrganization: 'Юқори ташкилот',
    SDATOLocation: 'СОАТО Манзил',
    Fine: 'Пеня%',
    PowerandAmount: 'Қувват ва энергия миқдори',
    MainTariff: 'Асосий тариф гуруҳи',
    AdditionalCode1: 'Қўшимча код1',
    AdditionalCode2: 'Қўшимча код3',
    AdditionalCode3: 'Қўшимча код3',
    InstalledPower: 'Ўрнатилган қувват',
    Substation: 'Субстанция',
    TransformerName: 'Трансформатор номи',
    TP_Feeder: 'ТП + Озиқлантирувчи',
    FullNameForeman: 'Ф.И.О уста',
    FullNameController: 'Ф.И.О назоратчи',
    CodeBook: 'Кодлар китоби',
    BooksPage: 'Китоб саҳифаси',
    ConnectionTo: 'Уланиш АСКУE га',
    SubtractedFrom: 'Ажратилди',
    Consumer: 'истеъмолчи',
    Subconsumer: 'Субистеъмолчи',
    FromtheTariff: 'Тарифдан',
    FullView: "Тўлиқ кўриш",
    Login: "Кириш",
    Password: "Парол",
    Logout: "Чиқиш",
    LoginSystem: "Тизимга кириш",
    View: 'Кўриш',
    Bind: 'Бириктириш',
    BindWorkTime: 'Иш вақтини бириктириш',
    BindBreakTime: 'Танаффус вақтини бириктириш',
    AddBindWorkTime: 'Иш вақтини қўшиш',
    AddBindBreakTime: 'Танаффус вақтини қўшиш',
    Edit: 'Ўзгартириш',
    Delete: 'Ўчириш',
    Spy: 'Эшитиш',
    Page: 'Бет',
    LEGAL_ENTITY_DATA: "Хизмат (транспорт учун)",
    BILLING_MONTH_RECALCULATION_NOT_INCLUDED_DEBITS: "Дебетга киритилмаган ҳисоб-китоб ойининг қайта ҳисоб-китоблари",
    RETURN_PREPAYMENT: "Олдиндан тўловни қайтариш",
    RECALCULATE_OTHER_REASON: "Бошқа сабабларга кўра қайта ҳисоб-китоблар",
    ABOVE_THE_TARIFF_FOR_EXCEEDING_THE_CONTRACTUAL_VALUE: "Шартнома қийматидан ошиб кетганлик учун тарифдан юқори",
    FOR_KWTH: "кВт/соат учун",
    SUM: "Сумма",
    INCOMING_BALANCE: "Кирувчи баланс",
    ACCRUED: "Ҳисобланган",
    ACT_RECALCULATION: "ш.ж Қайта ҳисоблаш акти",
    PAYMENT: "Тўлов",
    BALANCE_OUT: "Чиқувчи қолдиқ",
    V_T_CH_FOUND_PAYMENT: "ш.ж Тўлов топилди",
    DECOMMISSIONED: "Ёзилган",
    CLASSIFIED_HOPELESS: "Баланс",
    RESTORED_ACCOUNTS_RECEIVABLE: "Кейинги тўлов ойлар",
    OUTGOING_BALANCE: "Чиқувчи баланс",
    CURRENT_PAYMENT: "Жорий тўлов",
    NEXT_MONTH_PAYMENT: "Кейинги тўлов ойлар",
    BALANCE: "Баланс",
    TOTAL: "Жами",
    V_T_CH_ACT_RECALCULATION: "ш.ж Қайта ҳисоблаш акти",
    BALANCE_SALDO: "Баланс",
    CONSUMER_CODE: "Истеъмолчи коди",
    GOVERNMENT_ORGANIZATION: "СООГУ",
    CONSUMER_NAME: "Истеъмолчининг тўлиқ исми",
    CONTRACT_VALUE: "Шартнома қиймати (кВт/соат)",
    OVERDUE_AMOUNT: "Кечиктирилган миқдор",
    AFFILIATION: "Мансублик",
    PREPAYMENT_PERCENTAGE: "Олдиндан тўлов фоизи",
    REPORT_DATE: "Ҳисобот санаси",
    TARIFF_GROUP: "Тарифлар гуруҳи",
    CONTRACT_DATE: "Шартнома санаси",
    INSTALLED_CAPACITY: "Ўрнатилган қувват",
    ADDRESS: "Манзил",
    PAYMENT_CODE: "Тўлов коди",
    REPORT_NUMBER: "Ҳисобот рақами.",
    RESIDENT: "Резидент",
    ConsumerCard: "Истеъмолчи картаси",
    PERSONAL_CONSUMER_CARD: "Истеъмол картаси",
    PERSONAL_ACCOUNT: "Шахсий ҳисоб рақами",
    DISTRICT_LOCATION: "Манзил",
    Neighborhood: "Маҳалла",
    NOTES: "Еслатма",
    METER_NUMBER: '№ Ҳисоблагич',
    SHUTDOWN_DATE: 'Ўчириш санаси',
    INDICATION_INSTALLING_METER: 'Ҳисоблагични ўрнатишда кўрсатма',
    DATE_INSTALLATION_METER: 'Ҳисоблагични ўрнатиш санаси',
    COUNTER_STATUS: 'Ҳисоблагич ҳолати',
    METER_TYPE: 'Ҳисоблагич тури',
    CAPACITY: 'Ҳажм',
    TRANSFORMATION_KOEFF_KT: 'Транс нисбат',
    ASKUE: 'АСКУE',
    SOLAR_PANEL: 'Қуёш панели',
    TARIFF_SUM: 'Тариф (сум)',
    SUMNAME: 'сум',
    LAST_BYPASS: 'Охирги тур',
    LAST_BYPASS_DATE: 'Охирги ташриф санаси',
    SEAL: 'Сертификат',
    SEAL_NUMBER: 'Сертификат №',
    DATE: 'Сана',
    DEADLINE_TO: 'Муддат',
    ACT_INVERTORY: 'Қайта ҳисоблаш акти (инвентаризация)',
    READING: 'Кўрсатма',
    PENALTY_AMOUNT: 'Пения миқдори',
    AMOUNT_PER_EP_E: 'ЭП/э учун миқдор',
    ACT_AMOUNT: 'Актга мувофиқ миқдор',
    TOTAL_AMOUNT: 'Умумий қиймат',
    RECOIL_INDICATION_MINUS_A: 'Орқага ўқиш (-А)',
    INCOMING_BALANCE_KWTH: 'Кириш баланси кВт/соат',
    INCOMING_BALANCE_SUM: 'Балансни очиш суммаси',
    CALCULATION: 'Ҳисоблаш',
    CALCULATION_TYPE: 'Ҳисоблаш тури',
    CONTRACT_VALUE_KWH: 'Ит. ЛEД (кВт/соат)',
    AVERAGE_DAILY_CONSUMPTION_KWH: 'Ўрт. суткалик сарф(кВт/соат)',
    AVERAGE_MONTHLY_CONSUMPTION_KWH: 'Ўрт. ойлик сарф (кВт)',
    ESTIMATED_READING_FOR_LAST_MONTH: 'Ўтган ой учун Р.ҳ п.к',
    ACCRUED_DAY: 'Ҳисобланган (кун)',
    ACCRUED_SUM: 'Ҳисобланган (сўм)',
    ACCRUED_KWH: 'Ҳисобланган (кВт/соат)',
    RECALCULATION_DAYS: 'Қайта ҳисоблаш (кун)',
    RECALCULATION_KWH: 'Қайта ҳисоблаш (кВт.с)',
    RECALCULATION_BY_AVERAGE_DAILY: 'Ўрт. кунлик қайта ҳисоблаш.',
    PENALTY_CHARGED_SUM: 'Ҳисобланган жарима (сўм)',
    PENALTY_RECALCULATION: 'Жарималарни қайта ҳисоблаш',
    ESTIMATED_INDICATION_CURRENT_MONTH: 'Жорий ойнинг Р.ҳ',
    RECALCULATION: 'Қайта ҳисоблаш',
    WRITE_OFF: 'Ҳисобдан ўчириш',
    PAYMENT_CURRENT: "Жорий тўлов",
    PAYMENT_RECALCULATION: "Тўловни қайта ҳисоблаш",
    ELECTRICITY: 'Электр',
    ACT_VIOLATED: 'Бузилиш акти',
    UNLEARNED_CONSUMPTION: 'Ҳисобсиз исте\'молчи',
    FINE: 'Жарима',
    POV_CONNECTIONS: 'Пов. уланишлар',
    SelectRegion: 'Ҳудудни танланг',
    RECALCULATION_DATE: 'Қайта ҳисоблаш санаси',
    BYPASS_INDICATION_OF_RECALCULATION: 'Қайта ҳисоблаш вақтида айланиб ўтиш кўрсаткичи',
    BYPASS_INDICATION_OF_RECALCULATION_A_MINUS: 'Қайта ҳисоблаш вақтида айланиб ўтиш кўрсаткичи (-А)',
    ALL_AMOUNT: 'Умумий ҳисоб',
    UNLEARNED_CONSUMPTION_LONG: 'Ҳисобга олинмаган исте\'мол',
    ACT_RECONNECTION: 'Қайта уланиш',
    ResDataInvalid: 'Сервердан юборилган ма\'лумотларда хатолик!',
    LAST_PAYMENT_SUM: 'Охирги тўлов (сўм)',
    LAST_PAYMENT_DATE: 'Охирги тўлов санаси',
    PREVIOUS_BYPASS: 'Олдинги кўрсатма',
    PREVIOUS_DATE_BYPASS: 'Олдинги ташриф санаси',
    LOSS_AMOUNT: 'Ёқотиш миқдори',
    ONES_WITHOUT: 'Рўйхатга олинмагансиз',
    VOSSTA_DEB: 'Муваффақиятсиз (ДEБ)пакет',
    BUILDING_FLAT_NUMBER: 'Хонадон/ уй',
    METER: 'Ҳисоблагич',
    INSTALLATION: 'Ўрнатиш',
    NUMBER_ICON: '№',
    FROM: 'Дан',
    BEFORE: 'Гача',
    INVENTORY: 'Инвентаризация',
    PERIOD: 'Давр',
    BALANCE_IN: "Кирувчи қолдиқ",
    START_DATE: "Боши",
    TERMINATION: "Охири",
    CALCULATION_CATEGORY: "Ҳисоблаш тури",
    COUNT_DAY: "Кунлар сони",
    KVT_A_PLUS: "кВтС(А+)",
    KVT_A_MINUS: "кВтС(А-)",
    DIFFERENCE: "Фарқ",
    READING_A_PLUS: "Кўрсатма А+",
    READING_A_MINUS: "Кўрсатма А-",
    CURRENT_PERIOD_DATE: "Ҳисоб-китоб даври",
    AVERAGE_DAY_EXP: "Ўртача кунлик истеъмол",
    AVERAGE_DAY: "Ўрт.кун",
    SETTLEMENT_DATE: 'Ҳисоблаш санаси',
    READING_CALCULATION: 'Ҳисоб кўрсатгичи',
    KWT: 'кВт',
    LOSS: 'Ё\'қотиш',
    EDUCATION_PRICE: 'Нархлаш',
    VIOLATION: 'Бузилиш',
    OTHER_CALCULATION: 'Бошқа ҳисоб-китоблар',
    BYPASS_HISTORY: 'Ўтиш тарихи',
    PAYMENT_HISTORY: 'Тўлов тарихи',
    BANK_DOCUMENT_TYPE: 'Банк ҳужжати тури',
    DOCUMENT_NUMBER: 'Ҳужжат рақами',
    ENTER_DATE: 'Киритилган сана',
    DOCUMENT_DATE: 'Ҳужжат санаси',
    PAYMENT_AMOUNT: 'Тўлов миқдори',
    PAYMENT_PURPOSES: 'Тўловнома тайинлаш',
    CREATED_BY: 'Томонидан яратилган',
    UPDATED_BY: 'Томонидан ўзгартирилган',
    PREVIOUS_BYPASS_READING_AP: 'Олдинги четлаб ўтиш (А+)',
    PREVIOUS_BYPASS_READING_AM: 'Олдинги четлаб ўтиш(А-)',
    SN_METER: 'СН ҳисоблагич',
    CONSUMPTION_PLUS: 'Исте\'мол(+А)',
    RECOIL_MINUS: 'Орқага қайтиш(-А)',
    BYPASS_DATE: 'Ўтиш санаси',
    SOURCE: 'Манба',
    AGENT: 'Агент',
    UPDATED_AT: 'Ўзгартириш санаси',
    LOGIN: 'Кириш',
    STATUS: 'Ҳолат',
    PAYMENT_DATE: 'Тўлов санаси',
    TARIFF: 'Тариф',
    PAYMENT_TYPE: 'Тўлов тури',
    CALCULATION_PERIOD: 'Ҳисоблаш даври',
    SENDER: 'Юборувчи',
    EXTRA_OPTIONS: 'Қўш. параметрлари',
    SUMM: 'Сумма',
    TOTALL: 'Жами',
    NEGOTIABLE_CARD: "Тескари карта",
    SHOWCASE: "Витрина",
    DEBT_PAYMENT_BEFOREHAND_SHORT: 'Ортиқча иш (-олдинги)',
    IncomingCallRestriction: "Кирувчи қўнғироқларга рухсат бериш",
    OutgoingCallRestriction: "Чиқувчи қўнғироқларга руҳсат бериш",
    OutgoingCall: "Чиқувчи қўнғироқ",
    PleaseWait: "Кутинг",
    FilesUploadedToGeoMap: "Гео харитага юкланган файллар",
    DownloadCurrentStatus: "Жорий ҳолатни юклаб олиш",
    UploadNewFile: "Янги файл юклаш",
    UploadedByWho: "Ким томонидан юкланган",
    FileName: "Файл номи",
    RejectedFile: "Рад етилган",
    UploadTime: "Юкланган вақти",
    Explanation: "Изоҳ",
    Downloaded: "Юкланди",
    FileApproved: "Файл тасдиқланди",
    FileDisapproved: "Файл тасдиқланмади",
    Upload: "Юклаш",
    Uploading: "Юкланмоқда",
    SelectCSVFile: "CСВ файлни танланг",
    CSVFileUploadNote: "Фақат CСВ форматдаги файл юклаш мумкин. Файл ўлчами 1МБ дан ошмаслиги керак.",
    CommentOptional: "Изоҳ (Ихтиёрий)",
    ErrorCSVSelect: "Хато: CСВ файлни танланг!",
    ErrorSizeLimit: "Хато: файл ҳажми 1МБ чегарадан ошиб кетди!",
    CallsForwardCount: "Бошқа рақамга йўналтирилган қўнғироқлар сони",
    IVRHistoryCount: "IVR-га кириб маълумот олганлар сони",
    MissedCallsCount: "Ўтказиб юборилган қўнғироқлар сони",
    AuthenticationLog: "Aутентификация журнали",
    Browser: 'Браузер',
    IP_adress: "IP-манзил",
    Location: "Жойлашув(Location)",
    LoginAttemptTime: "Киришга уриниш вақти",
    RolePermissions: "Рол руҳсатлари",
    ThisRolIdNotDef: "Ушбу рол IDси топилмади!",
    Permissions: "Руҳсатлар",
    PerToRole: "ролига тегишли руҳсатлар",
    Module: "Модул",
    UpdateAt: "Янгиланган вақти",
    Confirm: "Тасдиқлаш",
    ToBack: "Орқага",
    confirmWarning: "Ўзгаришларни тасдиқлайсизми?",
    OperatorOnline: "Оператор фаол",
    OperatorOffline: "Оператор фаол эмас",
    NotFoundCOATO: "CОАТО топилмади!",
    NotBeenDefinedCOATO: "Фойдаланувчи кириш ҳуқуқига эга COATOлар рўйхати топилмади",
    CommunicationHasDeteriorated: "Алоқа сифати ёмонлашди!",
    NoSound: "Овозсиз",
    ActivityStatus: "Фаоллик ҳолати (тизимга кириш)",
    ConnectionStatus: "Оператор ҳолати (Онлайн ёки Офлайн)",
    Online: "Онлайн",
    Offline: "Офлайн",
    OffNotification: "Билдиришномани ўчириш",
    OnNotification: "Билдиришномани ёқиш",
    NameOrEmailSearch: "Исм ёки E-mail орқали қидириш",
    NameOrCoatoSearch: "CAОТО ёки Исм орқали қидириш",
    OnSound: "Овозни ёқиш",
    CallForwarding: "Қўнғироқни йўналтириш",
    WaitingClientsWindow: "Кутаётган мижозлар ойнаси",
    CloseWindow: "Ойнани ёпиш",
    OpenWindow: "Ойнани очиш",
    CustomerChat: "Мижоз суҳбати",
    EndCall: "Қўнғироқни тугатиш",
    SureEndCall: "Ростан ҳам бу қўнғироқни тугатишни истайсизми?",
    Termination: "Тугатиш",
    CallEnd: "Қўнғироқ тугатилди",
    ContactOperator: "Оператор билан онлайн суҳбат",
    REN: "“ҲУДУДИЙ ЭЛЕКТР ТАРМОҚЛАРИ” АЖ",
    KVT_A: "кВт/соат",
    OldSumm: "Аввалги бо`йича жами",
    NewSumm: "Янгиси бо`йича жами",
    PaymentDate: "То`лов куни",
    DateFrom: "Дан",
    DateTo: "Гача",
    Days: "Кунлар",
    RECALCULATION_NOT_FOUND: "Ушбу истеъмолчи учун жаримани қайта ҳисоблаш топилмади",
    OPERATOR_STATISTICS: "Операторлар статистикасини кўриш",
    AddPermitCoato: "Қўшимча бириктирилган ҳудудлар",
    CoatoNotFoundToastTitle: "Топилмади ёки кириш ҳуқуқингиз бўлмаган CОАТОни киритдингиз",
    UuidOrStartNotFound: "УУИД ёки Старт параметри мавжуд эмас",
    RecordSuccessfullyDeleted: "Ёзув муваффақиятли ўчирилди",
    FailedToDeleteRecord: "Ёзувни ўчириб бўлмади",
    DurationTime: "Давомийлиги",
    ViewLink: "Кўриш",
    SentTime: "Юборилган вақти",
    NotificationMonitoring: "Билдиришномалар мониторинги",
    NTFMonitoringTitle: "Реал вақтдаги хабар статусини аниқлаш учун саҳифани янгиланг",
    ReadingTime: "Ўқилган вақти",
    SendEmployees: "Жорий хабар юборилган ходимлар",
    Read: "Ўқилган",
    Unread: "Ўқилмаган",
    FromNewMessage: "дан сизга янги хабар келди",
    RestartAccountSipNumber: "Тармоқда узилиш юз берди. Aккаунт ва СИП рақамни қайта ишга туширинг."
  },
};
