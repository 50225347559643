import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {ROLES} from '@core/enums/Roles.enum';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  currentUser: any;

  constructor(private _authenticationService: AuthService, private router: Router) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expectedRoles = route.data['expectedRoles'];
    const profile = this.currentUser;

    const currentUserRole = profile.roles[0]?.name;

    if (!expectedRoles.includes(currentUserRole)) {
      const path = currentUserRole === ROLES.OPERATOR ? '/service-desk' : '/';

      this.router.navigate([path]);

      return false;
    }
    return true;
  }
}
