import {AfterViewInit, Component, inject, OnDestroy, OnInit, signal, ViewChild} from '@angular/core';
import {bounceAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from 'angular-animations';
import {Subject} from 'rxjs';
import {CoreCommonModule} from '@core/common.module';
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {NgbAccordionModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {NgScrollbar} from 'ngx-scrollbar';
import {AccordionModule} from 'primeng/accordion';
import {ModalService} from '@core/services/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {ROLES} from '@core/enums/Roles.enum';
import { ToastService } from '@core/services/toast.service';
import { Router } from '@angular/router';
import { NotificationsWsService } from './services/notifications-ws.service';
import {PaginationComponent} from '@shared/components/pagination/pagination.component';
import {NotificationsService} from '@layout/components/navbar/navbar-notification/services/notifications.service';
import {AddNotificationComponent} from '@layout/components/navbar/navbar-notification/actions/add-notification/add-notification.component';
import { NetworkOutageEnum } from '@core/enums/NetworkOutage.enum';

interface SubNotification {
  id: number;
  to_user: string;
  to_user_name: string;
  updated_at: string;
  read: boolean;
}

interface Notification {
  id: number;
  type: number;
  read: boolean;
  from_user: string;
  to_user: string;
  content: string;
  created_at: string;
  user_name: string;
  notifications: SubNotification[];
}


@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
  styleUrl: './navbar-notification.component.scss',
  standalone: true,
  animations: [
    bounceAnimation({delay: 100, duration: 1000}),
    fadeInOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
  ],
  imports: [
    CoreCommonModule,
    PaginationComponent,
    SpinnerComponent,
    KeenIconComponent,
    NgbDropdownModule,
    NgScrollbar,
    NgbAccordionModule,
    AccordionModule,
    NgxSkeletonLoaderModule
  ],
  providers: [NotificationsWsService]
})
export class NavbarNotificationComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('notificationAudio') notificationAudio: any;

  public animationState = false;
  public notifications: Array<Notification> = [];
  public isAll = false;
  public userId = null;
  public unreadNotificationsCount = 0;
  currentUser: any;
  protected isAdminOrDepHead = signal(false);
  private _intervalId: any;
  private _unsubscribeAll = new Subject();
  private ntfWsService = inject(NotificationsWsService);
  private toast = inject(ToastService);
  private translocoService = inject(TranslateService);

  constructor(
    public notificationsService: NotificationsService,
    public modalService: ModalService,
    public translate: TranslateService,
    private _authenticationService: AuthService,
    private _router: Router,
  ) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    if (this.currentUser && this.currentUser?.roles[0]?.name) {
      const role = this.currentUser.roles[0].name;
      if (role === ROLES.ADMIN || role === ROLES.DEPARTMENT_HEAD) {
        this.isAdminOrDepHead.set(true);
      }
    }

    this.ntfWsService.connect();
  }

  ngOnInit(): void {
    let userData = this.currentUser;

    if (userData && userData.id) {
      this.userId = userData.id;

      this.readLatestNotifications();
    }
  }

  ngAfterViewInit(): void {
    this.ntfWsService.receivedMessage.subscribe(data => {
        if (data?.message) {
          const parsedResponse = JSON.parse(data?.message);
          if(parsedResponse?.message && parsedResponse?.message == NetworkOutageEnum.Network_Outage){
            this.toast.error(this.translocoService.instant('RestartAccountSipNumber'));
          } else {
            const messageValue = parsedResponse?.from_user;
            this.toast.info(messageValue + this.translocoService.instant('FromNewMessage'));

            this.readLatestNotifications();
          }
        }
      }
    );
  }

  readLatestNotifications(playSound: boolean = false) {
    this.notificationsService.getUnreadNotifications(this.userId).subscribe((data: any) => {
      if (data?.success && data?.data?.data.length > 0) {
        this.notifications = data.data.data;

        this.unreadNotificationsCount = this.notifications.length;

        if (playSound) {
          this.notificationAudio?.nativeElement?.play();
        }

        if (this.isAll && this.unreadNotificationsCount > 0) {
          this.isAll = false;
        }
      }
    });
  }

  readAll() {
    this.notificationsService.getAllNotifications(this.userId).subscribe((data: any) => {
      if (data?.success && data?.data?.data.length > 0) {
        this.notifications = data.data.data;
      }
    });
  }

  click() {
    this.isAll = !this.isAll;
    this.notifications = [];

    if (this.isAll) {
      this.readAll(); 
    } else {
      this.readLatestNotifications(); 
    }
  }

  notificationClick(id: number, read: boolean) {
    if (!read) {
      this.notificationsService.readNotification(id).subscribe(
        (result: any) => {
          if (result.success) {
            const itemId = this.notifications.findIndex(item => item.id === id);

            this.notifications[itemId].notifications[0].read = true;

            this.unreadNotificationsCount = Math.max(0, this.unreadNotificationsCount - 1);
          }
        },
        error => {
          console.error('Error occurred:', error);
        }
      );
    }
  }

  addNotification() {
    this.modalService.open({
      component: AddNotificationComponent,
      title: 'Notification.AddNotification',
      modalOptions: {
        size: 'lg',
      },
    });
  }

  goNtfMonitoring() {
    this._router.navigate(['/crm/ntf-monitoring']);
  }

  // connectPusher(): void {
  //   const userId = this.currentUser?.id;
  //   const token = sessionStorage.getItem('accessToken');
  //
  //   const pusher = new Pusher('04d0dcbbd18f7189664a', {
  //     cluster: 'ap2',
  //     authEndpoint: '/auth',
  //     auth: {
  //       headers: {
  //         'Authorization': 'Bearer ' + token
  //       }
  //     }
  //   });
  //
  //   const channel = pusher.subscribe(`public-channel-${userId}`);
  //   channel.bind('operator-notification', () => {
  //     this.readLatestNotifications(true);
  //   });
  // }

  ngOnDestroy(): void {
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
