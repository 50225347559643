import {Routes} from '@angular/router';
import {AuthGuard} from '@core/guards/auth.guard';
import {RoleGuard} from '@core/guards/role.guard';
import {ROLES} from '@core/enums/Roles.enum';
import {OPERATORS_BREAK_TIME_PATH, OPERATORS_LIST_PATH, OPERATORS_WORK_TIME_PATH} from '@control-panel/helpers/paths';

export default [
  {
    path: 'crm',
    loadComponent: () => import('@control-panel/pages/main/crm-main.component').then(c => c.CrmMainComponent),
    canActivate: [AuthGuard, RoleGuard],
    data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]},
    children: [
      {
        path: '',
        loadComponent: () => import('@control-panel/pages/statistic/statistic.component').then(c => c.StatisticComponent)
      },
      {
        path: 'roles',
        loadComponent: () => import('@control-panel/pages/control/roles/roles.component').then(c => c.RolesComponent),
      },
      // {
      //   path: 'legal-consumers',
      //   loadComponent: () => import('@control-panel/components/consumers/pages/legal-consumers/legal-consumers.component').then(c => c.LegalConsumersComponent),
      // },
      // {
      //   path: 'physical-consumers',
      //   loadComponent: () => import('@control-panel/components/consumers/pages/physical-consumers/physical-consumers.component').then(c => c.PhysicalConsumersComponent),
      // },
      {
        path: 'vip-customers',
        loadComponent: () => import('@control-panel/pages/control/vip-customers/vip-customers.component').then(c => c.VipCustomersComponent),
      },
      {
        path: 'block-contacts',
        loadComponent: () => import('@control-panel/pages/control/blacklist/block-contacts/block-contacts.component').then(c => c.BlockContactsComponent),
      },
      {
        path: 'call-history',
        loadComponent: () => import('@control-panel/pages/control/blacklist/call-history/call-history.component').then(c => c.CallHistoryComponent),
      },
      {
        path: 'users',
        loadComponent: () => import('@control-panel/pages/control/users/users.component').then(c => c.UsersComponent),
      },
      {
        path: OPERATORS_LIST_PATH,
        loadComponent: () => import('@control-panel/pages/control/operators/operators-list/operators-list.component').then(c => c.OperatorsListComponent),
      },
      {
        path: OPERATORS_WORK_TIME_PATH,
        loadComponent: () => import('@control-panel/pages/control/operators/operators-work-time/operators-work-time.component').then(c => c.OperatorsWorkTimeComponent),
      },
      {
        path: OPERATORS_BREAK_TIME_PATH,
        loadComponent: () => import('@control-panel/pages/control/operators/operators-break-time/operators-break-time.component').then(c => c.OperatorsBreakTimeComponent),
      },
      {
        path: 'users-monitoring',
        loadComponent: () => import('@control-panel/pages/control/user-monitoring/user-monitoring.component').then(c => c.UserMonitoringComponent),
      },
      {
        path: 'video-stream-monitoring',
        loadComponent: () => import('@control-panel/pages/control/video-stream-monitoring/video-stream-monitoring.component').then(c => c.VideoStreamMonitoringComponent),
      },
      {
        path: 'geo-map',
        loadComponent: () => import('@control-panel/pages/map/geo-map/geo-map.component').then(c => c.GeoMapComponent),
      },
      {
        path: 'geo-map-setting',
        loadComponent: () => import('@control-panel/pages/map/geo-map-setting/geo-map-setting.component').then(c => c.GeoMapSettingComponent),
      },
      {
        path: 'bot-report',
        loadComponent: () => import('@control-panel/pages/control/bot-report/bot-report.component').then(c => c.BotReportComponent),
      },
      {
        path: 'sip-numbers',
        loadComponent: () => import('@control-panel/pages/control/sip-numbers/sip-numbers.component').then(c => c.SipNumbersComponent),
      },
      {
        path: 'call-time',
        loadComponent: () => import('@control-panel/pages/control/settings/call-time/call-time.component').then(c => c.CallTimeComponent),
      },
      {
        path: 'coato-permits',
        loadComponent: () => import('@control-panel/pages/control/settings/coato-permits/coato-permits.component').then(c => c.CoatoPermitsComponent),
      },
      {
        path: 'cas-rele-audit',
        loadComponent: () => import('@control-panel/pages/control/cas-rele-audit/cas-rele-audit.component').then(c => c.CasReleAuditComponent),
      },
      {
        path: 'operators-bind',
        loadComponent: () => import('@control-panel/pages/control/operators/operators-bind/operators-bind.component').then(c => c.OperatorsBindComponent),
      },
      {
        path: 'phone-change-audit',
        loadComponent: () => import('@control-panel/pages/control/phone-change-audit/phone-change-audit.component').then(c => c.PhoneChangeAuditComponent),
      },
      {
        path: 'ntf-monitoring',
        loadComponent: () => import('@control-panel/pages/notifications/ntf-monitoring/ntf-monitoring.component').then(c => c.NtfMonitoringComponent),
        data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]},
      }
    ]
  },
] satisfies Routes;
