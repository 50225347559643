import {ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HorizontalLayoutComponent} from '@core/layout/horizontal/horizontal-layout.component';
import {VerticalLayoutComponent} from '@core/layout/vertical/vertical-layout.component';
import {CoreConfigService} from '@core/services/config.service';
import {CoreConfig} from '@core/types';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, HorizontalLayoutComponent, VerticalLayoutComponent]
})
export class LayoutComponent {
  public coreConfig: CoreConfig;

  constructor(
    public coreConfigService: CoreConfigService,
    public changeDetection: ChangeDetectorRef,
  ) {
    coreConfigService.getConfig().subscribe(
      (config) => {
        this.coreConfig = config;
        this.changeDetection.markForCheck();
      }
    );
  }
}
