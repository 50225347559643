<!--<div class="content-wrapper  p-0 full-width mb-1">-->
<!--  <div class="content-body">-->
<!--    <div class="card">-->
<!--      <div class="card-body align-items-center d-flex justify-content-between">-->
<!--        <div class="text-dark" style="padding-left: 5px">© 2024 Все права защищены</div>-->
<!--        <div class="d-flex">-->
<!--          <span>Единый интегратор</span>-->
<!--          <a class="ml-1" href="https://uzinfocom.uz/">-->
<!--            <img alt="Uzinfocom" src="./assets/logo/logo_uzinfocom.svg">-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="card-body  d-flex justify-content-between px-3" style="padding: 0.5rem 0 0.5rem 0">
  <div class="content-center">
    <span class="mx-50">© {{ this.year }} {{ 'Все права защищены' | translate }}</span>
  </div>
  <div class="d-flex align-items-center justify-content-end">
    <span class="mr-1">{{ 'Единый интегратор' | translate }}</span>
    <a class="hover:text-slate-900 dark:hover:text-slate-400" href="https://uzinfocom.uz" target="_blank">
      <img alt="UZINFOCOM" src="./assets/logo/uzinfocom.png" style="max-height: 240px; max-width: 140px; padding: 1px">
    </a>
  </div>
</div>

<!--<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>-->
