import {Routes} from '@angular/router';
import {AuthGuard} from '@core/guards/auth.guard';
import {MainComponent} from '@call-center/pages/core-call-center/main.component';
import {RoleGuard} from '@core/guards/role.guard';
import {ROLES} from '@core/enums/Roles.enum';

export default [
  {
    path: 'call-center',
    component: MainComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('@call-center/pages/main/call-center-main.component').then((c) => c.CallCenterMainComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
        }
      },
      {
        path: 'call-history/:id/:consumerId/:consumerType',
        loadComponent: () => import('@call-center/pages/calls/calls-history/actions/id-calls-history-p2/id-calls-history-p2.component').then((c) => c.IdCallsHistoryP2Component),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
        }
      },
      {
        path: 'call-history/:id',
        loadComponent: () => import('@call-center/pages/calls/calls-history/actions/id-call-history/id-call-history.component').then((c) => c.IdCallHistoryComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
        }
      },
      {
        path: 'call-history',
        loadComponent: () => import('@call-center/pages/calls/calls-history/calls-history.component').then((c) => c.CallsHistoryComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
        }
      },
      // {
      //   path: 'call-forward',
      //   loadComponent: () => import('./pages/call-forward/call-forward.component').then((c) => c.CallForwardComponent),
      //   canActivate: [AuthGuard, RoleGuard],
      //   data: {
      //     animation: 'decommerce',
      //     expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      //   }
      // },
      // {
      //   path: 'notifications',
      //   loadComponent: () => import('@call-center/pages/notifications/notifications.component').then((c) => c.NotificationsComponent),
      //   canActivate: [AuthGuard, RoleGuard],
      //   data: {
      //     animation: 'decommerce',
      //     expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      //   }
      // },
      {
        path: 'call/:id',
        loadComponent: () => import('@call-center/pages/consumers/id-call/id-call.component').then((c) => c.IdCallComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
        }
      },
      {
        path: 'call',
        loadComponent: () => import('@call-center/pages/consumers/call.component').then((c) => c.CallComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
        }
      },
      {
        path: 'queue-calls',
        loadComponent: () => import('@call-center/pages/calls/queue-calls/queue-calls.component').then((c) => c.QueueCallsComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'live-calls',
        loadComponent: () => import('@call-center/pages/calls/live/live-calls/live-calls.component').then((c) => c.LiveCallsComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'live-stats',
        loadComponent: () => import('@call-center/pages/calls/live/grafik/grafik.component').then((c) => c.GrafikComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          animation: 'decommerce',
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'prefix-call',
        loadComponent: () => import('@call-center/pages/calls/prefix-call/prefix-call.component').then((c) => c.PrefixCallComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'incidents',
        loadComponent: () => import('@call-center/pages/calls/incidents/incidents.component').then((c) => c.IncidentsComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'spy-report',
        loadComponent: () => import('@call-center/pages/calls/spy-report/spy-report.component').then((c) => c.SpyReportComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'not-answered-call',
        loadComponent: () => import('@call-center/pages/calls/not-answered-call/not-answered-call.component').then((c) => c.NotAnsweredCallComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'operator-report',
        loadComponent: () => import('@call-center/pages/calls/report/operator-report/operator-report.component').then((c) => c.OperatorReportComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'consumer-report',
        loadComponent: () => import('@call-center/pages/calls/report/consumer-report/consumer-report.component').then((c) => c.ConsumerReportComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      {
        path: 'delete-channels',
        loadComponent: () => import('@call-center/pages/calls/live/delete-channels/delete-channels.component').then((c) => c.DeleteChannelsComponent),
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
        }
      },
      // {
      //   path: 'common-cal-report',
      //   loadComponent: () => import('@call-center/pages/common-report/common-call-report.component').then((c) => c.CommontCallReportComponent),
      //   canActivate: [AuthGuard, RoleGuard],
      //   data: {
      //     expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      //   }
      // }
    ]
  }
] satisfies Routes;
