import {inject, Injectable, signal} from '@angular/core';
import {Subject} from 'rxjs';
import {ToastService} from '@core/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@app/modules/pages/login/auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class NotificationsWsService {
  public receivedMessage: Subject<any> = new Subject<any>();
  currentUser: any;
  public isLostSignal = signal(false);
  private socket: WebSocket;
  private toast = inject(ToastService);
  private translateService = inject(TranslateService);
  private timeout: any;
  private alertCount: number = 0;

  constructor(private _authenticationService: AuthService) {
    this._authenticationService.currentUser.subscribe(
      (x) => {
        this.currentUser = x;

        this.socket = new WebSocket(`${environment.wsNtfUrl}/public-channel-${this.getUserId()}/ws`);
      }
    );
  }

  getUserId(): string {
    return this.currentUser?.id;
  }

  connect(): void {
    this.socket.onopen = (event) => {

      setInterval(() => {
        this.socket.send('ping');

        this.timeout = setTimeout(() => {
          if (this.alertCount === 0) {
            this.toast.warning(this.translateService.instant('CommunicationHasDeteriorated') + '\nWebSocket');
            this.isLostSignal.set(true);
            this.alertCount++;
          }
        }, 2000);
      }, 15000);
    };

    this.socket.onmessage = (event) => {
      clearTimeout(this.timeout);
      this.receivedMessage.next(JSON.parse(event.data));
    };

    this.socket.onerror = (error) => {
      this.toast.error('Notification Websocket ' + this.translateService.instant('Components.CallCenter.SocketError'));

      console.error('WebSocket error:', error);
    };

    this.socket.onclose = (event) => {
      // this.toast.warning(this.transloco.instant('Components.CallCenter.SocketClosed') + ' WebSocket',);
      this.isLostSignal.set(true);

      console.log('WebSocket connection closed');
    };
  }
}
