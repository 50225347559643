import {Injectable} from '@angular/core';
import {DefaultService} from '@core/services/default.service';

@Injectable({
  providedIn: 'root'
})
export class OutgoingCallService extends DefaultService {

  formName: 'outgoing-call';

  constructor() {
    super();
  }

  getUrl(): string {
    return 'call-center/api/v1/outgoing-call';
  }
}
