<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="modal-body">
    <div class="input-content">
      <ups-phone-input
        [required]="true"
        formControlName="phone_number"
      ></ups-phone-input>
    </div>
  </div>
  <div class="modal-footer">
    <ups-button
      [isPending]="isPending()"
      [styleType]="'primary'"
      label="Components.CallCenter.MakeCall"
      type="submit"
    ></ups-button>
  </div>
</form>
