export const locale = {
    lang: "uz",
    data: {
        Menu: {
            Spravochnik: {
                Title: "Spravochniklar",
                Children: {
                    Departments: "Filial boʻlimlari",
                    Branches: "COATO filiallari",
                    TicketTypes: "Murojaat turlari",
                    TicketStatuses: "Holat turlari",
                    TicketPriorities: "Daraja turlari",
                    Activities: "Faoliyat turlari",
                    Boards: "Murojaatlar",
                    Projects: "Loyihalar",
                },

            },

            Home: "Bosh sahifa",
            CRM: "Boshqaruv paneli",
            HelpDesk: "Yordam oynasi",
            GeoMap: "Geoxarita",
            GeoMapSetting: "Geoxarita sozlamalari",
            CallCenter: "Call markaz",
            ChatService: "Chat xizmati",
            Statistics: "Statistika",
            Consumers: "Iste\'molchilar",
            All: "Umumiy",
            LegalConsumers: "Yuridik iste\'molchilar",
            LegalConsumer: "Yuridik iste\'molchi",
            PhysicalConsumers: "Maishiy iste\'molchilar",
            PhysicalConsumer: "Maishiy iste\'molchi",
            VIPCustomers: "VIP mijozlar",
            BlockContacts: "Qora ro'yxat",
            VIPCustomer: "VIP mijoz",
            Users: "Xodimlar",
            Operators:"Operatorlar",
            Management: "Boshqaruv",
            Appeals: "Murojaatlar",
            Marketing: "Marketing",
            Roles: "Rollar",
            Services: "Xizmatlar",
            Calls: "Qoʻngʻiroqlar",
            CallsHistory: "Tarix",
            CallForward: "Yoʻnaltirilgan",
            LiveCalls: "Jonli",
            QueueCalls: "Navbatdagi",
            Evaluation: "Baholash",
            SendNotificatin: "Bildirishnoma yuborish",
            SendNotificationToClients: "Mijozlarga yuborish",
            SendNotificationToOperators: "Operatorlarga yuborish",
            AssignRoles:'Rol biriktirish',
            RoleNotMessage: 'Rol mavjud emas!',
            CallRecording: "Yozib olinganlar",
            PersonnelManagement: "Xodimlar boshqaruvi",
            ViewContract: "Shartnomani koʻrish",
            UserCode: "Foydalanuvchi kodi",
            ConsumerCode: "Iste\'molchi kodi",
            ContractNumber: "Shartnoma raqami",
            FullNameofConsumer: "Iste\'molchining toʻliq ismi",
            ShortNameofConsumer: "Iste\'molchining qisqacha nomi",
            INN: "INN",
            Account: "Hisob",
            ContractDate: "Shartnoma sanasi",
            StatisticalData: "Statistik ma\'lumotlar",
            AddressAndBankDetails: "Manzil va bank rekvizitlari",
            ConditionsofEnergy: "Energiya ta\'minoti shartlari",
            PowerandAmount: "Quvvat va energiya miqdori",
            OtherInfo: "Boshqa ma\'lumotlar",
            SubtractedFrom: "Ajratildi",
            ListMeter: "Hisoblagichlar roʻyxati",
            ControlCode: "Boshqaruv kodi",
            OKPO: "OKPO",
            Subgroup: "SOOGU kichik guruhi",
            Codeby: "OKONH boʻyicha kod",
            FormCodeby: "FSC boʻyicha shakl kodi",
            FormCodeby1: "COPF boʻyicha shakl kodi",
            PlaceofRegistration: "Roʻyxatdan oʻtish joyi",
            UserMonitoring: "Xodimlar kuzatuvi",
            Tickets: "Murojaatlar",
            Contracts: "Shartnomalar",
            Reports: "Hisobotlar",
            Report: "Hisobot",
            RequestsReports: "Hududlar",
            StatisticalReports: "Murojaat turlari",
            TimeReports: "Vaqt boʻyicha",
            OperatorReports: "Operatorlar boʻyicha",
            Main: "Asosiy",
            Prefiks:"Prefiks",
            Incidents: "Insidentlar",
            BotReport:"Bot hisoboti",
            Spy:"Shpionaj",
            SipNumbers:"SIP raqamlar",
            BreakTime: 'Tanaffus vaqtlar',
            WorkTime: 'Ish vaqtlar',
            CallTime:"Qoʻngiroqlar orasidagi vaqt",
            NotAnsweredCall:"Javobsiz qoʻngʻiroqlar",
            List: "Roʻyxat",
            BreakTimes: 'Tanaffus vaqtlari',
            WorkTimes: "Ish vaqtlari",
            ReleAudit:"Rele Audit",
            LiveStats:"Grafik",
            OperatorReport:"Operatorlar Hisoboti",
            CoatoSettings: "COATO ruhsatlari",
            CoatoSettingsTitle: "Operatorlarni COATOga nisbatan kirish huquqlari",
            CommonReport:"Umumiy Hisobot",
            PhoneChangeAudit:"Telefon raqamlar hisoboti",
            DeleteChannels:"Qo'ng'iroq kanallarni o'chirish",
            VideoStreamMonitoring:"Xodimlar video kuzatuvi"
        },

        Block: {
            Active: "Faol",
            InActive: "Nofaol",
        },
        Time:{
          Hour:"Soat",
          Minute:"Daqiqa",
          Second:"Soniya"
        },
        Actions: {
            Buttons: {
                Submit: "Saqlash",
                Cancel: "Bekor qilish",
                Open: "Ochish",
                LoadMore:"Koʻproq yuklang",
                Forward: "Yoʻnaltirish",
                WorkBind:"Ish vaqti biriktirish"
            },
            Toast: {
                Massage: "Oʻchirishni tasdiqlash",
                ToastDelete: "Tanlangan jadval qatori muvaffaqiyatli oʻchirildi!",
                ConfirmMassage: "Diqqat oʻchirishni tasdiqlang!",
                ConfirmButton: "Oʻchirish",
                CancelButton: "Bekor qilish",
                Yes: "Ha",
                No: "Yoʻq",
            },
            Title: "Harakat",
            Add: "Qoʻshish",
            AddTicket: "Murojaat qoʻshish",
            Cleaning: "Tozalash",
            SendNotification: "Bildirishnoma yuborish",
            Update: "Tahrirlash",
            UpdateCallData: "Qoʻngʻiroq tafsilotlarini yangilash",
            Delete: "Oʻchirish",
            DeleteImg: "Rostan ham bu rasmni oʻchirishni hohlaysizmi?",
            DeleteImgMessage: "Rasm oʻchirildi!",
            DeleteIncidentsWarning: "Rostan ham bu insidentni oʻchirishni hohlaysizmi?",
            DeleteIncidentsMessage: "Insident ma'lumotlari oʻchirildi!",
            UpdateIncidentsTitle: "Tahrirlashni tasdiqlash",
            UpdateIncidentsWarning: "Rostan ham bu insidentni tahrirlashni hohlaysizmi?",
            UpdateIncidentsMessage: "Insident ma'lumotlari tahrirlandi!",
            ForwardCall: "Qoʻngʻiroqni yoʻnaltirish"
        },

        Dashboard: {
            Statistics: "Statistika",
            AppealOfNumbers: "Murojaatlar soni hududlar kesimida",
            CallOfNumbers: "Qoʻngʻiroqlar soni hududlar kesimida",
            AppealOfStatus: "Murojaatlar holati statistikasi",
            AppealOfModuleType: "Murojaatlar qayerdan yaratilganligi boʻyicha statistika",
            AppealOfTypeByRegions: "Murojaatlar turlari boʻyicha statistika hududlar kesimida",
            Done: "Bajarilgan",
            InProgress: "Jarayonda",
            Checked: "Tekshirilmoqda",
            Backlog: "Oʻrganilmoqda",
            Archived: "Arxivlangan",
            AppealsOfMonths: "Murojaatlar soni oylar kesimida",
            Appeals: "Murojaatlar",
            GradeByUser: "Foydalanuvchilar bergan baholar",
            Users: "Foydalanuvchilar",
            Excellent: "Ajoyib",
            Good: "Yaxshi",
            Satisfactory: "Qoniqarli",
            NotSatisfactory: "Qoniqarsiz",
            Bad: "Yomon",
            AppealOfType: "Murojaatlar turlari boʻyicha statistika",
            Complaints: "Shikoyatlar",
            Suggestions: "Takliflar",
            MustBeDone: "Bajarilishi kerak",
            Completed: "Yakunlandi",
            DDone: "Bajarildi",
        },

        Reports: {
            SubName: {
                Time: "Qoʻngʻiroq davomiyligi boʻyicha",
                Region: "Viloyat boʻyicha",
                Operator: "Operatorlar boʻyicha",
            },
            Requests: "Murojaatlar",
            Statistical: "Statistika",
            Users: "Xodimlar",
            Tickets: "Murojaatlar",
            Time: "Qoʻngʻiroq davomiyligi",
            Region: "Viloyatlar",
            Operator: "Operatorlar",
        },

        Table: {
            Pagination: "{{totalPages}} tadan {{starterItemNumber}} dan {{endItemNumber}} gacha",
            HeaderNumbers: "№",
            PaginationShow: "Koʻrsatish",
            NoData: "Ma\'lumot topilmadi!",
        },

        Components: {
            Users: {
                Title: "Xodimlar",
                TableHeader: {
                    Name: "Ism",
                    Email: "Email",
                    EmailVerifiedAt: "Email tasdiqlangan vaqti",
                    CreatedAt: "Yaratilgan vaqti",
                    UpdatedAt: "Oʻzgartirilgan vaqti",
                    CoatoBranch: "COATO filial",
                    CoatoDepartment: "COATO boʻlim",
                    SipNumber: "SIP raqami",
                    Phone: "Telefon",
                    Address: "Manzil",
                    Status: 'Holat',
                    ACTIVE: 'Faol',
                    IN_ACTIVE: 'Faol emas',
                    PERMISSION: "Ruhsat etilgan",
                    FORBIDDIN: "Taqiqlangan",
                    LocalPassword: "Lokal parol",
                    LocalPassword2: "Lokal parolni tasdiqlang",
                    ConfirmLocalPassword: "Ilovani ochish uchun lokal parolni kiriting",
                    Staff:"Xodim"
                },
            },
            Consumers: {
                LegalConsumers: {
                    Title: "Yuridik iste\'molchilar",
                    TableHeader: {
                        ConsumerPersonalAccount: "Iste\'molchi kodi",
                        ConsumerBusinessName: "Iste\'molchi nomi",
                        AddressNote: "Manzil",
                        BookCode: "Kitob",
                        AccountingStatus: "Hisoblash holati",
                        CalculationCategory: "Hisoblash turi",
                        ReportStatus: "Hisobot holati",
                        ConsumerStatus: "Iste\'molchi holati",
                        MetersCount: "Hisoblagichlar soni",
                        ActionColumn: "Harakatlar",
                    },
                    DateConclusionOfAgreement: "Shartnoma tuzilgan sana",
                    ContractNumber: "Shartnoma raqami",
                    ContracterFullName: "Toʻliq iste\'molchi",
                },
                LegalConsumersById: {
                    ConsumerPersonalAccount: "Iste\'molchi kodi",
                    SummaryDate: "Xulosa sanasi",
                    ContractNumber: "Shartnoma raqami",
                    ContractRenewalDate: "Shartnomani uzaytirish sanasi",
                    CustomerFullName: "Iste\'molchining toʻliq nomi",
                    CustomerShortName: "Iste\'molchining qisqacha nomi",
                    FaceType: "Yuz turi",
                    INN: "INN",
                    NDS_RegistrationCode: "NDS roʻyxatdan oʻtish kodi",
                    Score: "Hisob",
                    PromNepromPred: "Prom/Neprom/Pred",
                    Resident: "Rezident",
                    ResidentYes: "Ha",
                    ResidentNo: "Yoʻq",
                    CadastreAddress: "Kadastr boʻyicha manzil",
                    CadastreCode: "Kadastr raqam",
                    Sector: "Sektor",
                    PINFL: "PINFL",
                    StatisticalInformation: {
                        Title: "Statistik ma\'lumotlar",
                        PaymentCode: "Toʻlov kodi",
                        OKPO: "OKPO",
                        SOOGU: "SOOGU",
                        SOOGU_Subgroup: "SOOGU kichik guruhi",
                        OKED_Code: "OKED kodi",
                        FormCode_KFS: "KKF boʻyicha shakl kodi",
                        FormCode_COPF: "COPF shakl kodi",
                        PlaceOfRegistration: "Roʻyxatdan oʻtish joyi",
                        ConsumerGroup: "Iste\'mol guruhi",
                    },
                    Address: {
                        Title: "Manzil",
                        SOATO_Address: "SOATO manzillari",
                        Neighborhood: "Mahalla",
                        Street: "Koʻcha",
                        Home: "Uy",
                        Home2: "Uy(Dom)",
                        FlatName: "Kvartira",
                        CorpusName: "Korpus",
                        LegalAddress_SOATO: "SOATO yuridik manzil",
                        HigherOrganization: "Yuqori tashkilot",
                        ContactPhoneNumber: "Aloqa telefon raqami",
                        MobilePhone: "Mobil telefon",
                        EmailAddess: "Elektron pochta manzili",
                    },
                    BankDetails: {
                        Title: "Bank rekvizitlari",
                        CalculationType: "Hisoblash turi",
                        MFO: "MFO",
                        Score: "Hisob",
                        BranchCode: "Filial kodi",
                        BranchRecruitment: "Filialni yollash",
                        Supervisor: "Nazoratchi",
                        Accountant: "Buxgalter",
                        FIO: "FIO",
                        Position: "Lavozim",
                    },
                },
                PhysicalConsumers: {
                    Title: "Jismoniy iste\'molchilar",
                    TableHeader: {
                        PersonalAccount: "Shaxsiy hisob",
                        Name: "FIO iste\'molchisi",
                        Passport: "Passport",
                        Address: "Manzil",
                        Agent: "Agent",
                        TP: "TP",
                        Transformer: "Oziqlantiruvchi",
                        CalculationType: "Hisoblash turi",
                        CalculationStatus: "Holat",
                        LastReading: "Oxirgi aylanma yoʻl",
                        LastReadingDate: "Oxirgi aylanma yoʻl sanasi",
                    },
                },
                PhysicalConsumersById: {
                    FaceCard: {
                        Title: "Iste\'molchi kartasi",
                        PersonalAccount: "Shaxsiy hisob",
                        MobilePhone: "Mobil telefon",
                        Address: "Manzil",
                        Neighborhood: "Mahalla",
                        StreetAddress: "Uy manzili",
                        Surname: "Familiya",
                        FirstName: "Ism",
                        FatherName: "Sharif",
                        Reminder: "Eslatma",
                    },
                    Document: {
                        Title: "Hujjat",
                        DocumentType: "Hujjat turi",
                        IdCard: "ID karta",
                        Passport: "Passport",
                        DocumentSeries: "Hujjat seriyasi va №",
                        GivenWhom: "Kim tomonidan berilgan",
                        DateIssue: "Berilgan sana",
                        DateBirth: "Tugʻilgan sana",
                        INN: "INN",
                        CadastralCode: "Kadastr kodi",
                        PINFL: "PINFL",
                        Language: "Til",
                    },
                    GeneralInformation: {
                        Title: "Hamma ma\'lumotlar",
                        Telephone: "Telefon",
                        Agent: "Agent",
                        Book: "Kitob",
                        Brigade: "Brigada",
                        Status: "Holat",
                        StatusAlive: "Jonli",
                        StatusDead: "Nofaol",
                        Consent: "Rozilik",
                        ConsentYes: "Ha",
                        ConsentNo: "Yoʻq",
                    },
                    Counter: {
                        Title: "Hisoblagich",
                        CounterType: "Hisoblagich turi",
                        SerialMeterNumber: "Hisoblagichni seriya raqami",
                        SerialNumber: "Seriya raqami",
                        NoStandardSeals: "Uzstandart muhrlar №",
                        DateUzstandartSeal: "Uzstandart muhrining sanasi",
                        EnsbytSeals: "Ensbyt muhrlar №",
                        DateEnsbytSeals: "Ensbyt muhrining sanasi",
                        BPI_No: "BPI muhr №",
                        BPI_Date: "BPI muhr sanasi",
                        MeterInstallationLocation: "Oʻrnatish joyi",
                        ASKUE_Type: "ASKUE turi",
                        AgreementDate: "Kelishuv sanasi",
                        ASKUE_Connect: "ASKUE ga ulanish",
                        ASKUE_ConnectYes: "Ha",
                        ASKUE_ConnectNo: "Yoʻq",
                        CounterStatus: "Hisoblagich holati",
                        BitPower: "Bit hajmi",
                        TransformerCoefficient: "Transformatsiya nisbati",
                        SolarPanel: "Quyosh paneli",
                        TariffPrice: "Ta\'rif miqdori",
                        installationReadingPlus: "Oʻrnatish vaqtidagi koʻrsatkichlar (+A)",
                        installationReadingMinus: "Oʻrnatish vaqtidagi koʻrsatkichlar (-A)",
                        PreviousIndications: "Dastlabki oʻqishlar |А|",
                        InstallationDate: "Oʻrnatish sanasi",
                    },
                    Certificate: {
                        Title: "Sertifikat",
                        No: "Sertifikat №",
                        Check: "Tekshirish",
                        ExpirationDate: "Amal qilish",
                    },
                    ConstructionInformation: {
                        Title: "Tuzilish haqida ma\'lumot",
                        Substation: "Podstansiya",
                        Nourishing: "Oziqlantiruvchi",
                        Transformer: "Transformator",
                    },
                    Agreement: {
                        Title: "Shartnoma",
                        TariffSum: "Ta\'rif (sum)",
                        TariffCode: "Ta\'rif kodi",
                        TariffName: "Ta\'rif nomi",
                        ContractNumber: "Shartnoma raqami",
                        DateApplication: "Ariza sanasi",
                        TariffChangeDate: "Ta\'rifni oʻzgartirish sanasi",
                        DateConclusionContract: "Shartnoma tuzilgan sana",
                        ContractRenewalDate: "Shartnomani uzaytirish sanasi",
                        TariffImplementationDate: "Ta\'rif joriy qilingan sana",
                        SignUp_No_REP: "Roʻyxatdan oʻtish № va REP",
                    },
                    AdditionalInformation: {
                        Title: "Qoʻshimcha ma\'lumotlar (Namunaviy  shartnoma boʻyicha VII boʻlim)",
                        LEP: "LEP",
                        One_TP: "1 Oziqlantiruvchi + TP",
                        Two_TP: "2 Oziqlantiruvchi + TP",
                        TypeAccommodation: "Turar joy turi",
                        ClassResidence: "Uy-joy toifasi",
                        ApartmentGassed: "Kvartira gazlashtirilganmi",
                        ApartmentGassedYes: "Ha",
                        ApartmentGassedNo: "Yoʻq",
                        ConnectedCentralSystem: "Markaziy isitish tizimiga ulanganmi",
                        ConnectedCentralSystemYes: "Ha",
                        ConnectedCentralSystemNo: "Yoʻq",
                    },
                    ConsumerAccess_LEP: {
                        Title: "Iste\'molchi 2x yoki 3x uchinchi tomon LEP-0,4 kv",
                        NumberLightingDevices: "Uy jihozlarini soni (dona)",
                        PowerLightingDevices: "Uy jihozlarini quvvati (kVt)",
                        InstalledPower: "Ulangan quvvat (kVt)",
                        NumberSockets: "Rezidentlar miqdori (dona)",
                        TotalPowerConsumption: "Umumiy quvvat iste\'moli (kVt)",
                        TheNumberPersons: "Yashash soni (shaxslar)",
                    },
                    ContractValue: {
                        Title: "Shartnoma qiymati (kVt/soat)",
                        January: "Yanvar",
                        February: "Fevral",
                        March: "Mart",
                        One_KV: "1 kv",
                        AnnualContract: "Yillik shartnoma boʻyicha (kVt/soat)",
                        April: "Aprel",
                        May: "May",
                        June: "Iyun",
                        Two_KV: "2 kv",
                        AnnualAmount: "Yillik miqdor (sum)",
                        July: "Iyul",
                        August: "Avgust",
                        September: "Sentyabr",
                        Three_KV: "3 kv",
                        October: "Oktyabr",
                        November: "Noyabr",
                        December: "Dekabr",
                        Four_KV: "4 kv",
                    },
                },
                VIPCustomers: {
                    Search: "Telefon raqam",
                    AllCustomers: "Barcha mijozlar",
                },
                Consumers: {
                    Title: "Iste\'molchilar",
                    TableHeader: {
                        FirstName: "Ism",
                        LastName: "Familiya",
                        MiddleName: "Sharif",
                        Phone: "Telefon raqam",
                        CreatedAt: "Yaratilgan vaqti",
                    },
                },
                Children: {
                    LegalConsumers: "Yuridik iste\'molchilar",
                    PhysicalConsumers: "Jismoniy iste\'molchilar",
                },
                ConsumerModalTitle: "Isteʻmolchini ma\'lumotlari",
                Title: "Isteʻmolchilar",
            },
            Spravochniks: {
                SpravochniksBranches: {
                    Title: "Filiallar",
                    TableHeader: {
                        Code: "Kod",
                        Name: "Nomi",
                        Email: "Email",
                        Status: "Holati",
                        CreatedAt: "Yaratilgan vaqti",
                        UpdatedAt: "Oʻzgartirilgan vaqti",
                        CoatoBranch: "COATO filial",
                        CoatoDepartment: "COATO boʻlim",
                        Time: "Vaqti",
                    },
                },
                SpravochniksDepartments: {
                    Title: "Boʻlimlar",
                    TableHeader: {
                        Code: "Kod",
                        CoatoCode: "COATO kod",
                        Name: "Nomi",
                        Email: "Email",
                        Status: "Holati",
                        CreatedAt: "Yaratilgan vaqti",
                        UpdatedAt: "Oʻzgartirilgan vaqti",
                        CoatoBranch: "COATO filial",
                        CoatoDepartment: "COATO boʻlim",
                        CoatoBranchId: "COATO filial ID",
                        CoatoBranchName: "COATO filial nomi",
                    },
                },
                Projects: {
                    Title: "Loyihalar",
                    TableHeader: {
                        Name: "Nomi",
                        ShortDesk: "Qisqacha nomi",
                        CreatedAt: "Yaratilgan vaqti",
                        UpdatedAt: "Oʻzgartirilgan vaqti",
                        Description: "Tavsif",
                    },
                },
            },
            Dashboard: {
                SelectPeriod: "Davrni tanlang",
                SelectRegion: "Hududni tanlang",
                RequestType: "Soʻrov turi",
                RequestStatus: "Soʻrovlar holati statistikasi",
                AppealType: "Murojaatlar turlari boʼyicha statistika",
            },
            Tickets: {
                CoatoCode: "COATO kod",
                TableTitle: "Murojaatlar roʻyxati",
                Type: "Turi",
                Importance: "Muhimligi",
                Status: "Holati",
                Title: "Sarlavha",
                Priority: "Darajasi",
                DateSelect: "Vaqt oraliqi",
                Degree: "Darajasi",
                Performer: "Bajaruvchi",
                Deadline: "Tugash vaqti",
                Archive: "Arxiv",
                Archived: "Arxivlangan",
                NotArchived: "Arxivlanmagan",
                Description: "Tavsif",
                File: "Fayl",
                Created: "Yaratilgan vaqti",
                Updated: "Tahrirgan vaqti",
                Creator: "Yaratuvchi",
                Where: "Yaratilish manzili",
                FileDrag: "Faylni shu yerga torting yoki",
                FileSelect: "faylni tanlang",
                MaxFile: "Maxsimal fayl yuborish hajmi",
                Tabs: {
                    Comments: "Izohlar",
                    History: "Tarix",
                    StatusHistory: " Holat Tarixi",
                },
                WriteComment: "Izoh yozing",
                Edit: "Oʻzgartirish",
                Assigners: "Bajaruvchilar",
                NotFound: "Ma\'lumot topilmadi!",
                Confirmation:{
                    message:"Murojaat holatini",
                    from:"dan",
                    to:"ga",
                    question:"oʻzgartirishni xohlaysizmi"
                },
                HasFile:{
                    No:"Fayl mavjud emas",
                    Yes:"Fayl mavjud"
                },
                EmpthyDescription:"Batafsil tavsif qoʻshing...",
                Ticket:'Murojaat',
                Create:"yaratildi",
                Update:"oʻzgaritirildi",
                EmpthyValue:"Boʻsh qiymat",
                CreateFileName:"Yaratilgan fayl nomi",
                DeletedFileName:" oʻchirilgan fayl   nomi",
                AssignUser:"Biriktirilgan bajaruvchi",
                UnAssignUser:"Bekor qilingan bajaruvchi"
            },
            CallCenter: {
                TableHeader: {
                    QueueCalls: "Navbatdagilar",
                    TableHistory: "Qoʻngʻiroqlar tarixi",
                    CallForward: "Yoʻnaltirilgan qoʻngʻiroqlar",
                    TableRecording: "Yozib olingan qoʻngʻiroqlar",
                    Type: "Turi",
                    Status: "Holati",
                    From: "Kimdan",
                    ToOperator: "Kimga",
                    To: "Operator",
                    Via: "Telefon",
                    CreatedAt: "Yaratilgan vaqti",
                    End: "Tugatilgan vaqti",
                    Time: "Vaqti",
                    Audio: "Suhbat yozuvi",
                    Tap: "Operator",
                    via: "Telefon",
                    ContactTime: "Muloqot vaqti",
                    WaitingTime: "Kutish vaqti",
                    TotalWaitingTime: "Umumiy kutish vaqti",
                    TotalDurationTime: "Umumiy muloqat vaqti",
                    CustomerPhoneNumber: "Mijozning telefon raqami",
                    Language: "Til",
                    Filters: "Filtr",
                    FromOperatorSip: "Yoʻnaltirgan operatorning SIP raqami",
                    ToOperatorSip: "Qabul qilgan operatorning SIP raqami",
                    Incoming: "Kiruvchi",
                    IncomingCalls: "Kiruvchi qoʻngʻiroqlar",
                    OutgoingCalls: "Chiquvchi qoʻngʻiroqlar",
                    Outgoing: "Chiquvchi",
                    Answered: "Javob berilgan",
                    NotAnswered: "Javob berilmagan",
                    Missed:"O'tkazib yuborilgan",
                    MissedCallCount:"O'tkazib yuborilganlar",
                    HungUp:"Qo'yib yuborilgan",
                    CallType: "Qoʻngʻiroq turi",
                    CallStatus: "Qoʻngʻiroq holati",
                    MissedCall: "Javobsiz qoʻng'iroq raqami",
                    LastCall: "Oxirgi qoʻng'iroq raqami",
                    Interval:"Interval",
                    SipNumber:"SIP Raqam",
                    IVRYes:"IVR dan foydalangan istemolchilar",
                    IVRNo:"IVR dan foydalanmagan istemolchilar",
                    IVR:"IVR",
                    Call:"Qoʻng'iroq",
                    AllTime:"Barcha davrlar",
                    AllTimeSee:"Barcha davrlarni koʻrish",
                    ViewEvaluation: "Iste'molchi biriktirmasdan baholashda ko'rinishi",
                    AccountNumber: "Hisob raqami",
                    ConsumerType: "Iste'molchi turi",
                    HOUSEHOLD: "Maishiy",
                    LEGAL: "Yuridik",
                    ConsumersReport: "Iste'molchilar hisoboti",
                    LiveStreamingAllowed:"Video kuzatuv",
                    VideoRecord:"Video yozuvlar",
                    StartTime:"Boshlanish vaqti",
                    Link:"Havola",
                    Record:"Yozuvlar",
                    UzbRusKk:"Uzbek | Rus | Qoraqalpoq",
                    DurationTotalAvg:"Davomiylik(Umumiy|O'rtacha)",
                    WorkPercent:"Ish foizi"
                },
                TableUserID: {
                    UserData: "Mijoz ma\'lumotlari",
                    ReqType: {
                        Title: "Ariza turi",
                        Request: "Soʻrov",
                        Complaint: "Shikoyat",
                        Offer: "Taklif",
                        Service: "Xizmat koʻrsatish",
                    },
                    Description: "Eslatma",
                    UserType: {
                        Title: "Mijoz muomalasi",
                        Well: "Yaxshi",
                        Medium: "Oʻrtacha",
                        Rude: "Yomon",
                        VeryBad: "Juda yomon",
                        Lose: "Adashib tushgan raqam",
                    },
                    SubmitBtn: "Joʻnatish",
                    CallHistory: "Qoʻngʻiroqlar tarixi",
                    UserAngryTitle: "Jahldor",
                },
                Call: {
                    TableHeader: {
                        Phone: "Telefon raqami",
                        FISH: "Iste\'molchining toʻliq ismi",
                        oneName: "Ismi",
                        Type: "Iste\'molchining turi",
                        Fio: "F.I.O",
                        Address: "Manzil",
                        PersonalAccount: "Shaxsiy hisob",
                        CounterNumber: "Hisoblagich raqami",
                        CounterInformation: "Hisoblagich ma\'lumotlari",
                        InterruptStartTime: "Uzilish boshlanish vaqti",
                        InterruptEndTime: "Uzilish tugash vaqti",
                    },
                    Status: {
                        CounterStatus: "Hisoblagich holati",
                        StateBalance: "Balans holati",
                        OtherProblems: "Boshqa muammolar",
                        SubStatus: {
                            Good: "Holati yaxshi",
                            Debt: "Qarz bor",
                            Repair: "Ta’mirlash ishlari",
                            Bad:"Holati yomon",
                            Unknown:"Noma'lum"
                        },
                    },
                    CounterStatus: {
                        Launch: "Ishga tushirish",
                        Loading: "Yuklanmoqda",
                        On: "Yoqilgan",
                        Off: "O'chirilgan",
                        OnBtn: "Yoqish",
                        OffBtn: "O'chirish",
                        CountersStatus: "Hisoblagichlar",
                        ChangeCountersStatus: "Hisoblagichlar holatini o'zgartirish",
                        LaunchToastText: "Muvaffaqiyatli ishga tushirildi",
                        Error400Text: "Hisoblagichni 10 daqiqada 1 marta qayta ishga tushirish mumkin",
                        AllBalance: "Jami balans"
                    },
                    UserSearch: "Iste\'molchi qidirish",
                    UserInfo: "Iste\'molchi ma\'lumotlari",
                    ConsumerType: "Iste\'molchi turi",
                    ConsumerName: "Iste\'molchi ma\'lumoti",
                    HomePhone: "Uy telefoni",
                    PassportNumber: "Passport raqami",
                    PassportSerial: "Hujjat seriyasi va №",
                    SolveProblem: "Muammoni hal qilish uchun oʻting:",
                    History: "Qoʻngʻiroqlar tarixi",
                    FeedbackAboutClient: "Mijoz haqida fikr-mulohazalar",
                    TotalItemsSelected: "{ count } ta ma'lumot tanlandi",
                    Incident: "Insident",
                    All: "Jami",
                    Electr: "Elektr",
                    ActViolation: "Akt qoida-buzilishi",
                    Fine: "Penya",
                    Reconnection: "Qayta ulanish",
                    WithoutContract: "Shartnomasiz iste\'mol",
                    Reactive: "Reaktiv",
                    Bust: "Ortiqcha",
                    Shortage: "Kamchilik",
                    Services: "Xizmatlar",
                },

                Title: "Call Center",
                OperatorIsReady: "Javob berish!",
                WaitingClientsNotFound: "Kutayotgan mijozlar mavjud emas",
                CountOfClientsWaiting: "{{count}} ta mijoz operator javob berishini kutmoqda",
                CallConnectedSuccessfully: "Aloqa muvaffaqiyatli oʻrnatildi!",
                CallEnded: "Aloqa tugatilgan!",
                SocketConnected: "Server bilan aloqa muvaffaqiyatli oʻrnatildi!",
                SocketError: "Xatolik yuz berdi. Iltimos, sahifani yangilang va qayta urinib koʻring.",
                SocketClosed: "Server bilan aloqa uzildi!",
                NotConnect: "Ulanmadi. Iltimos keyinroq qayta urinib koʻring",
                ConfirmSpyCall: "Qoʻngʻiroqni kuzatishni tasdiqlash",
                ConfirmSpyCallMessage: "Haqiqatan ham bu qoʻngʻiroqni kuzatishni xohlaysizmi?",
                WaitingConsumersCount: "Qoʻngʻiroqlar navbati",
                CallIsNotAvailable: "Sizda qo'ng'iroq mavjud emas!",
                AttachCallToConsumerSuccessfull: "Qoʻngʻiroq mijozga muvaffaqiyatli biriktirildi",
                MobilePhoneEdited: "Mobil telefon muvaffaqiyatli almashtirildi",
                AttachCallToConsumer: "Qoʻngʻiroqni biriktirish",
                CallEvaluation: "Qoʻng'iroqni baholash",
                AttachCallToConsumerConfirm: "Haqiqatan ham joriy qo‘ng‘iroqni ushbu iste’molchiga biriktirmoqchimisiz?",
                UserEndedCall: "Qoʻngʻiroq tugadi",
                MakeCall: "Qoʻngʻiroq qilish",
                ToastCalled: "Qoʻngʻiroq amalga oshirildi!",
                OutgoingCalls: "Chiquvchi qoʻngʻiroq",
                OutgoingCall: "Chiquvchi",
                Operator: "Operator",
                ToAllOperators: "Barcha operatorlarga",
                CallPrefix:{
                    Region: "Hudud",
                    Prefix:"Prefiks"
                },
                SpyReport:{
                    Title:"Shpionaj hisoboti",
                    TableHeader: {
                        Name:"Ism",
                        Email:"Email",
                        CreatedAt: "Yaratilgan vaqti",
                        StartSpy:"Boshlanish vaqti",
                        EndSpy:"Tugash vaqti"
                    },
                },
                Attention:"Diqqat",
                DeleteChannelTitle:"Barcha qo‘ng‘iroq kanallarini qayta ishga tushirishni amalga oshirish uchun o'chirish tugmasini bosing.",
                Mention:"Eslatma",
                MentionAction:"Eslatma: Barcha harakatlaringiz kuzatilib boriladi.",
                NoLiveVideo:"Jonli video mavjud emas",
                LiveVideoActive:"Jonli video faol",
                LiveVideoError:"Jonli video hozirda faol emas. Iltimos, sahifani yangilang",
                LiveVideoServerError:"Jonli video uzatishda server bilan ulanishda xatolik"
            },
            Boards: {
                title: "Murojaatlar",
                Filter: {
                    Search: "Qidiruv",
                    IgnoreResolved: "Bajarilmagan vazifalar",
                    OnlyMyIssue: "Mening vazifalarim",
                    ResetAll: "Tozalash",
                    User: "Foydalanuvchi",
                },
                TableHeader: {
                    Backlog: "Oʻrganilmoqda",
                    Selected: "Jarayonda",
                    InProgress: "Tekshirilmoqda",
                    Done: "Bajarildi",
                },
            },
            TicketTypes: {
                Title: "Murojaat turlari",
                TableHeader: {
                    Name: "Nomi",
                    NameUz: "Nomi_Uz",
                    NameRu: "Nomi_Ru",
                    NameKk: "Nomi_Kk",
                    NameCrl: "Nomi_Crl",
                    Color: "Rangi",
                    Icon: "Belgi",
                    Status: "Holati",
                },
            },
            TicketStatus: {
                Title: "Holat turlari",
                TableHeader: {
                    Name: "Nomi",
                    Color: "Rangi",
                    Order: "Tartib",
                    Status: "Holati",
                },
            },
            TicketPriorities: {
                Title: "Daraja turlari",
                TableHeader: {
                    Name: "Nomi",
                    Color: "Rangi",
                    Status: "Holati",
                },
            },
            Activities: {
                Title: "Faoliyat turlari",
                TableHeader: {
                    Name: "Nomi",
                    Description: "Tavsif",
                    CreatedAt: "Yaratilgan vaqti",
                },
            },
            Reports: {
                Excel: "Excel",
                TimeBy: {
                    Title: "Qoʻngʻiroq davomiyligi boʻyicha hisobatlar",
                    TableHeader: {
                        Time: "Vaqt",
                        AllCall: "Barcha qoʻngʻiroqlar",
                        DoneCall: "Hal qilingan qoʻngʻiroqlar(soni)",
                        DonePercentage: "Hal qilingan qoʻngʻiroqlar(foiz)",
                    },
                },
                RegionBy: {
                    Title: "Viloyatlar boʻyicha hisobatlar",
                },
                OperatorBy: {
                    Title: "Operator ishi haqida hisobot",
                },
                Bot:{
                    Title:"Botdan olingan hisobotlar",
                    TableHeader: {
                        Name:   "Iste\'molchining toʻliq ismi",
                        Account_Type: "Iste\'molchining turi",
                        Account_Number:"Akkaunt raqami",
                        Coato:"COATO",
                        Count: "Soʻrovlar soni",
                        Last_Request:"Soʻngi soʻrov vaqti"
                    },
                },
            },
            SipNumber:{
                Title:"SIP raqamlar",
                TableHeader: {
                    Phone:   "Raqam",
                    Parol: "Parol",
                    Status: "Holati",
                    CreatedAt: "Yaratilgan vaqti",
                },
            },
            Operators: {
                BindWorkBreakTimeToOperators: 'Ish va tanaffus vaqtlarini biriktirish'
            }
        },

        Errors: {
            Required: "Majburiy maydon",
            ErrorPastDate: "Xatolik, oʻtgan sana",
            ErrorElapsedTime: "Oʻtgan soat",
            ErrChooseFuture: "Kelajakni tanlang",
            ErrorOfflineText: "Internetga ulaning",
            ErrorOfflineTitle: "Siz oflaynsiz. Aloqani tekshiring",
            MaxLengthError: "Maksimal uzunlik: {{ value }} | Hozir: {{ controlValueLength }}",
        },

        ERROR_LIST: {
            POPUP_RESTRICTED: "Sizning brauzeringizda chop etish sahifasini ochishga ruxsat berilmagan!",
            SERVER_ERROR: "Serverda xatolik yuzaga keldi!",
            ACCESS_DENIED: "Kirish cheklangan!",
            BAD_REQUEST: "Notoʻgʻri soʻrov!",
            FIELD_IS_REQUIRED: "Majburiy maydon",
            INVALID_EMAIL_ADDRESS: "Notoʻgʻri elektron pochta manzili",
            MINLENGTH_REQUIRED: "Maydon kamida {{requiredLength}} belgidan iborat boʻlishi kerak",
            MAXLENGTH_REQUIRED: "Maydon kamida {{requiredLength}} belgidan koʻp boʻlishi kerak",
            MIN_REQUIRED: "Uzunlik {{min}} dan koʻp boʻlishi kerak",
            MAX_REQUIRED: "Uzunlik {{max}} dan kam boʻlishi kerak",
            PATTERN_REQUIRED: "Maydon quyidagi belgilar orasida boʻlishi kerak {{requiredPattern}}",
            BETWEEN: "Qiymatlar {{x}} va {{y}} orasida boʻlishi kerak",
            INVALID_LENGTH: "INN {{minLength}} va {{maxLength}} orasida boʻlishi kerak",
            IP_NOT_VALID: "Notoʻgʻri IP-manzil kiritildi",
            CONFIRM_PASSWORD_ERROR: "Parolni tasdiqlashda xatolik boʻldi!",
            NUMERIC: "Bu maydonda faqat raqamlar yoziladi!",
            LOWERCASE: "Bu maydonda faqat kichik harflar yoziladi!",
            UPPERCASE: "Bu maydonda faqat katta harflar yoziladi!",
            MAX_FILE_SIZE: "Fayl hajmi {{size}} dan oshmasligi kerak",
            TWO_Date_Error: "Siz 2 ta sana tanlashingiz kerak!",
            DATE_EMPYTE: "Maydon boʻsh boʻlmasligi kerak!",
            ERROR_RESPONSE: "Notoʻgʻri maʻlumot qaytarildi!",
            ERROR_ACCESS: "Login yoki parol notogʻri",
            NTF_CANNOT_EDIT: "Bu turdagi bildirishnomalarni tahrirlab boʻlmaydi",
            COATO_REQUIRED: 'Viloyat yoki Tumanni tanlash majburiy!',
            COATO_REQUIRED_TEXT: 'Iltimos tuman yoki shahar tanlang!'
        },

        TOAST: {
            TITLE: {
                SUCCESS: "Muvaffaqiyatli",
                CANCELLED: "Bekor qilingan",
                WARNING: "Ogohlantirish",
                ERROR: "Xatolik",
                INFO: "Ma\'lumot",
                DOWNLOAD: "Yuklab olish",
                EXPORT_EXCEL: "Excel fayl muvaffaqiyatli yuklab olindi!",
                IMPORT_EXCEL: "Excel fayl muvaffaqiyatli import qilindi!",
                RESIDENT_ID: "Bu jadval qatori abonent identifikatori emas",
            },
            TOAST_DELETE: "Tanlangan jadval qatori muvaffaqiyatli oʻchirildi!",
            TOAST_SELECT_ROW: "Iltimos, qatorni tanlang!",
            TOAST_SUCCESS: "Maʻlumot muvaffaqiyatli qoʻshildi!",
            TOAST_UPDATE: "Maʻlumot muvaffaqiyatli tahrirlandi!",
            TOAST_STATUS_UPDATE: "Murojaat holati muvaffaqiyatli oʻzgartirildi",
            TOAST_ADD_COATO: "Murojaat qoʻshish uchun COATO tanlang",
            TOAST_MULTIPLE_SELECT_ROW: "Iltimos, qatorlarni tanlang!",
            TOAST_WARNING_RESIDENT: "Bu abonent korxona xodimi emas!",
            TOAST_ERROR_NOT_EMPLOYEE: "Bu abonent korxona xodimi emas!",
            TOAST_WARNING_RESIDENT_SEARCH: "Abonent topilmadi",
            TOAST_WARNING: "Agar fayl strukturasi qanday koʻrinishda boʻlishini bilmasangiz namuna uchun faylni yuklab olishingiz mumkin",
            SUCCES_ACCESS:"Tizimga muvaffaqiyatli kirildi!",
            SUCCESS_NO_ACCESS: 'Siz tizimdan foydalanish huquqiga ega emassiz!',
            ThisEmployeeIDDoesNotExist: "Bu xodim IDsi mavjud emas, Autentifikatsiya jurnalini koʻrish uchun xodim IDsi kerak!",
            DoubleIncident:"Ikkitadan ortiq belgilab bilmaysiz!",
            MinCalendar:"Ish vaqtini biriktirish uchun kamida bitta kun tanlang",
            CameraNotAllowed:"Kameraga kirishga ruxsat berilmagan. Kamerangizni sozlang!"
        },

        MANAGEMENT: {
            TABLE_HEADERS: {
                NUMBERS: "№",
                ID: "ID",
                NAME: "Nomi",
                COMPANY: "Kompaniyalar",
                DATE1: "03.2023",
                DATE2: "04.2023",
                DATE3: "05.2023",
                DATE4: "06.2023",
                DATE5: "07.2023",
                DATE6: "08.2023",
                DATE7: "09.2023",
                ACTION: "Harakat",
                ACTION_LINK: "Oʻtish",
            },
            USER_ROLE: {
                TABLE_HEADERS: {
                    ID: "ID",
                    PREFIX: "Rol prefiksi",
                    NAME: "Rol nomi",
                    LEVEL: "Darajasi",
                    TYPE: "Tipi",
                    COMMENT: "Izoh",
                    COMMENT_UZ: "Izoh (UZ)",
                    COMMENT_CRL: "Izoh (CRL)",
                    COMMENT_RU: "Izoh (RU)",
                },
                FILTER: {
                    FILTER_BUTTON: "Mavjud harakatlar",
                    TITLE: "Ruxsat etilgan harakatlar",
                },
                INSERT_MODAL: "Yangi rol qoʻshish",
                UPDATE_MODAL: "Rolni tahrirlash",
                DELETE_MODAL: {
                    TITLE: "Rolni oʻchirish",
                    TEXT: "Tanlangan rolni tizimdan olib tashlamoqchimisiz?",
                },
                TOAST_EXISTS_ROLENAME: "Bu \"Rol nomi\" tizimda mavjud.\nIltimos boshqa nom kiriting!",
            },
            USERS: {
                ROLE: "Rol",
                ADD_MODAL_TITLE: "Foydalanuvchi qoʻshish",
                EDIT_MODAL_TITLE: "Foydalanuvchi ma\'lumotlarini tahrirlash",
                EDIT_USER: "Ma\'lumotlarini tahrirlash",
                UPDATE_STATUS_MODAL_TITLE: "Foydalanuvchi statusi",
                UPDATE_PASS_MODAL_TITLE: "Foydalanuvchi parolini oʻzgartirish",
                UPDATE_STATUS: "Statusni oʻzgartirish",
                UPDATE_PASS: "Parolini oʻzgartirish",
                NEW_PASSWORD: "Yangi parol",
                NEW_PASSWORD2: "Parolni qayta kiriting",
                ACTIVE: "Faol",
                IN_ACTIVE: "Faol emas",
                TOAST_EXISTS_LOGIN: "Bu \"Login\" nomi tizimda mavjud.\nIltimos boshqa nom kiriting!",
                RESPUBLIC: "Respublika",
            },
            COMPANY_OPERATIONS: {
                TABLE_HEADERS: {
                    ID: "ID",
                    NAME: "Nomi",
                    T_PERIOD: "Texnologik davr",
                    ADDRESS: "Manzil",
                    PHONE: "Telefon",
                    TASK: "Vazifalar",
                    START_DATE: "Boshlanish sanasi",
                    END_DATE: "Tugash sanasi",
                    STATUS: "Holat",
                    RESULT: "Natija",
                    KOD: "Kod",
                    ACTION: "Harakat",
                },
                SELECT: {
                    REGION: "Viloyatni tanlang",
                    DISTRICT: "Tumanni tanlang",
                    YEAR: "Yil",
                    MONTH: "Oy",
                },
                MODAL: {
                    TITLE: "Joriy oyni qayta hisoblash",
                    TEXT: "Siz haqiqatan ham bir vaqtning oʻzida qayta hisoblashni va keyin ushbu kompaniyani yangi davrga oʻtkazishni xohlaysizmi?",
                    SAVE: "Ha, bajaring",
                },
            },
        },

        ChatService: {
            Search: "Qidirish...",
            Chats: "Chatlar",
            Select_chat: "Chatni tanlang",
            About: "Men haqimda",
            Personal_information: "Shaxsiy ma\'lumotlar",
            View_contact: "Kontaktni koʻrish",
            Mute_notifications: "Sukut qilish",
            Block_contact: "Kontaktni bloklash",
            Clear_chat: "Tarixni tozalash",
            Write_message: "Xabaringizni yozing",
            Send: "Yuborish",
        },

        ShortRegions: {
            A03000: "And...",
            A06000: "Bux...",
            A08000: "Jiz...",
            A10000: "Qash...",
            A12000: "Nav...",
            A14000: "Nam...",
            A18000: "Sam...",
            A22000: "Sur...",
            A24000: "Sir...",
            A30000: "Far...",
            A33000: "Xor...",
            A27000: "Tosh v...",
            A26000: "Tosh sh... ",
            A35000: "QQR",
            A00002: "Xor Urg...",
        },

        ShortRegionsCode: {
            A03000: "And...",
            A06000: "Bux...",
            A08000: "Jiz...",
            A10000: "Qash...",
            A12000: "Nav...",
            A14000: "Nam...",
            A18000: "Sam...",
            A22000: "Sur...",
            A24000: "Sir...",
            A30000: "Far...",
            A33000: "Xor...",
            A27000: "Tosh v...",
            A26000: "Tosh sh...",
            A35000: "Qora Res...",
            A00002: "Xora Urg...",
        },

        FullRegions: {
            Andijan: "Andijon",
            Bukhara: "Buxoro",
            Jizzakh: "Jizzax",
            Kashkadarya: "Qashqadaryo",
            Navoi: "Navoiy",
            Namangan: "Namangan",
            Samarkand: "Samarqand",
            Surkhandarya: "Surxandaryo",
            SyrDarya: "Sirdaryo",
            Ferghana: "Fargʻona",
            Khorezm: "Xorazm",
            TashkentRegion: "Toshkent viloyat",
            TashkentCity: "Toshkent shahar",
            KKR: "KKR",
        },

        Profil: {
            Months: {
                January: "Yanvar",
                February: "Fevral",
                March: "Mart",
                April: "Aprel",
                May: "May",
                June: "Iyun",
                July: "Iyul",
                August: "Avgust",
                September: "Sentabr",
                October: "Oktabr",
                November: "Noyabr",
                December: "Dekabr",
            },
            Name: "Profil",
            Request: "Soʻrovlar",
            Complaint: "Shikoyatlar",
            CallTime: "Umumiy suhbat vaqti",
            CallName: "Qoʻngʻiroqlar soni",
            Offer: "Takliflar",
            Service: "Xizmat koʻrsatish",
            Chat_title: "Kunlik qabul qilingan murojaatlar soni",
            days30Statistics: "Soʻngi 30 kunlik qoʻngʻiroqlar statistikasi",
            days30StatisticsTime: "30 kunlik jami suhbat vaqti",
            ImgError: "Rasm hajmi 2 mb dan kichkina boʻlishi kerak",
            EditProfileMessage: "Ma'lumotlar oʻzgartirildi!",
            FirstAndSecondName: "Ism Familiya",
            respublika: "Respublika",
            region: "Region",
            tuman: "Tuman",
            kompaniya: "Kompaniya",
            inspektor: "Inspektor",
            operator: "Operator",
            resident: "Resident"
        },

        Selects: {
            Regions: "Viloyatlar",
            Region: "Viloyat",
            FromRegion: "Viloyatdan",
            ToRegion: "Viloyatga",
            PES: "PES roʻyxati",
            RES: "RES roʻyxati",
        },

        NOTIFICATION_TYPE: {
            MAINTENANCE: "Uzilishlar haqida xabar",
            20: "Boshqa turdagi xabarlar",
        },

        Notification: {
            Notifications: "Bildirishnomalar",
            NoNotifications: "O‘qilmagan bildirishnomalar yo‘q",
            AllNotifications: "Barcha bildirishnomalar",
            AddNotification: "Bildirishnoma yuborish",
            New: "Yangi",
            StartDate: "Boshlanish sanasi",
            EndDate: "Tugash sanasi",
            StartTime: "Boshlanish vaqti",
            EndTime: "Tugash vaqti",
            SelectAll: "Hammasini tanlash",
            SelectElementName: "ta viloyat tanlandi",
            SelectUserName: "ta foydalanuvchi tanlandi",
            MessageDescription: "Xabar mazmuni",
            MessageFull: "Batafsil xabar tavsifi",
            MessageSend: "Yuborish",
            SendName: "Bildirishnoma yuborish",
            NotFound: "Topilmadi",
            MessageType: "Xabar turi",
            SelectSendUser: "Xabar yuboriladigan foydalanuvchi",
            MessageDescriptionUZ: "Xabar mazmuni(UZ)",
            MessageDescriptionRU: "Xabar mazmuni(RU)",
            MessageFullUZ: "Batafsil xabar tavsifi(UZ)",
            MessageFullRU: "Batafsil xabar tavsifi(RU)",
            Consumer: "Iste\'molchi",
            AccordingtoCOATO: "COATO boʻyicha uzilishlar xabari yuborish",
            SendMsgToCustomer: "Bir iste\'molchiga xabar yuborish",
            TYPE_MAINTENANCE: "Uzilishlar haqida xabar",
            TYPE_NOTIFICATION_FROM_CRM: "Boshqa turdagi xabarlar",
            LoadingProgress: "Yuborilmoqda...",
            Responsed: "Quyidagicha xabar yuborildi",
            Error: "Xatolik",
            SelectDistrictCOATO: "Tuman COATOni tanlang!",
            Sent: "Yuborildi",
            Edited: "Tahrirlandi",
            FileUploadText: "Fayldagi ma'lumotlar belgilangan struktura boʻyicha tekshirib chiqiladi, " +
              "\n natijasi bildirishnoma sifatida qaytariladi",
        },

        CallCenter: {
            last3day: "Oxirgi 3 kunlik",
            last7day: "Oxirgi 1 haftalik",
            last15day: "Oxirgi 15 kunlik",
            last1month: "Oxirgi 1 oylik",
            last3months: "Oxirgi 3 oylik",

            SelectPeriod: "Davrni tanlang",
            TotalNumberOfCalls: "Qoʻngʻiroqlarning umumiy soni",
            AllowedAppeals: "Murojaatlar",
            Completed: "Bajarildi",
            InProgress: "Jarayonda",
            AverageTalkTime: "Oʻrtacha suhbat vaqti",
            AllTalkTime: "Umumiy suhbat vaqti",
            AverageWaitingTime: "Oʻrtacha kutish vaqti",
            CaseStatusStatistics: "Ish holati statistikasi",
            ListLastCalls: "Oxirgi qoʻngʻiroqlar roʻyxati",
            Consumer: "Iste\'molchi",
            Phone: "Telefon raqami",
            WaitingTime: "Kutish vaqti",
            Status: "Holat",
            Time: "Vaqt",
            CalculationStatus: "Hisob holati",
            Accepted: "Qabul qilingan",
            Skipped: "Oʻtkazib yuborilgan",
            TalkedStatus: "Gaplashilgan",
            NotTalkedStatus: "Gaplashilmagan",
            SelectRegionLabel: "ta viloyat tanlandi",
            DuringConversation: "Suhbat vaqti",
            ActiveCall:"Faol Qo'ngiroqlar",
            ActiveOperators:"Faol Operatorlar",
            Online:"onlayn",
            Offline:"oflayn",
            QueueCall:"Navbatdagi qo'ng'iroqlar",
            CallCount:"Jami qo'ng'iroqlar",
            NotForwardCallCount:"Yo'naltirilmagan qo'ng'iroqlar",
            ForwardCallCount:"Yo'naltirilgan qo'ng'iroqlar"
        },

        ROLES: {
            RepublicAdmin: "Respublika admin",
            RegionAdmin: "Region admin",
            DistrictAdmin: "Tuman admin",
            CompanyAdmin: "Kompaniya admin",
            Inspector: "Inspektor",
            Operator: "Operator",
            Resident: "Resident",
            NameofROL: "Rol",
            DEPARTMENT_HEAD: "Bo'lim boshliqi",
            ADMIN: "Admin",
            OPERATOR: "Operator",
            AGENT: "Agent",
            PERFORMER: "Bajaruvchi"
        },

        LEVELS: {
            Republic: "Respublika",
            Region: "Region",
            District: "Tuman",
            Company: "Kompaniya",
            Inspector: "Inspektor",
            Operator: "Operator",
            Resident: "Resident",
        },

        PageTitle: {
            Home: "Bosh sahifa",
        },

        ChangeMenu: {
            Mega_Menu: "Katta yuqori menyu",
            Dropdown_Menu: "Kichik yuqori menyu",
        },

        Settings: {
            Name: "Sozlamalar",
        },

        Date: {
            From: "Dan",
            To: "Gacha",
            StartWork: "Ishning boshlanishi",
            EndWork: "Ishning oxiri",
            Empty: "Hech nima topilmadi",
            NotTypes: "Murojaat turlari mavjud emas",
        },

        Button: {
            Search: "Qidirish",
            Clear: "Tozalash",
            Accept: "Qabul qilish",
            Reject: "Rad etish",
            Disable: "Oʻchirish",
            Export: 'Eksport',
            ExportExcel: 'Excel ga eksport qilish',
            RegionOrDistrict: 'Viloyat / Tuman',
        },

        AccountType: {
            ELECTRICITY: "Elektr",
            REACTIVE: "Reaktiv",
            BUST: "Pribor",
            FINE: "Penya",
            COLLECTION: "Kamchilik",
            SERVICES: "Xizmatlar",
            ECO_ENERGY: "Ekologik energiya (-A)",
            TRANSPORTATION: "Xizmat (transport uchun)",
        },

        AvailabilityofReservation: 'Bronning mavjudligi',
        TotalCost: 'Jami xarajat (sum)',
        AnnualVolumeUnderAgreement: 'Shartnoma boʻyicha yillik hajm (ming kVt/soat)',
        ReportSubmission: 'Hisobot topshirish kuni',
        PrepaymentPercentage: 'Oldindan toʻlov foizi',
        SDATOLegalLocation: 'SOATO Yuridik manzili',
        ParentOrganization: 'Yuqori tashkilot',
        SDATOLocation: 'SOATO Manzil',
        Fine: 'Penya%',
        PowerandAmount: 'Quvvat va energiya miqdori',
        MainTariff: 'Asosiy tarif guruhi',
        AdditionalCode1: 'Qoʻshimcha kod1',
        AdditionalCode2: 'Qoʻshimcha kod2',
        AdditionalCode3: 'Qoʻshimcha kod3',
        InstalledPower: 'Oʻrnatilgan quvvat',
        Substation: 'Substantsiya',
        TransformerName: 'Transformator nomi',
        TP_Feeder: 'TP + Oziqlantiruvchi',
        FullNameForeman: 'F.I.O usta',
        FullNameController: 'F.I.O nazoratchi',
        CodeBook: 'Kodlar kitobi',
        BooksPage: 'Kitob sahifasi',
        ConnectionTo: 'Ulanish ASKUE ga',
        SubtractedFrom: 'Ajratildi',
        Consumer: 'iste\'molchi',
        Subconsumer: 'Subiste\'molchi',
        FromtheTariff: 'tarifdan',
        FullView: "Toʻliq koʻrish",
        View: "Koʻrish",
        Bind: 'Biriktirish',
        BindWorkTime: 'Ish vaqtini biriktirish',
        AddBindWorkTime: 'Ish vaqtini qoʻshish',
        BindBreakTime: 'Tanaffus vaqtini biriktirish',
        AddBindBreakTime: 'Tanaffus vaqtini qoʻshish',
        Edit: "Oʻzgartirish",
        Delete: "Oʻchirish",
        Spy: "Eshitish",
        Page: "Bet",
        Login: "Login",
        Password: "Parol",
        Logout: "Chiqish",
        LoginSystem: "Tizimga kirish",
        LEGAL_ENTITY_DATA: "Yuridik shaxs toʻgʻrisidagi ma'lumotlar",
        BILLING_MONTH_RECALCULATION_NOT_INCLUDED_DEBITS: "Debetga kiritilmagan hisob-kitob oyining qayta hisob-kitoblari",
        RETURN_PREPAYMENT: "Oldindan toʻlovni qaytarish",
        RECALCULATE_OTHER_REASON: "Boshqa sabablarga koʻra qayta hisob-kitoblar",
        ABOVE_THE_TARIFF_FOR_EXCEEDING_THE_CONTRACTUAL_VALUE: "Shartnoma qiymatidan oshib ketganlik uchun tarifdan yuqori",
        FOR_KWTH: "kVt/soat uchun",
        SUM: "Summa",
        INCOMING_BALANCE: 'Kiruvchi balans',
        ACCRUED: 'Hisoblangan',
        ACT_RECALCULATION: 'sh.j Qayta hisoblash akti',
        PAYMENT: 'Toʻlov',
        BALANCE_OUT: "Chiquvchi qoldiq",
        V_T_CH_FOUND_PAYMENT: 'sh.j. Toʻlov topildi',
        DECOMMISSIONED: 'Yozilgan',
        CLASSIFIED_HOPELESS: "Balans",
        RESTORED_ACCOUNTS_RECEIVABLE: "Keyingi toʻlov oylar",
        OUTGOING_BALANCE: 'Chiquvchi balans',
        CURRENT_PAYMENT: 'Toʻlov joriy',
        NEXT_MONTH_PAYMENT: 'Keyingi toʻlov oylar',
        BALANCE: "Balans",
        TOTAL: "Jami",
        V_T_CH_ACT_RECALCULATION: "sh.j Qayta hisoblash akti",
        BALANCE_SALDO: "Сальдо",
        CONSUMER_CODE: "Iste'molchi kodi",
        GOVERNMENT_ORGANIZATION: "SOOGU",
        CONSUMER_NAME: "Iste'molchining toʻliq ismi",
        CONTRACT_VALUE: "Shartnoma qiymati (kVt/soat)",
        OVERDUE_AMOUNT: "Kechiktirilgan miqdor",
        AFFILIATION: "Mansublik",
        PREPAYMENT_PERCENTAGE: "Oldindan toʻlov foizi",
        REPORT_DATE: "Hisobot sanasi",
        TARIFF_GROUP: "Tariflar guruhi",
        CONTRACT_DATE: "Shartnoma sanasi",
        INSTALLED_CAPACITY: "oʻrnatilgan quvvat",
        ADDRESS: "Manzil",
        PAYMENT_CODE: "Toʻlov kodi",
        REPORT_NUMBER: "Hisobot raqami.",
        RESIDENT: "Rezident",
        ConsumerCard: "Iste'molchi kartasi",
        PERSONAL_CONSUMER_CARD: "Iste\'mol kartasi",
        PERSONAL_ACCOUNT: "Shaxsiy hisob raqami",
        DISTRICT_LOCATION: 'Manzil',
        Neighborhood: 'Mahalla',
        NOTES: 'Eslatma',
        METER_NUMBER: '№ Hisoblagich',
        SHUTDOWN_DATE: 'Oʻchirish sanasi',
        INDICATION_INSTALLING_METER: 'Hisoblagichni oʻrnatishda koʻrsatma',
        DATE_INSTALLATION_METER: 'Hisoblagichni oʻrnatish sanasi',
        COUNTER_STATUS: 'Hisoblagich holati',
        METER_TYPE: 'Hisoblagich turi',
        CAPACITY: 'Hajm',
        TRANSFORMATION_KOEFF_KT: 'Trans nisbat',
        ASKUE: 'ASKUE',
        SOLAR_PANEL: 'Quyosh paneli',
        TARIFF_SUM: 'Tarif (sum)',
        SUMNAME: 'so\'m',
        LAST_BYPASS: 'Oxirgi tur',
        LAST_BYPASS_DATE: 'Oxirgi tashrif sanasi',
        SEAL: 'Sertifikat',
        SEAL_NUMBER: 'Sertifikat №',
        DATE: 'Sana',
        DEADLINE_TO: 'Muddat',
        ACT_INVERTORY: 'Qayta hisoblash akti (inventarizatsiya)',
        READING: 'Koʻrsatma',
        PENALTY_AMOUNT: 'Peniya miqdori',
        AMOUNT_PER_EP_E: 'EP/e miqdor',
        ACT_AMOUNT: 'Aktga muvofiq miqdor',
        TOTAL_AMOUNT: 'Umumiy qiymat',
        RECOIL_INDICATION_MINUS_A: 'Orqaga oʻqish (-A)',
        INCOMING_BALANCE_KWTH: 'Kirish balansi kVt/soat',
        INCOMING_BALANCE_SUM: 'Balansni ochish summasi',
        CALCULATION: 'Hisoblash',
        CALCULATION_TYPE: 'Hisoblash turi',
        CONTRACT_VALUE_KWH: 'It. LED (kVt/soat)',
        AVERAGE_DAILY_CONSUMPTION_KWH: 'Oʻrt. sutkalik sarf(kVt/soat)',
        AVERAGE_MONTHLY_CONSUMPTION_KWH: 'Oʻrt. oylik sarf (kVt)',
        ESTIMATED_READING_FOR_LAST_MONTH: 'Oʻtgan oy uchun R.h p.k',
        ACCRUED_DAY: 'Hisoblangan (kun)',
        ACCRUED_SUM: 'Hisoblangan (soʻm)',
        ACCRUED_KWH: 'Hisoblangan (kVt/soat)',
        RECALCULATION_DAYS: 'Qayta hisoblash (kun)',
        RECALCULATION_KWH: 'Qayta hisoblash (kVt.s)',
        RECALCULATION_BY_AVERAGE_DAILY: 'Oʻrt. kunlik qayta hisoblash.',
        PENALTY_CHARGED_SUM: 'Hisoblangan jarima (soʻm)',
        PENALTY_RECALCULATION: 'Jarimalarni qayta hisoblash',
        ESTIMATED_INDICATION_CURRENT_MONTH: 'Joriy oyning R.h',
        RECALCULATION: 'Qayta hisoblash',
        WRITE_OFF: 'Hisobdan oʻchirish',
        PAYMENT_CURRENT: "Joriy to'lov",
        PAYMENT_RECALCULATION: "To'lovni qayta hisoblash",
        ELECTRICITY: 'Elektr',
        ACT_VIOLATED: 'Buzilish akti',
        UNLEARNED_CONSUMPTION: 'Hisobsiz iste\'molchi',
        FINE: 'Jarima',
        POV_CONNECTIONS: 'Pov. ulanishlar',
        SelectRegion: 'Hududni tanlang',
        RECALCULATION_DATE: 'Qayta hisoblash sanasi',
        BYPASS_INDICATION_OF_RECALCULATION: 'Qayta hisoblash vaqtida aylanib oʻtish koʻrsatkichi',
        BYPASS_INDICATION_OF_RECALCULATION_A_MINUS: 'Qayta hisoblash vaqtida aylanib oʻtish koʻrsatkichi (-A)',
        ALL_AMOUNT: 'Umumiy hisob',
        UNLEARNED_CONSUMPTION_LONG: 'Hisobga olinmagan iste\'mol',
        ACT_RECONNECTION: 'Qayta ulanish',
        ResDataInvalid: 'Serverdan yuborilgan ma\'lumotlarda xatolik!',
        LAST_PAYMENT_SUM: 'Oxirgi toʻlov (soʻm)',
        LAST_PAYMENT_DATE: 'Oxirgi toʻlov sanasi',
        PREVIOUS_BYPASS: 'Oldingi koʻrsatma',
        PREVIOUS_DATE_BYPASS: 'Oldingi tashrif sanasi',
        LOSS_AMOUNT: 'Yoʻqotish miqdori',
        ONES_WITHOUT: 'Roʻyxatga olinmagansiz',
        VOSSTA_DEB: 'Muvaffaqiyatsiz (DEB)paket',
        BUILDING_FLAT_NUMBER: 'Xonadon/uy',
        METER: 'Hisoblagich',
        INSTALLATION: 'Oʻrnatish',
        NUMBER_ICON: '№',
        FROM: 'D',
        BEFORE: 'G',
        INVENTORY: 'Inventarizatsiya',
        PERIOD: 'Davr',
        BALANCE_IN: "Kiruvchi qoldiq",
        START_DATE: "Boshi",
        TERMINATION: "Oxiri",
        CALCULATION_CATEGORY: "Hisoblash turi",
        COUNT_DAY: "Kunlar soni",
        KVT_A_PLUS: "kVtS(A+)",
        KVT_A_MINUS: "kVtS(А-)",
        DIFFERENCE: "Farq",
        READING_A_PLUS: "Ko'rsatma А+",
        READING_A_MINUS: "Ko'rsatma А-",
        CURRENT_PERIOD_DATE: "Hisob-kitob davri",
        AVERAGE_DAY_EXP: "O'rtacha kunlik iste'mol",
        AVERAGE_DAY: "O`rt.kun",
        SETTLEMENT_DATE: 'Hisoblash sanasi',
        READING_CALCULATION: 'Hisob koʻrsatgichi',
        KWT: 'kVt',
        LOSS: 'Yoʻqotish',
        EDUCATION_PRICE: 'Narxlash',
        VIOLATION: 'Buzilish',
        OTHER_CALCULATION: 'Boshqa hisob-kitoblar',
        BYPASS_HISTORY: 'Oʻtish tarixi',
        PAYMENT_HISTORY: 'Toʻlov tarixi',
        BANK_DOCUMENT_TYPE: 'Bank hujjati turi',
        DOCUMENT_NUMBER: 'Hujjat raqami',
        ENTER_DATE: 'Kiritilgan sana',
        DOCUMENT_DATE: 'Hujjat sanasi',
        PAYMENT_AMOUNT: 'Toʻlov miqdori',
        PAYMENT_PURPOSES: 'Toʻlovnoma tayinlash',
        CREATED_BY: 'Tomonidan yaratilgan',
        UPDATED_BY: 'Tomonidan oʻzgartirilgan',
        PREVIOUS_BYPASS_READING_AP: 'Oldingi chetlab oʻtish (A+)',
        PREVIOUS_BYPASS_READING_AM: 'Oldingi chetlab oʻtish(А-)',
        SN_METER: 'SN hisoblagich',
        CONSUMPTION_PLUS: 'Iste\'mol(+А)',
        RECOIL_MINUS: 'Orqaga qaytish(-А)',
        BYPASS_DATE: 'Oʻtish sanasi',
        SOURCE: 'Manba',
        AGENT: 'Agent',
        UPDATED_AT: 'Oʻzgartirish sanasi',
        LOGIN: 'Kirish',
        STATUS: 'Holat',
        PAYMENT_DATE: 'Toʻlov sanasi',
        TARIFF: 'Tarif',
        PAYMENT_TYPE: 'Toʻlov turi',
        CALCULATION_PERIOD: 'Hisoblash davri',
        SENDER: 'Yuboruvchi',
        EXTRA_OPTIONS: 'Qoʻsh. parametrlari',
        SUMM: 'Summa',
        TOTALL: 'Jami',
        DEBT_PAYMENT_BEFOREHAND_SHORT: 'Ortiqcha ish (-oldingi)',
        NEGOTIABLE_CARD: "Aylanma karta",
        SHOWCASE: "Vitrina",
        IncomingCallRestriction: "Kiruvchi qoʻngʻiroqlarga ruxsat berish",
        OutgoingCallRestriction: "Chiquvchi qoʻngʻiroqlarga ruxsat berish",
        OutgoingCall: "Chiquvchi qoʻngʻiroq",
        PleaseWait: "Kuting",

        FilesUploadedToGeoMap: "Geo xaritaga yuklangan fayllar",
        DownloadCurrentStatus: "Joriy holatni yuklab olish",
        UploadNewFile: "Yangi fayl yuklash",
        UploadedByWho: "Kim tomonidan yuklangan",
        FileName: "Fayl nomi",
        RejectedFile: "Rad etilgan",
        UploadTime: "Yuklangan vaqti",
        Explanation: "Izoh",
        Downloaded: "Yuklandi",
        FileApproved: "Fayl tasdiqlandi",
        FileDisapproved: "Fayl tasdiqlanmadi",
        Upload: "Yuklash",
        Uploading: "Yuklanmoqda",
        SelectCSVFile: "CSV faylni tanlang",
        CSVFileUploadNote: "Faqat CSV formatdagi fayl yuklash mumkin. Fayl oʻlchami 1MB dan oshmasligi kerak.",
        CommentOptional: "Izoh (Ixtiyoriy)",
        ErrorCSVSelect: "Xato: CSV faylni tanlang!",
        ErrorSizeLimit: "Xato: fayl hajmi 1MB chegaradan oshib ketdi!",
        CallsForwardCount: "Boshqa raqamga yoʻnaltirilgan qoʻngʻiroqlar soni",
        IVRHistoryCount: "IVRga kirib ma'lumot olganlar soni",
        MissedCallsCount: "Oʻtkazib yuborilgan qoʻngʻiroqlar soni",
        AuthenticationLog: "Autentifikatsiya jurnali",
        Browser: 'Brauzer',
        IP_adress: "IP-manzil",
        Location: "Joylashuv(Location)",
        LoginAttemptTime: "Kirishga urinish vaqti",
        RolePermissions: "Rol ruxsatlari",
        ThisRolIdNotDef: "Ushbu rol IDsi topilmadi!",
        Permissions: "Ruxsatlar",
        PerToRole: "roliga tegishli ruxsatlar",
        Module: "Modul",
        UpdateAt: "Yangilangan vaqti",
        Confirm: "Tasdiqlash",
        ToBack: "Orqaga",
        confirmWarning: "Oʻzgarishlarni tasdiqlaysizmi?",
        OperatorOnline: "Operator faol",
        OperatorOffline: "Operator faol emas",
        NotFoundCOATO: "COATO topilmadi!",
        NotBeenDefinedCOATO: "Foydalanuvchi kirish huquqiga ega COATOlar roʻyxati topilmadi",
        CommunicationHasDeteriorated: "Aloqa sifati yomonlashdi!",
        NoSound: "Ovozsiz",
        ActivityStatus: "Faollik holati (tizimga kirish)",
        ConnectionStatus: "Operator holati (Onlayn yoki Oflayn)",
        Online: "Onlayn",
        Offline: "Oflayn",
        OffNotification: "Bildirishnomani o'chirish",
        OnNotification: "Bildirishnomani yoqish",
        NameOrEmailSearch: "Ism yoki E-mail orqali qidirish",
        NameOrCoatoSearch: "CAOTO yoki Ism orqali qidirish",
        OnSound: "Ovozni yoqish",
        CallForwarding: "Qoʻngʻiroqni yoʻnaltirish",
        WaitingClientsWindow: "Kutayotgan mijozlar oynasi",
        CloseWindow: "Oynani yopish",
        OpenWindow: "Oynani ochish",
        CustomerChat: "Mijoz suhbati",
        EndCall: "Qoʻngʻiroqni tugatish",
        SureEndCall: "Rostan ham bu qoʻngʻiroqni tugatishni istaysizmi?",
        Termination: "Tugatish",
        CallEnd: "Qoʻngʻiroq tugatildi",
        ContactOperator: "Operator bilan onlayn suhbat",
        REN: "“HUDUDIY ELEKTR TARMOQLARI” AJ",
        KVT_A: "kVt/soat",
        OldSumm: "Avvalgi bo`yicha jami",
        NewSumm: "Yangisi bo`yicha jami",
        PaymentDate: "To`lov kuni",
        DateFrom: "Dan",
        DateTo: "Gacha",
        Days: "Kunlar",
        RECALCULATION_NOT_FOUND: "Ushbu iste'molchi uchun jarimani qayta hisoblash topilmadi",
        OPERATOR_STATISTICS: "Operatorlar statistikasini ko'rish",
        AddPermitCoato: "Qoʻshimcha biriktirilgan hududlar",
        CoatoNotFoundToastTitle: "Topilmadi yoki kirish huquqingiz bo'lmagan COATOni kiritdingiz",
        UuidOrStartNotFound: "UUID yoki Start parametri mavjud emas",
        RecordSuccessfullyDeleted: "Yozuv muvaffaqiyatli oʻchirildi",
        FailedToDeleteRecord: "Yozuvni o‘chirib bo‘lmadi",
        DurationTime: "Davomiyligi",
        ViewLink: "Ko'rish",
        FromNewMessage: "dan sizga yangi xabar keldi",
        SentTime: "Yuborilgan vaqti",
        NotificationMonitoring: "Bildirishnomalar monitoringi",
        NTFMonitoringTitle: "Real vaqtdagi xabar statusini aniqlash uchun sahifani yangilang",
        ReadingTime: "O'qilgan vaqti",
        SendEmployees: "Joriy xabar yuborilgan xodimlar",
        Read: "O'qilgan",
        Unread: "O'qilmagan",
        RestartAccountSipNumber: "Tarmoqda uzilish yuz berdi. Akkaunt va SIP raqamni qayta ishga tushiring."
    },
};
