import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CoreCommonModule} from '@core/common.module';
import {FooterComponent} from '@core/layout/components/footer/footer.component';
import {ScrollTopComponent} from '@core/layout/components/footer/scroll-to-top/scroll-top.component';
import {KeenIconComponent} from '@shared/components/keen-icon/keen-icon.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [FooterComponent, ScrollTopComponent],
  imports: [RouterModule, CoreCommonModule, KeenIconComponent, NgbTooltipModule],
  exports: [FooterComponent]
})
export class FooterModule {
}
