import {Component, ViewEncapsulation} from '@angular/core';
import {fadeInOut} from '@chat-service/pages/customer-chat/animations';
import {fadeIn} from '@core/animations/core.animation';
import {ChatMainComponent} from '@chat-service/pages/chat-main/chat-main.component';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';

@Component({
  selector: 'chat-button',
  standalone: true,
  imports: [
    ChatMainComponent,
    ButtonComponent
  ],
  templateUrl: './chat-button.component.html',
  styleUrl: './chat-button.component.scss',
  animations: [fadeInOut, fadeIn],
  encapsulation: ViewEncapsulation.None
})
export class ChatButtonComponent {
  public _visible = false;
  public get visible() {
    return this._visible;
  }

  public set visible(visible) {
    this._visible = visible;
  }

  public toggleChat() {
    this.visible = !this.visible;
  }
}
