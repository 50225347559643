import {Component, inject, OnInit} from '@angular/core';
import {BasicForm} from '@shared/components/forms/basic/basic.form';
import {DefaultService} from '@core/services/default.service';
import {FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {InputComponent} from '@shared/components/forms/components/input/input.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {PaginatorModule} from 'primeng/paginator';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {HetTextareaComponent} from '@shared/components/forms/components/het-textarea/het-textarea.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {RegionService} from '@call-center/services/regions.service';
import {UsersService} from '@control-panel/pages/control/users/services/users.service';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {AddNotificationService} from '@layout/components/navbar/navbar-notification/services/add-notification.service';
import {CabAutocompleteComponent} from '@shared/components/forms/components/cab-autocomplete/cab-autocomplete.component';

@Component({
  selector: 'app-add-notification',
  standalone: true,
  imports: [
    ButtonComponent,
    CabAutocompleteComponent,
    InputComponent,
    NgSelectModule,
    PaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    HetTextareaComponent,
    MultiSelectModule
  ],
  templateUrl: './add-notification.component.html',
  styleUrl: './add-notification.component.scss'
})
export class AddNotificationComponent extends BasicForm implements OnInit {
  _defaultService: DefaultService = inject(AddNotificationService);
  _regionsService = inject(RegionService);
  _usersService = inject(UsersService);
  _translate = inject(TranslateService);

  selectedRegions: any = [];
  selectedUsers: any = [];
  regionOptions: any;
  userOptions: any;
  coatoCode: any;
  toUsersData: any;
  userDisabled: boolean = true;
  currentLang: any;
  currentUser: any;

  form: FormGroup = this._fb.group({
    from_user: [null, Validators.required],
    to_user: [null, Validators.required],
    content: [null, Validators.required],
  });

  constructor(private _authenticationService: AuthService) {
    super();

    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.currentLang = this._translate.getDefaultLang();
    this.getRegionsData();
    const userId = this.currentUser?.id;
    this.form.patchValue({
      from_user: userId
    });
  }

  getRegionsData() {
    this._regionsService.getAll({parent_code: '00000', per_page: 200}).subscribe({
      next: (res) => {
        this.regionOptions = res?.data?.data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  selectAll() {
    if (this.selectedRegions.length === this.regionOptions.length) {
      this.selectedRegions = [];
      this.coatoCode = [];
      this.userDisabled = true;
    } else {
      this.selectedRegions = [...this.regionOptions];

      this.coatoCode = [];
      this.regionOptions.forEach((item) => {
        this.coatoCode.push(item?.coato_code);
      });

      this.getUserData();
      this.userDisabled = false;
    }
  }

  selectUserAll() {
    if (this.selectedUsers.length === this.userOptions.length) {
      this.selectedUsers = [];

      this.toUsersData = null;

      this.form.patchValue({
        to_user: this.toUsersData
      });
    } else {
      this.selectedUsers = [...this.userOptions];
      this.toUsersData = [];
      this.userOptions.forEach((item) => {
        this.toUsersData.push(item?.id);
      });

      this.form.patchValue({
        to_user: this.toUsersData
      });
    }
  }

  onMultiSelectChange(event) {
    if (event && event.value && Array.isArray(event.value) && event.value.length > 0) {
      this.coatoCode = [];
      event.value.forEach((item) => {
        this.coatoCode.push(item?.coato_code);
      });

      this.userDisabled = false;
      this.getUserData();
    } else {
      this.userDisabled = true;
    }
  }

  onUserMultiSelectChange(event) {
    if (event && event.value && Array.isArray(event.value) && event.value.length > 0) {
      this.toUsersData = [];
      event.value.forEach((item) => {
        this.toUsersData.push(item?.id);
      });

      this.form.patchValue({
        to_user: this.toUsersData
      });
    }
  }

  getSelectedRegionsLabel() {
    const count = this.selectedRegions.length;

    if (count !== 0) {
      if (this.currentLang == 'ru') {
        return `${this._translate.instant('Notification.SelectElementName')} ${count} ${this._translate.instant('Notification.SelectElementEnd')}`;
      } else {
        return `${count} ${this._translate.instant('Notification.SelectElementName')}`;
      }
    }
  }

  getSelectedUsersLabel() {
    const count = this.selectedUsers.length;

    if (count !== 0) {
      if (this.currentLang == 'ru') {
        return `${this._translate.instant('Notification.SelectElementName')} ${count} ${this._translate.instant('Notification.SelectUserEnd')}`;
      } else {
        return `${count} ${this._translate.instant('Notification.SelectUserName')}`;
      }
    }
  }

  getUserData() {
    this._usersService.getAll({'coato_code[]': this.coatoCode, per_page: 400}).subscribe({
      next: (res) => {
        if (res && res.data && res.data.data) {
          this.userOptions = res.data.data;
        }
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
}
