import {Injectable} from '@angular/core';
import {DefaultService} from '@core/services/default.service';

@Injectable({
  providedIn: 'root'
})
export class AddNotificationService extends DefaultService {
  formName: '#add-notification';

  getUrl(): string {
    return 'call-center/api/v1/notifications';
  }
}
