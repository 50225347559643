import {Routes} from '@angular/router';
import {AuthGuard} from '@core/guards/auth.guard';
import {ServiceType} from '@call-center/helpers/service-type.enum';
import {RoleGuard} from '@core/guards/role.guard';
import {ROLES} from '@core/enums/Roles.enum';

export default [
  {
    path: 'chat-service',
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
    },
    loadComponent: () => import('./components/main.component').then(c => c.MainComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/chat-main/chat-main.component').then(c => c.ChatMainComponent),
        data: {animation: 'decommerce'}
      },
      {
        path: 'consumers/:id',
        loadComponent: () => import('@call-center/pages/consumers/id-call/id-call.component').then((c) => c.IdCallComponent),
        canActivate: [AuthGuard],
        data: {animation: 'decommerce', serviceType: ServiceType.ChatService}
      },
      {
        path: 'consumers',
        loadComponent: () => import('@chat-service/pages/consumers/consumers.component').then(c => c.ConsumersComponent),
        data: {animation: 'decommerce'}
      },
    ]
  }
] satisfies Routes;
