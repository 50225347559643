import {Component, inject, Input} from '@angular/core';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';
import {FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {BasicForm} from '@shared/components/forms/basic/basic.form';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ForwardCallService} from '@call-center/services/forward-call.service';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {coato_parent} from 'assets/data/coato_parent';

@Component({
  selector: 'app-forward-call',
  standalone: true,
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule
  ],
  templateUrl: './forward-call.component.html',
  styleUrl: './forward-call.component.scss'
})
export class ForwardCallComponent extends BasicForm {
  @Input() callId;

  _defaultService = inject(ForwardCallService);
  public COATOPARENT = coato_parent;
  form: FormGroup = this._fb.group({
    coatoCode: [null, Validators.required],
    operatorId: [null]
  });

  override onSubmit() {
    const params = {
      callId: this.callId,
      coatoCode: this.form.value.coatoCode
    };

    // if (this.form.value.operatorId) {
    //     params['operatorId'] = this.form.value.operatorId;
    // }

    this._defaultService.tableRequest.updateParams(params);

    this.form.markAllAsTouched();

    if (this.form.valid && !this.isPending()) {
      this.beforeSubmit();

      this.submitAction({})
        .subscribe({
          next: (res) => this.afterSuccessSubmit(res),
          error: (res: HttpErrorResponse) => this.afterErrorSubmit(res),
        });
    }
  }

  override submitAction(data: object): Observable<any> {
    return this._defaultService.insert(data);
  }

}
