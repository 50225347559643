<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <div class="modal-body">
    <div class="flex w-full h-full scroll-body">
      <div
        class="w-full flex flex-col h-full scroll-body"
      >
        <ng-select
          [clearable]="true"
          [items]="COATOPARENT"
          [placeholder]="'Components.CallCenter.CallPrefix.Region' | translate"
          [required]="true"
          appendTo="body"
          bindLabel="name"
          bindValue="code"
          class="w-full"
          formControlName="coatoCode"
        ></ng-select>
        <!-- <app-cab-autocomplete
                [_defaultService]="_spravochnikBranchesService"
                [firstSelect]="false"
                [params]="{parent_code: '00000'}"
                [required]="true"
                class="w-full"
                bindValue="code"
                formControlName="coatoCode"
                label="Components.CallCenter.CallPrefix.Region"
                [searchKey]="'name'"
                [isSearchable]="false"
                [appendTo]="'body'"
        /> -->

        <!--                <app-cab-autocomplete-->
        <!--                        [_defaultService]="_operatorsService"-->
        <!--                        [firstSelect]="false"-->
        <!--                        [required]="false"-->
        <!--                        class="w-full"-->
        <!--                        bindLabel="name"-->
        <!--                        bindValue="id"-->
        <!--                        formControlName="operatorId"-->
        <!--                        label="Components.CallCenter.Operator"-->
        <!--                        [searchKey]="'name'"-->
        <!--                        [appendTo]="'body'"-->
        <!--                />-->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <ups-button
      [isPending]="isPending()"
      label="Actions.Buttons.Forward"
      styleType="primary"
      type="submit"
    />
  </div>
</form>
