import {Routes} from '@angular/router';
import {AuthGuard} from '@core/guards/auth.guard';
import {RoleGuard} from '@core/guards/role.guard';
import {ROLES} from '@core/enums/Roles.enum';

export default [
  {
    path: 'service-desk',
    loadComponent: () =>
      import('@help-window/pages/statistic/ticket-board/ticket-board.component').then((c) => c.TicketBoardComponent),
    canActivate: [AuthGuard],
    data: {animation: 'decommerce'},
    children: [
      {
        path: '',
        loadComponent: () =>
          import('@help-window/pages/main/board/board.component').then(
            (c) => c.BoardComponent
          ),
        canActivate: [RoleGuard],
        data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]}
      },
      {
        path: 'tickets-list',
        loadComponent: () =>
          import('@help-window/pages/appeals/tickets-list/tickets-list.component').then(
            (c) => c.TicketsListComponent
          ),
        canActivate: [RoleGuard],
        data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]}
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('@help-window/pages/statistic/ticket-dashboard/ticket-dashboard.component').then(
            (c) => c.TicketDashboardComponent
          ),
        canActivate: [RoleGuard],
        data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]}
      },
      {
        path: 'ticket-types',
        loadComponent: () =>
          import('@help-window/pages/references/pages/ticket-types/ticket-types.component').then(
            (c) => c.TicketTypesComponent
          ),
        canActivate: [RoleGuard],
        data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]}
      },
      {
        path: 'ticket-statuses',
        loadComponent: () =>
          import('@help-window/pages/references/pages/ticket-status/ticket-status.component').then(
            (c) => c.TicketStatusComponent
          ),
        canActivate: [RoleGuard],
        data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]}
      },
      {
        path: 'ticket-priorities',
        loadComponent: () =>
          import('@help-window/pages/references/pages/ticket-priorities/ticket-priorities.component').then(
            (c) => c.TicketPrioritiesComponent
          ),
        canActivate: [RoleGuard],
        data: {expectedRoles: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]}
      },
      {
        path: '',
        redirectTo: 'board',
        pathMatch: 'full'
      }
    ]
  }
] satisfies Routes;
