export enum LoginStep {
  STEP_CHECK = 1,
  STEP_VERIFY = 2,
  STEP_LOGIN = 3
}

export enum EReqHeaders {
  AUTHORIZATION = 'Authorization',
  LANGUAGE = 'Accept-Language',
  DEVICE_ID = 'deviceId',
  DEVICE_NAME = 'deviceName',
  DEVICE_TYPE = 'type',
  APP_VERSION = 'appVersion'
}
