import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@app/modules/pages/login/auth.service';
import {ELocalStorage} from '@core/enums/LocalStorage.enum';

@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(
    private authService: AuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hasToken = sessionStorage.getItem(ELocalStorage.ACCESS_TOKEN);
    if (hasToken) {
      return true;
    }

    this.authService.logout();
    return false;
  }
}
