import {Routes} from '@angular/router';
import dashboardRouting from '@control-panel/control-panel.routing';
import serviceDeskRouting from '@help-window/help-window-routing';
import callCenterRouting from '@call-center/call-center.routing';
import chatServiceRouting from '@chat-service/chat-service.routing';
import corporateChatRouting from '@app/modules/pages/corporate-chat/corporate-chat.routing';

export const appRoutes: Routes = [
  ...dashboardRouting,
  ...serviceDeskRouting,
  ...callCenterRouting,
  ...chatServiceRouting,
  ...corporateChatRouting,
  {
    path: 'login',
    loadComponent: () => import('@app/modules/pages/login/login.component').then(c => c.LoginComponent)
  },
  {
    path: 'profile',
    loadComponent: () => import('@app/modules/pages/profile/profile.component').then(c => c.ProfileComponent)
  },
  {
    path: 'client-chat',
    loadComponent: () => import('@chat-service/pages/customer-chat/chat-widget/chat-widget.component').then(c => c.ChatWidgetComponent),
    data: {animation: 'decommerce'},
  },
  {
    path: '',
    redirectTo: 'crm',
    pathMatch: 'full'
  },
  {
    path: '**',
    loadComponent: () => import('@app/modules/pages/error/error.component').then(c => c.ErrorComponent),
    pathMatch: 'full'
  },
];
