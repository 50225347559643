<li #dropdown="ngbDropdown" class="nav-item dropdown-notification mr-25" ngbDropdown>
  <a class="nav-link d-flex align-items-center" id="navbarNotificationDropdown" ngbDropdownToggle>
    <keen-icon
      *ngIf="unreadNotificationsCount && !isAll"
      [name]="'notification-on'"
      class="font-large-1"
      type="duotone"
    ></keen-icon>
    <keen-icon
      *ngIf="!unreadNotificationsCount || isAll"
      [name]="'notification'"
      class="font-large-1"
      type="duotone"
    ></keen-icon>
    <span
      *ngIf="!isAll && unreadNotificationsCount > 0"
      class="badge badge-pill badge-success badge-up"
      [@bounce]="animationState">
      {{ unreadNotificationsCount }}
    </span>
  </a>
  <ul
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
    ngbDropdownMenu>
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ 'Notification.Notifications' | translate }}
        </h4>
        <div *ngIf="!isAll && unreadNotificationsCount > 0" class="badge badge-pill badge-light-primary">
          {{ unreadNotificationsCount }} {{ 'Notification.New' | translate }}
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <li *ngIf="notifications.length == 0" class="scrollable-container media-list text-center mb-1 mt-1">
      <span class="badge badge-light-primary">
        {{ 'Notification.NoNotifications' | translate }}
      </span>
    </li>

    <!--         Notifications content-->
    <ng-scrollbar autoHeightDisabled="false">
      <li class="scrollable-container media-list">
        <p-accordion class="notification-accordion" collapseIcon="pi pi-minus" expandIcon="pi pi-plus">
          <p-accordionTab *ngFor="let notification of notifications">
            <ng-template class="p-0" pTemplate="header">
              <div (click)="notificationClick(notification?.id, notification.notifications[0]?.read)"
                   class="p-[8px] w-full d-flex align-items-center gap-[12px]">
                @if (notification.notifications[0]?.read) {
                  <div>
                    <div class="avatar-content">
                      <keen-icon
                        [name]="'notification'"
                        class="font-large-1"
                        type="duotone"
                      ></keen-icon>
                    </div>
                  </div>
                } @else {
                  <div class="avatar bg-light-success">
                    <div class="avatar-content">
                      <keen-icon
                        [name]="'notification-on'"
                        class="font-large-1"
                        type="duotone"
                      ></keen-icon>
                    </div>
                  </div>
                }

                <div class="media-body">
                  <p class="media-heading ntf-header">
                    <strong>
                      {{ notification.content }}
                    </strong>
                  </p>
                  <div class="d-flex flex-col align-bottom pr-2 mt-[5px]">
                    <small class="notification-text mb-0">
                      {{ notification.from_user_name }}
                    </small>
                    <small class="notification-text mb-0 text-right">
                      {{ notification.created_at | localizedDate: 'medium' }}
                    </small>
                  </div>
                </div>
              </div>
            </ng-template>
            <p class="m-0">
              {{ notification.content }}
            </p>
          </p-accordionTab>
        </p-accordion>
      </li>
    </ng-scrollbar>
    <!--        / Notifications content-->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <button (click)="click()" *ngIf="!isAll" class="btn btn-outline-blue btn-block">
        {{ 'Notification.AllNotifications' | translate }}
      </button>
      @if (isAdminOrDepHead()) {
        <div class="mt-1">
          <button (click)="addNotification()" class="btn btn-primary btn-block">
            {{ 'Notification.AddNotification' | translate }}
          </button>
          <button (click)="goNtfMonitoring()" class="btn btn-primary btn-block">
            {{ 'NotificationMonitoring' | translate }}
          </button>
        </div>
      }
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>

<audio
  #notificationAudio
  src="assets/audio/notification.mp3"
></audio>
